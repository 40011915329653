import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';

import { Helmet } from 'react-helmet';

import { useUser } from 'contexts/user';

import Orders from 'components/VOD/Orders';
import Filter from 'components/VOD/Filter';
import Order from 'components/VOD/Order';
import useLocationSearch from 'hooks/useLocationSearch';

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const propTypes = {
    currentClient: PropTypes.number.isRequired,
};

const defaultProps = {
};

const defaultFilter = {
    titleSearch: '',
    status: [], // Rename to statuses (Must be done in api as well).
    distributor: [], // Rename to distributors (Must be done in api as well).
    orderId: '',
    itemId: null,
    version: [], // Rename to versions (Must be done in api as well).
    translation: [], // Rename to translations (Must be done in api as well).
    statusCc: [], // Rename to statusCcs (Must be done in api as well).
    encodingFormat: [], // Rename to encodingFormats (Must be done in api as well).
    product: [], // Rename to products (Must be done in api as well).
    contentType: [], // Rename to contentTypes (Must be done in api as well).
    advertisment: '',
    fromStartWindow: null,
    toStartWindow: null,
    fromEndWindow: null,
    toEndWindow: null,
    toDeliveryDate: null,
    fromDeliveryDate: null,
    toOrderOn: null,
    fromOrderOn: null,
    episodeNumber: '',
    episodeTitle: '',
    seasonNumber: '',
    commanderPar: '',
    hasProblem: null,
    poNumber: '',
};


function VOD(props) {
    const {
        currentClient: clientId,
    } = props;

    const { api, user } = useUser();

    const { order: orderId } = useParams();

    const [page, setPage] = useLocationSearch('page', 0);
    const [rowsPerPage, setRowsPerPage] = useLocationSearch('rowsPerPage', 50);
    const [order, setOrder] = useLocationSearch('order', 'asc');
    const [orderBy, setOrderBy] = useLocationSearch('orderBy', '');
    const [filters, setFilters] = useLocationSearch(Object.keys(defaultFilter), defaultFilter);

    const classes = useStyles();
    const intl = useIntl();

    const [pages, setPages] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [fetchingFailed, setFetchingFailed] = React.useState(false);

    const orders = (Object.values(pages) || []).reduce((acc, lst) => ([...acc, ...lst]), []);
    const fullCount = (orders.length > 0 ? orders[0].fullCount : 0);
    const curPageRows = pages[page] || [];
    const haveProductionInfo = React.useMemo(() => (
        orders.some((p) => !(p.produit === null || p.produit === ''))
    ), [orders]);

    const userPermission = user.clients.find((c) => c.client === clientId);
    const VodWrite = userPermission.vodOrders === 2;

    const fetchOrders = React.useCallback(async (flushObjects = false, pageToFetch = 0, limit = 50, sortBy, sort, abortController = new AbortController()) => {
        if (clientId) {
            setIsLoading(true);
            setFetchingFailed(false);

            try {
                const query = {
                    ...filters,
                    orderBy: sortBy,
                    order: sort,
                    offset: pageToFetch * limit,
                    limit,
                };

                const response = await api.get(`/vod/orders/${clientId}`, query, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setPages((currentPages) => (flushObjects ? { [pageToFetch]: data } : { ...currentPages, [pageToFetch]: data }));
                } else {
                    setPages((currentPages) => (flushObjects ? {} : omit(currentPages, pageToFetch)));
                    setFetchingFailed(true);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setIsLoading(false);
        }
    }, [filters, clientId, order, orderBy, rowsPerPage]);

    const handlePageChange = React.useCallback((newPage) => {
        if (isEmpty(pages[newPage])) {
            setIsLoading(true);
        }
        setPage(newPage);
    }, [pages]);

    const refreshPage = React.useCallback(() => {
        fetchOrders(true, page, rowsPerPage, orderBy, order);
        setPage(0);
    }, [orderId, page, rowsPerPage, orderBy, order]);
    return (
        <div className={classes.root}>
            <Card>
                <Helmet>
                    <title>
                        {`Melodie - ${!orderId ? intl.formatMessage({ id: 'VOD_ORDERS' }) : `${intl.formatMessage({ id: 'VOD_ORDER' })} #${orderId}`}`}
                    </title>
                </Helmet>
                <div className={classes.root}>
                    <Toolbar>
                        <Filter
                            clientId={clientId}
                            applyFilters={setFilters}
                            initialFilters={filters}
                            isLoading={isLoading}
                            defaultFilter={defaultFilter}
                        />
                    </Toolbar>
                    <Orders
                        clientId={clientId}
                        filters={filters}
                        orders={orders}
                        haveProductionInfo={haveProductionInfo}
                        fetchingFailed={fetchingFailed}
                        setPage={handlePageChange}
                        page={page}
                        fetchPage={fetchOrders}
                        pageRows={curPageRows}
                        totalRowCount={fullCount}
                        isLoading={isLoading}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </div>
                <Order VodWrite={VodWrite} refreshPage={refreshPage} page={page} setPage={setPage}/>
            </Card>
        </div>
    );
}

VOD.propTypes = propTypes;
VOD.defaultProps = defaultProps;

export default VOD;
