import React from 'react';
import Grid from '@mui/material/Grid';
import Details from 'components/Distribution/Entities/Details';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useConstants } from 'contexts/constants'
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';


import { useLocale } from 'contexts/locale';
import {
    NEVER, TEXTLESS, AUDIO, MONO,
    STEREO, AUDIO51, DESCRIBED_VIDEO, MUSIC_AND_EFFECTS,
    MONO_SHORT, STEREO_SHORT, AUDIO51_SHORT
} from './constants';

function AssetDescription(assetDetails, intl, language, locales, assetTypeOptions, translationTypeOptions, audioTrackTypeOptions) {
    const {
        assetIndex, assetType, tvDefinition, assetLanguage,
        translationTypes, frameRate, ratio, element, version, season, episode,
        partNumber, seasonTitle, episodeTitle, partNumner,
        ratioImage, audioTrack=[], defaultDescription, isPartial,
        isHDR, is3D, ccStatus, hasPublicity,
    } = assetDetails;

    const displayIsFrench = language && language.toLowerCase() === 'fr';

    const monoDescription = (audioTrackTypeOptions.find((a) =>  a.value === MONO) || {}).label;
    const stereoDescription = (audioTrackTypeOptions.find((a) =>  a.value === STEREO) || {}).label;


    const assetTypeDefinition = assetTypeOptions.find((at) => at.assetTypeId === assetType) || {};
    const assetTypeDescription = assetTypeDefinition.description;
    const assetTypeShortDescription = assetTypeDefinition.shortDescription;
    const assetTypeDefaultLocale = assetTypeDefinition.hasLocale !== NEVER ? TEXTLESS : null;

    const translationTypeDeDefinition = translationTypeOptions.find((tt) => tt.translationTypeId === translationTypes) || {};
    const translationTypeDescription = translationTypeDeDefinition.description;
    const translationTypeShortDescription = translationTypeDeDefinition.shortDescription;

    const localeDefinition = locales.find((l) => (l.value === assetLanguage)) || {};
    const localeDescription = assetLanguage === null ? assetTypeDefaultLocale : localeDefinition.label;
    const localeDescriptionPlural = assetLanguage === null ? assetTypeDefaultLocale : localeDefinition.pluralDescription;
    const isTextless = assetLanguage === 'NONE';

    let elementDescription = '';

    let assetDescription = assetType !== 'CC' && !displayIsFrench && assetTypeDescription ? assetTypeDescription.toLowerCase() : assetTypeDescription;
    let additionalDescription = '';

    let audioContents = '';
    let audio51 = '';
    let audio20 = '';
    let audio10 = '';
    let audioDescribedVideo = '';
    let audioMusicAndEffects = '';


    if (element === 'TRAILER') {
        elementDescription = `${intl.formatMessage({ id: element ? element : '' })}`
    } else if (element === 'EPISODE') {
        if (season) {
            elementDescription = `${ season ? 'S' : ''}${season}`
        }
        if (episode) {
            elementDescription += `${ season ? ' ' : ''} E${episode}`
        }
    } else if (element === 'OTHER_VERSION') {
        elementDescription = `${intl.formatMessage({ id: 'VERSION' })}`
        if (version) {
            elementDescription += ` ${intl.formatMessage({ id: version  })}`
        }
    } else if (element === 'PART') {
        elementDescription = `${intl.formatMessage({ id: element ? element : '' })}`
        if (partNumber) {
            elementDescription += ` ${partNumber}`
        }
    }
    if (assetType === AUDIO && !isEmpty(audioTrack)) {
        const distinctLanguages = audioTrack.reduce((mem, a) => {
            if (a.language && !mem.includes(a.language)) {
                mem.push(a.language);
            }
            return mem;
        }, []);

        let distinctLocale = null;
        let distinctLanguage = null;

        if (distinctLanguages.length === 1) {
            distinctLocale = distinctLanguages[0];
            distinctLanguage = (locales.find((l) => (l.value === distinctLocale)) || {}).label;

            audio10 = audioTrack.find((audio) => (audio.audioTrackType === MONO)) ? MONO_SHORT : null;
            audio20 = audioTrack.find((audio) => (audio.audioTrackType === STEREO)) ? STEREO_SHORT : null;
            audio51 = audioTrack.find((audio) => (audio.audioTrackType === AUDIO51)) ? AUDIO51_SHORT : null;
        }

        audioContents = distinctLanguages.join('+');

    } else if (assetTypeDefinition.hasAudio !== NEVER) {
        audio10 = audioTrack.find((audio) => (audio.audioContenType.indexOf('MIX') > -1 && (!assetLanguage || audio.language === assetLanguage) && audio.audioTrackType === MONO)) ? MONO_SHORT :null;
        audio20 = audioTrack.find((audio) => (audio.audioContenType.indexOf('MIX') > -1 && (!assetLanguage || audio.language === assetLanguage) && audio.audioTrackType === STEREO)) ? STEREO_SHORT : null;
        audio51 = audioTrack.find((audio) => (audio.audioContenType.indexOf('MIX') > -1 && (!assetLanguage || audio.language === assetLanguage) && audio.audioTrackType === AUDIO51)) ? AUDIO51_SHORT : null;

        audioDescribedVideo = !!audioTrack.find((audio) => (audio.audioContenType === DESCRIBED_VIDEO && (!assetLanguage || audio.language === assetLanguage)));
        audioMusicAndEffects = !!audioTrack.find((audio) => (audio.audioContenType === MUSIC_AND_EFFECTS && (!assetLanguage || audio.language === assetLanguage)));
    }

    if (assetType === 'SUBTITLE') {
        if (isPartial) {
            if (!localeDescriptionPlural) {
                if (displayIsFrench) {
                    assetDescription += ` ${intl.formatMessage({ id: "PARTIAL" })}`
                } else {
                    assetDescription = `${intl.formatMessage({ id: "PARTIAL" })} ${assetDescription}`
                }
            } else {
                if (displayIsFrench) {
                    assetDescription += ` ${intl.formatMessage({ id: "PARTIAL" })} ${localeDescriptionPlural.toLowerCase()}`
                } else {
                    assetDescription = `${localeDescription} ${intl.formatMessage({ id: "PARTIAL" })} ${assetDescription}`
                }
            }
        } else if (localeDescriptionPlural) {
           if (displayIsFrench) {
                assetDescription += ` ${localeDescriptionPlural.toLowerCase()}`
            } else {
                assetDescription = `${localeDescription} ${assetDescription}`
            }
        }
    } else {
        if (isPartial) {
            if (!localeDescription) {
                if (displayIsFrench) {
                    assetDescription += ` ${intl.formatMessage({ id: "PARTIAL" })}`
                } else {
                    assetDescription = `${intl.formatMessage({ id: "PARTIAL" })} ${assetDescription}`
                }
            } else {
                if (displayIsFrench) {
                    assetDescription += ` ${intl.formatMessage({ id: "PARTIAL" })} ${localeDescription.toLowerCase()}`
                } else {
                    assetDescription = `${localeDescription} ${intl.formatMessage({ id: "PARTIAL" })} ${assetDescription}`
                }
            }
        } else {
            if ((!translationTypeShortDescription || translationTypeShortDescription == "n/a") && localeDescription) {
                
                if (displayIsFrench) {
                    assetDescription += ` ${localeDescription.toLowerCase()}`
                } else {
                    assetDescription = `${localeDescription} ${assetDescription}`
                }
            } else if (translationTypeShortDescription && (!localeDescription || assetLanguage === 'NONE')) {
                if (displayIsFrench) {
                    assetDescription += ` ${translationTypeShortDescription.toLowerCase()}`
                } else {
                    assetDescription = `${translationTypeShortDescription} ${assetDescription}`
                }
            } else if (translationTypeShortDescription && localeDescription) {
                if (displayIsFrench) {
                    assetDescription += ` ${translationTypeShortDescription.toLowerCase()} en ${localeDescription.toLowerCase()}`
                } else {
                    assetDescription += ` ${translationTypeShortDescription.toLowerCase()} in ${assetDescription}`
                }
            }
        }
    }


    // TV definition
    if (tvDefinition) {
        assetDescription += ` ${tvDefinition}`;

        if (isHDR) {
            assetDescription += `-HDR`;
        }

        if (is3D) {
            assetDescription += ` 3D`;
        }
    }

    // Image ratio
    if (ratio) {
        assetDescription += ` (${ratio}:1)`;
    }

    // Audio contents
    if (audioContents) {
        assetDescription += ` ${audioContents}`;
    }

    // Audio types
    if (audio51 || audio20 || audio10) {
        assetDescription += ` ${[audio51, audio20, audio10].filter((x) => !!x).join('+')}`;
    }


    if (['YES', 'PARTIAL'].includes(ccStatus)) {
        if (additionalDescription) {
            additionalDescription += ', ';
        }

        if (ccStatus === 'PARTIAL') {
            if (displayIsFrench) {
                additionalDescription += 'CC partiel';
            } else {
                additionalDescription += 'partial CC';
            }
        } else {
            additionalDescription += 'CC'
        }
    }

    if (hasPublicity) {
        if (additionalDescription) {
            additionalDescription += ', ';
        }

        additionalDescription += displayIsFrench ? 'pauses' : 'breaks';
    }

    if (audioDescribedVideo) {
        if (additionalDescription) {
            additionalDescription += ', ';
        }

        additionalDescription += displayIsFrench ? 'VD' : 'DV';
    }

    if (audioMusicAndEffects) {
        if (additionalDescription) {
            additionalDescription += ', ';
        }

        additionalDescription += 'M&E';
    }

    if (additionalDescription) {
        const joinner = displayIsFrench ? 'avec' : 'with';
        assetDescription += ` ${joinner} ${additionalDescription}`;
    }

    if (frameRate) {
        assetDescription += ` (${frameRate}${isNaN(frameRate) ? '' : ' fps'})`;
    }

    if (assetDescription) {
        assetDescription = assetDescription.trim();
    }

    let description = defaultDescription;
    if (elementDescription && assetDescription) {
        description = `${elementDescription}: ${assetDescription}`;
    } else if (elementDescription || assetDescription) {
        description = elementDescription || assetDescription;
    }

    return description;
}

export default AssetDescription;
