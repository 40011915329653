import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import { filter, isEmpty } from 'lodash';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import isEqual from 'lodash/isEqual';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


import InputMenuItem from 'components/InputMenuItem';
import FormSelect from 'components/FormSelect';
import DatePicker from 'components/DatePicker';
import { useUser } from 'contexts/user';

const propTypes = {
    currentClient: PropTypes.number.isRequired,
    applyFilters: PropTypes.func.isRequired,
    initialFilters: PropTypes.shape().isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {

};

const useStyles = makeStyles((theme) => createStyles({
    badge: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
        lineHeight: 1,
        fontSize: 'inherit',
        padding: 'inherit',
        '.MuiBadge-badge:hover > &': {
            opacity: 1,
        },
        '&, &:active, &:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
        },
    },
}));

function PhysicalVaultFilter(props) {
    const {
        currentClient,
        initialFilters,
        isLoading,
        applyFilters,
        defaultFilter,
    } = props;

    const { api } = useUser();
    const classes = useStyles();
    const objectInputLabel = React.useRef(null);
    const formatInputLabel = React.useRef(null);
    const statusInputLabel = React.useRef(null);
    const [statuses, setStatuses] = React.useState([]);
    const [formats, setFormats] = React.useState([]);
    const [objectTypes, setObjectTypes] = React.useState([]);
    const [advancedOpen, setAdvancedOpen] = React.useState(false);
    const [filtersActive, setFiltersActive] = React.useState(!isEqual(defaultFilter, initialFilters));
    const [filters, setFilters] = React.useState(initialFilters);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {
        objectTypeId, barcode, title, title2,
        serviceCenter, clientCode, filmingDate, formatId,
        statusId,
    } = filters;

    // const filterCount = React.useMemo(() => (
    //     (Object.keys(filters || {}).filter((key) => (filters[key] && !isEmpty(filters[key]))) || []).length + (filters.hasProblem ? 1 : 0)
    // ), [filters]);

    const filterCount = React.useMemo(() => {
        let count = 0;
        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                const value = filters[key];
                if (value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                    count++;
                }
            }
        }
        return count;
    }, [filters]);

    const handleMenuClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    function handleInputChange({ target: { name, value } }) {
        setFilters({ ...filters, [name]: value });
    }

    function handleSubmit(event) {
        event.preventDefault();
        handleMenuClose();
        applyFilters(filters);
        setFiltersActive(true);
    }

    function clearFilters(event) {
        event.stopPropagation();
        handleMenuClose();
        setFilters(defaultFilter);

        if (filtersActive) {
            applyFilters(defaultFilter);
        }
    }

    async function fetchStatuses(abortController) { // TODO should be in constants?
        try {
            const response = await api.get(`/physical-vault/${currentClient}/statuses`, {}, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setStatuses(data);
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    async function fetchFormats(abortController) { // TODO should be in constants?
        try {
            const response = await api.get(`/physical-vault/${currentClient}/formats`, {}, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setFormats(data);
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    async function fetchObjectTypes(abortController) { // TODO should be in constants?
        try {
            const response = await api.get(`/physical-vault/${currentClient}/object-types`, {}, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setObjectTypes(data);
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchStatuses(abortController);
        fetchFormats(abortController);
        fetchObjectTypes(abortController);

        return () => {
            abortController.abort();
        };
    }, []);

    const objectTypeOptions = objectTypes.map(({ objectTypeId: id, description }) => (
        <MenuItem value={id} key={id}>
            {description}
        </MenuItem>
    ));
    const statusOptions = statuses.map(({ statusId: id, description }) => (
        <MenuItem value={id} key={id}>
            {description}
        </MenuItem>
    ));
    const formatOptions = formats.map(({ formatId: id, format }) => (
        <MenuItem value={id} key={id}>
            {format}
        </MenuItem>
    ));

    return <>
        <Grid
            component="form"
            onSubmit={handleSubmit}
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid container item xs={12} spacing={1} alignItems="center">
                <Grid item xs lg={2}>
                    <TextField
                        size="small"
                        margin="dense"
                        fullWidth
                        name="barcode"
                        value={barcode}
                        variant="outlined"
                        label={<FormattedMessage id="BARCODE" />}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        size="small"
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        name="title"
                        value={title}
                        label={<FormattedMessage id="TITLE" />}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs lg={3}>
                    <FormSelect
                        fullWidth
                        multiple
                        size="small"
                        margin="dense"
                        variant="outlined"
                        label={<FormattedMessage id="STATUS" />}
                        name="statusId"
                        value={statusId}
                        onChange={handleInputChange}
                    >
                        { statusOptions }
                    </FormSelect>
                </Grid>
                <Grid item>
                    <Badge
                        color="primary"
                        overlap="circular"
                        invisible={filterCount < 1}
                        badgeContent={(
                            <>
                                <span>
                                    {filterCount}
                                </span>
                                <IconButton size="small" className={classes.badge} onClick={clearFilters} color="error">
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        )}
                    >
                        <IconButton onClick={handleMenuClick} size="large">
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                </Grid>
                <Grid item>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        color="primary"
                        variant="outlined"
                    >
                        <FormattedMessage id="SEARCH" />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Drawer
            anchor="right"
            keepMounted
            open={!!anchorEl}
            onClose={handleMenuClose}
        >
            <List style={{ height: '100%', overflow: 'auto' }}>
                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="OBJECT_TYPE" />}
                        name="objectTypeId"
                        value={objectTypeId}
                        onChange={handleInputChange}
                    >
                        { objectTypeOptions }
                    </FormSelect>
                </ListItem>
                <ListItem dense>
                    <TextField
                        fullWidth
                        name="title2"
                        value={title2}
                        label={<FormattedMessage id="OTHER_TITLE" />}
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <TextField
                        fullWidth
                        name="serviceCenter"
                        value={serviceCenter}
                        label={<FormattedMessage id="SERVICE_CENTER" />}
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <TextField
                        fullWidth
                        name="clientCode"
                        value={clientCode}
                        label={<FormattedMessage id="CLIENT_CODE" />}
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <DatePicker
                        clearable
                        disableToolbar
                        fullWidth
                        name="filmingDate"
                        format="yyyy-MM-dd"
                        value={filmingDate}
                        label={<FormattedMessage id="FILMING_DATE" />}
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="FORMAT" />}
                        name="formatId"
                        value={formatId}
                        onChange={handleInputChange}
                    >
                        { formatOptions }
                    </FormSelect>
                </ListItem>
            </List>
            <ListItem>
                <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            onClick={clearFilters}
                            variant="text"
                        >
                            <FormattedMessage id="CANCEL" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            <FormattedMessage id="SEARCH" />
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
        </Drawer>
    </>;
}

PhysicalVaultFilter.propTypes = propTypes;
PhysicalVaultFilter.defaultProps = defaultProps;

export default PhysicalVaultFilter;
