import React from 'react';
import Color from 'color';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioMulti from 'components/RadioMulti';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import Loading from 'components/Loading';

const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 200px)',
        gridAutoRows: 'auto',
        gridGap: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'space-between',
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(auto-fill, 200px)',
        }
    },
    actionArea: {
        height: '100%',
    },
    header: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    actionSubTitle: {
        textAlign: 'center',
        display: 'block',
    },
    actionTitle: {
        textAlign: 'center',
        display: 'block',
        marginBottom: theme.spacing(1),
    },
    loading: {
        height: '100%',
        width: '100%',
    }
}));

import {
    catalogueElementService, vaultService, closedCaptionService,
    videoDescriptionService, subtitleService, versionService,
    adaptationService
} from './constants';

const propTypes = {
    selectedService: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
const defaultProps = {
    selectedService: null,
};

function ServiceCard(props) {
    const {title, subtitle, value, onChange, disabled} = props;
    const classes = useStyles();

    const handleClick = React.useCallback(() => onChange({ target: { value } }), [onChange, value]);

    if (disabled) {
        return null;
    }

    return (
        <Card>
            <CardActionArea className={classes.actionArea} onClick={handleClick}>
                <CardContent>
                    <Typography variant="body1" className={classes.actionTitle}>{title}</Typography>
                    {
                        !subtitle ? null : (
                            <Typography variant="caption" className={classes.actionSubTitle}>{subtitle}</Typography>
                        )
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    );
}


function ServiceSelection(props) {
    const {
        selectedService, onChange, preSelectedAssets, preSelectedTitles,
        multipleAssetsSelected, isLoading
    } = props;
    const classes = useStyles();

    const handleChange = React.useCallback(({ target: { value } }) => {
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);

    const services = [
        catalogueElementService, vaultService, closedCaptionService, videoDescriptionService,
        subtitleService, versionService, adaptationService
    ];

    if (isLoading) {
        return <Loading className={classes.loading} />
    }


    return (
        <Grid container spacing={2}>
            {
                preSelectedAssets ? null : (
                    <Grid item xs={12}>
                        <FormLabel className={classes.header}>
                            <FormattedMessage id="ADD_TO_VIRTUAL_VAULT" />
                        </FormLabel>
                        <FormControl component="fieldset" className={classes.grid}>
                            <ServiceCard
                                onChange={handleChange}
                                value={vaultService}
                                title={<FormattedMessage id="NEW_ENTRY" />}
                                subtitle={<FormattedMessage id="DESTINATION_VIRTUAL_VAULT" />}
                            />
                        </FormControl>
                    </Grid>
                )
            }
            <Grid item xs={12}>
                <FormLabel className={classes.header}>
                    <FormattedMessage id="DELIVERY" />
                </FormLabel>
                <FormControl component="fieldset" className={classes.grid}>
                    <ServiceCard
                        onChange={handleChange}
                        value={catalogueElementService}
                        title={<FormattedMessage id="CATALOGUE_ELEMENT" />}
                        subtitle={<FormattedMessage id="DESTINATION_PLATFORM" />}
                    />
                    <ServiceCard
                        onChange={handleChange}
                        value={versionService}
                        title={<FormattedMessage id="NEW_ELEMENT" />}
                        subtitle={<FormattedMessage id="DESTINATION_PLATFORM" />}
                        disabled={preSelectedAssets || preSelectedTitles}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormLabel className={classes.header}>
                    <FormattedMessage id="CREATION" />
                </FormLabel>
                <FormControl component="fieldset" className={classes.grid}>
                    <ServiceCard
                        onChange={handleChange}
                        value={closedCaptionService}
                        title={<FormattedMessage id="CCS" />}
                        disabled={multipleAssetsSelected}
                    />
                    <ServiceCard
                        onChange={handleChange}
                        value={subtitleService}
                        title={<FormattedMessage id="ST" />}
                        disabled={multipleAssetsSelected}
                    />
                    <ServiceCard
                        onChange={handleChange}
                        value={videoDescriptionService}
                        title={<FormattedMessage id="VD" />}
                        disabled={multipleAssetsSelected}
                    />
                    <ServiceCard
                        onChange={handleChange}
                        value={adaptationService}
                        title={<FormattedMessage id="ADAPTATION" />}
                        subtitle={<FormattedMessage id="ORIGINAL_CONTENT_TO_OTHER_LANGUAGE" />}
                        disabled={multipleAssetsSelected}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

ServiceSelection.propTypes = propTypes;
ServiceSelection.defaultProps = defaultProps;

export default ServiceSelection;
