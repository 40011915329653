import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

function RadioMulti(props) {
    const {
        fullWidth, label, options,
        onChange, name, commonValues,
        value, margin, size, variant,
        disabled, row, required,
    } = props;

    const commonOptions = [];
    const otherOptions = [];

    options.forEach((item) => {
        if (commonValues.includes(item.value)) {
            commonOptions.push(item);
        } else {
            otherOptions.push(item);
        }
    });

    let radioValue = '';
    let selectValue = value;

    if (commonOptions.find(({ value: v }) => v === value)) {
        radioValue = value;
        selectValue = '';
    }

    const renderValue = React.useCallback((selectedValue) => {
        if (otherOptions && !!selectValue) {
            const locale = otherOptions.find(({ value: v }) => v === selectedValue);

            if (locale) {
                return locale.label;
            }
        }
        return <FormattedMessage id="OTHER" />;
    }, [otherOptions, selectValue]);

    return (
        <FormControl margin={margin} size={size} fullWidth={fullWidth} variant={variant} disabled={disabled} required={required}>
            <FormLabel>
                {label}
            </FormLabel>
            <RadioGroup onChange={onChange} name={name} value={radioValue} row={row}>
                {
                    commonOptions.map(({ label: commonLabel, value: commonValue }) => (
                        <FormControlLabel key={commonValue} size="small" value={commonValue} control={<Radio size="small" />} label={commonLabel} />
                    ))
                }
                {
                    !otherOptions || otherOptions.length == 0 ? null : (
                        <FormControlLabel
                            checked={!!selectValue}
                            control={<Radio size="small" />}
                            size="small"
                            label={(
                                <Select
                                    fullWidth
                                    displayEmpty
                                    renderValue={renderValue}
                                    onChange={onChange}
                                    name={name}
                                    size="small"
                                    value={selectValue}

                                >
                                    {
                                        otherOptions.map(({ value: optionValue, label: optionLabel }) => (
                                            <MenuItem value={optionValue} key={optionValue}>
                                                {optionLabel}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            )}
                        />
                    )
                }
            </RadioGroup>

        </FormControl>
    );
}

RadioMulti.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.node,
    name: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    })).isRequired,
    commonValues: PropTypes.arrayOf(PropTypes.any).isRequired,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    size: PropTypes.oneOf(['small', 'medium']),
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

RadioMulti.defaultProps = {
    label: null,
    name: undefined,
    fullWidth: false,
    disabled: false,
    margin: 'none',
    size: undefined,
    variant: 'standard',
};

export default RadioMulti;
