import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import LoginCheck from 'components/LoginCheck';

const propTypes = {
    children: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
};

const defaultProps = {
};

const RouteWithAuth = ({ children, path }) => {

    return (
        <Route path={path}>
            <LoginCheck>
                {children}
            </LoginCheck>
        </Route>
    );
};

RouteWithAuth.propTypes = propTypes;
RouteWithAuth.defaultProps = defaultProps;

export default RouteWithAuth;
