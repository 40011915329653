import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';
import Bowser from 'bowser';

import { useUser } from 'contexts/user';


const supportTypes = [
    {
        value: 'suggestion',
        intlLabel: 'SUGGESTION',
    },
    {
        value: 'question',
        intlLabel: 'QUESTION',
    },
    {
        value: 'error',
        intlLabel: 'PROBLEM',
    },
];

function Support(props) {
    const {
        user, currentClient, clientName, isOpen,
        handleClose, supportType: defaultSupportType,
    } = props;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [supportText, setSupportText] = React.useState('');
    const [supportType, setSupportType] = React.useState(defaultSupportType || 'question');
    const [recaptchaValid, setRecaptchaValid] = React.useState(false);
    const { api } = useUser();

    async function handleSubmit(event) {
        event.preventDefault();

        const bowser = Bowser.parse(window.navigator.userAgent);
        const navigator = `${bowser.browser.name} ${bowser.browser.version}`;
        const snackId = enqueueSnackbar(<FormattedMessage id="SENDING_SUPPORT_REQUEST" />, { variant: 'loading', persist: true });
        const { name, email } = user;
        const permission = currentClient;
        const lastLocation = window.location.pathname;
        const comment = supportText;
        const type = supportType;
        const data = {
            user, name, email, permission, lastLocation, navigator, clientName, comment, type,
        };

        const response = await api.post('/support', data);

        if (response.ok) {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="SUPPORT_REQUEST_SENT" />, { variant: 'success' });
        } else {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ERROR_SENDING_SUPPORT_REQUEST" />, { variant: 'error' });
        }

        handleClose();
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                <FormattedMessage id="SUPPORT" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="SUPPORT_MESSAGE" />
                </DialogContentText>
                <form>
                    <TextField
                        name="supportText"
                        label={<FormattedMessage id="MESSAGE" />}
                        value={supportText}
                        fullWidth
                        margin="normal"
                        onChange={({ target: { value } }) => setSupportText(value)}
                        multiline
                    />
                    {
                        defaultSupportType !== undefined ? false : (
                            <FormControl
                                component="fieldset"
                                margin="normal"
                            >
                                <FormLabel component="legend">
                                    <FormattedMessage id="SUPPORT_TYPE" />
                                </FormLabel>
                                <RadioGroup
                                    row
                                    name="supportType"
                                    value={supportType}
                                    onChange={({ target: { value } }) => setSupportType(value)}
                                >
                                    {
                                        supportTypes.map(({ value, intlLabel }) => (
                                            <FormControlLabel
                                                key={value}
                                                value={value}
                                                control={<Radio />}
                                                label={<FormattedMessage id={intlLabel} />}
                                            />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>

                        )
                    }
                    <FormControl
                        margin="normal"
                    >
                        <ReCAPTCHA
                            theme="light"
                            sitekey="6LfuCWkUAAAAADDVVvpnRClRaJomtzIYs14buqW6"
                            onChange={(value) => setRecaptchaValid(!!value)}
                        />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                    onClick={handleSubmit}
                    type="submit"
                    status="accent"
                    color="primary"
                    disabled={!(recaptchaValid && supportText.length > 0)}
                >
                    <FormattedMessage id="SUBMIT" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Support.propTypes = {
    user: PropTypes.shape({}).isRequired,
    currentClient: PropTypes.shape({}).isRequired,
    clientName: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    supportType: PropTypes.oneOf(supportTypes.map((type) => type.value).concat([undefined])),
};

Support.defaultProps = {
    supportType: undefined,
};

export default Support;
