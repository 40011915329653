import assignIn from 'lodash/assignIn';

class Theme {
    static colorVariant({ r, g, b }, o = 1) {
        return `rgba(${r},${g},${b},${o})`;
    }

    static textColor(color) {
        let rgbaColor = color;
        if (typeof color === 'string' || color instanceof String) {
            rgbaColor = Theme.rgbaObj(color);
        }
        const { r, g, b } = rgbaColor;
        const perceptiveLuminance = 1 - (((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255);
        // return darker color if color is light.
        return (perceptiveLuminance < 0.5) ? { r: 20, g: 20, b: 20 } : { r: 255, g: 255, b: 255 };
    }

    static rgbaObj(rgbaString) {
        const splitColor = /\(([^,]+),([^,]+),([^,]+),?([^,]+)?\)$/i.exec(rgbaString);
        const rgbColor = {
            r: (splitColor && parseInt(splitColor[1], 10)) || 0,
            g: (splitColor && parseInt(splitColor[2], 10)) || 0,
            b: (splitColor && parseInt(splitColor[3], 10)) || 0,
            a: splitColor && splitColor[4] ? parseInt(splitColor[4], 10) || 1 : 1,
        };
        return rgbColor;
    }

    constructor(options) {
        this.rgbColors = assignIn({
            pageBackground: { r: 246, g: 247, b: 249 },
            background: { r: 255, g: 255, b: 255 },
            altBackground: { r: 50, g: 50, b: 50 },
            darkBackground: { r: 0, g: 0, b: 0 },
            accent: { r: 3, g: 169, b: 244 },
            positive: { r: 76, g: 175, b: 80 },
            negative: { r: 244, g: 67, b: 54 },
            random: [
                { r: 255, g: 82, b: 82 },
                { r: 255, g: 64, b: 129 },
                { r: 224, g: 64, b: 251 },
                { r: 63, g: 81, b: 181 },
                { r: 83, g: 109, b: 254 },
                { r: 68, g: 138, b: 255 },
                { r: 64, g: 196, b: 255 },
                { r: 24, g: 255, b: 255 },
                { r: 100, g: 255, b: 218 },
                { r: 105, g: 240, b: 174 },
                { r: 178, g: 255, b: 89 },
                { r: 238, g: 255, b: 65 },
                { r: 255, g: 255, b: 0 },
                { r: 255, g: 215, b: 64 },
                { r: 255, g: 171, b: 64 },
                { r: 255, g: 110, b: 64 },
            ],
        }, options.rgbColors);

        const sizes = options.sizes || {}

        const xsMin = sizes.xsMin || 30;
        const smMin = sizes.smMin || 48;
        const mdMin = sizes.mdMin || 64;
        const lgMin = sizes.lgMin || 75;
        const XlMin = sizes.XlMin || 130;
        const smFontSize = sizes.smFontSize || 1.3;
        const lgFontSize = sizes.lgFontSize || 1.2;
        const xlFontSize = sizes.xlFontSize || 1.1;
        const smPhotoSize = sizes.smPhotoSize || 3.6;
        const mdPhotoSize = sizes.mdPhotoSize || 4.2;
        const lgPhotoSize = sizes.lgPhotoSize || 10;
        const lineHeight = sizes.lineHeight || 1.61803399;

        this.sizes = {
            xsMin,
            smMin,
            mdMin,
            lgMin,
            XlMin,
            lineHeight,
            screenXsMin: `${xsMin}em`,
            screenSmMin: `${smMin}em`,
            screenMdMin: `${mdMin}em`,
            screenLgMin: `${lgMin}em`,
            screenXlMin: `${XlMin}em`,
            smFontSize: `${smFontSize}rem`,
            lgFontSize: `${lgFontSize}rem`,
            xlFontSize: `${xlFontSize}rem`,
            smPhotoSize: `${smPhotoSize}rem`,
            mdPhotoSize: `${mdPhotoSize}rem`,
            lgPhotoSize: `${lgPhotoSize}rem`,
        };

        this.zIndexes = assignIn({
            BucketDiv: 10,
            ToasterDiv: 2000,
        }, options.zIndexes);

        this.shadow = options.shadow || '0 1.4rem 4.5rem rgba(0,0,0,0.25), 0 1rem 1.8rem rgba(0,0,0,0.22)';
    }


    pageBackground = (opacity) => {
        return Theme.colorVariant(this.rgbColors.pageBackground, opacity);
    }

    background = (opacity) => {
        return Theme.colorVariant(this.rgbColors.background, opacity);
    }

    altBackground = (opacity) => {
        return Theme.colorVariant(this.rgbColors.altBackground, opacity);
    }

    text = (backgroundColor = this.rgbColors.pageBackground) => {
        const text = Theme.textColor(backgroundColor || this.rgbColors.pageBackground);
        return Theme.colorVariant(text, text.r === 255 ? 1 : 0.87);
    }

    secondaryText = (backgroundColor = this.rgbColors.pageBackground) => {
        const text = Theme.textColor(backgroundColor || this.rgbColors.pageBackground);
        return Theme.colorVariant(text, text.r === 255 ? 0.7 : 0.54);
    }

    disabledText = (backgroundColor = this.rgbColors.pageBackground) => {
        const text = Theme.textColor(backgroundColor || this.rgbColors.pageBackground);
        return Theme.colorVariant(text, text.r === 255 ? 0.5 : 0.38);
    }

    divider = (backgroundColor = this.rgbColors.pageBackground) => {
        const text = Theme.textColor(backgroundColor || this.rgbColors.pageBackground);
        return Theme.colorVariant(text, 0.12);
    }

    accent = (opacity) => {
        return Theme.colorVariant(this.rgbColors.accent, opacity);
    }

    positive = (opacity) => {
        return Theme.colorVariant(this.rgbColors.positive, opacity);
    }

    negative = (opacity) => {
        return Theme.colorVariant(this.rgbColors.negative, opacity);
    }

    random = () => {
        const rColor = this.rgbColors.random[Math.floor(Math.random() * (this.rgbColors.random.length))];
        return { color: Theme.colorVariant(rColor), text: Theme.textColor(rColor) };
    }

    transition = (property) => {
        return `${property} 280ms cubic-bezier(0.4, 0, 0.2, 1)`;
    }
}


const colorThemes = {
    default: {
        rgbColors: {
            pageBackground: { r: 246, g: 247, b: 249 },
            background: { r: 255, g: 255, b: 255 },
            altBackground: { r: 50, g: 50, b: 50 },
            darkBackground: { r: 0, g: 0, b: 0 },
            accent: { r: 3, g: 169, b: 244 },
            positive: { r: 76, g: 175, b: 80 },
            negative: { r: 244, g: 67, b: 54 },
        },
    },
    oceanNext: {
        rgbColors: {
            pageBackground: { r: 26, g: 42, b: 50 },
            background: { r: 37, g: 53, b: 60 },
            altBackground: { r: 50, g: 50, b: 50 },
            darkBackground: { r: 0, g: 0, b: 0 },
            accent: { r: 3, g: 169, b: 244 },
            positive: { r: 76, g: 175, b: 80 },
            negative: { r: 244, g: 67, b: 54 },
        },
    },
};

export { Theme, colorThemes };