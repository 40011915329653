import React from 'react';
import { FormattedMessage,useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


import AssetDescription from 'components/Distribution/Assets/Description';

import NotFound from 'components/NotFound';
import { useUser } from 'contexts/user';
import Table from 'components/Table';

import OrderCreation from 'components/Distribution/Orders/Create';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    paper: {
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    wizard: {
        // '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root.MuiDialog-paper': {
        //     height: '100%',
        // },
    },
}));

const propTypes = {
};

function DistributionWorkspace() {
    const classes = useStyles();
    const { user: { contact }, user, api } = useUser();
    const { client } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalOpen2, setModalOpen2] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [selectedAssets, setSelectedAssets] = React.useState([]);
    const [assets, setAssets] = React.useState([]);
    const [fetchingAssets, setFetchingAssets] = React.useState(false);
    const intl = useIntl();
    async function fetchWorkspace(abortController = new AbortController()) {
        if (client) {
            try {
                setFetchingAssets(true);
                setFailed(false);

                const response = await api.get(`/distribution/workspace/${client}`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setAssets(data);
                } else {
                    setFailed(true);
                    setAssets([]);
                }

                setFetchingAssets(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchWorkspace(abortController);
        setSelectedAssets([]);
        return () => {
            abortController.abort();
        };
    }, [client, contact]);


    async function removeAssets(assets) {
        if (client && assets && contact) {
            const snackId = enqueueSnackbar(<FormattedMessage id={`REMOVING_ASSET${assets.length > 1 ? 'S' : ''}`} />, { variant: 'loading', persist: true });

            const response = await api.delete(`/distribution/workspace/${client}`, { assetId: assets, contactId: contact });

            if (response.ok) {
                fetchWorkspace();
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id={`REMOVED_ASSET${assets.length > 1 ? 'S' : ''}`} />, { variant: 'success' });
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id={`ERROR_REMOVING_ASSET${assets.length > 1 ? 'S' : ''}`} />, { variant: 'error' });
            }
        }
    }

    function handleRemoveAssets() {
        removeAssets(selectedAssets);
        setSelectedAssets([]);
    }

    function handleOpenModal() {
        setModalOpen(true);
    }

    function handleCloseModal() {
        setModalOpen(false);
    }

    function handleOpenModal2() {
        setModalOpen2(true);
    }

    function handleCloseModal2() {
        setModalOpen2(false);
    }

    const selectedTitles = assets.reduce((mem, {assetId, rootId}) => {
        if (!mem.includes(rootId) && selectedAssets.includes(assetId)) {
            mem.push(rootId);
        }
        return mem;
    }, []);

    return (
        <div className={classes.root}>
            <Card>
                <Helmet>
                    <title>
                        {`Melodie - ${intl.formatMessage({ id: 'DISTRIBUTION_WORKSPACE' })}`}
                    </title>
                </Helmet>
                <div className={classes.root}>
                    <Toolbar>
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={handleRemoveAssets}
                                    disabled={isEmpty(selectedAssets)}
                                >
                                    <FormattedMessage id="REMOVE_SELECTED" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleOpenModal2}
                                >
                                    <FormattedMessage id="ORDER" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <Paper className={classes.paper}>
                        <Table
                            isSortable
                            isSelectable
                            isLoading={fetchingAssets}
                            selected={selectedAssets}
                            setSelected={setSelectedAssets}
                            uniqueField="assetId"
                            rows={assets}
                            pagenate={false}
                            emptyMessage="WORKSPACE_EMPTY"
                            columns={[
                                {
                                    field: 'titre',
                                    title: 'ORIGINAL_TITLE',
                                },
                                {
                                    field: 'description',
                                    title: 'CONTENT_TYPE',
                                    Component: AssetDescription
                                },
                            ]}
                        />
                    </Paper>
                    <Dialog
                        open={modalOpen2}
                        className={classes.wizard}
                        maxWidth="lg"
                        // fullWidth
                    >
                        <OrderCreation
                            closeModal={handleCloseModal2}
                            selectedAssets={!isEmpty(selectedAssets) && selectedAssets}
                            selectedTitles={selectedTitles}
                        />
                    </Dialog>
                </div>
            </Card>
        </div>
    );
}

DistributionWorkspace.propTypes = propTypes;


export default DistributionWorkspace;
