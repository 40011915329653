import React from 'react';
import parseISO from 'date-fns/parseISO';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import CloudCircleIcon from '@mui/icons-material/CloudCircleTwoTone';
import DiscussionIcon from '@mui/icons-material/ForumTwoTone';
import NotInterestedIcon from '@mui/icons-material/NotInterestedTwoTone';
import EventAvailableIcon from '@mui/icons-material/EventAvailableTwoTone';


function AssetDescription({assetDescription, description, isReceived, isInVault, isNew, hasDiscussion, expectedDate: expectedBy}, showDiscussion) {
    const d = assetDescription || description;
    const expectedDate = isReceived !== 1 && expectedBy ? parseISO(expectedBy) : null;
    const isAvailableNotInVault = isReceived === 1 && isInVault !== 1;
    let BadgeIcon = isInVault ? CloudCircleIcon : NotInterestedIcon;
    let tooltipData = <FormattedMessage id={isInVault ? 'IN_VAULT' : 'UNAVAILABLE'} />
    let late = false;

    if (expectedDate)  {
        BadgeIcon = EventAvailableIcon;
        tooltipData = <FormattedMessage values={{ date: expectedDate }} id="EXPECTED_BY" />
        if (isPast(expectedDate) && !isToday(expectedDate)) {
            late = true;
            tooltipData = <FormattedMessage values={{ expectedDate: expectedDate }} id="LATE_EXPECTED" />
        }


    }
    const marginLeft = '8px';

    return (
        <span style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', columnGap: '8px'}}>
            {d}
            {
                !showDiscussion || !hasDiscussion ? null : (
                    <DiscussionIcon size="small" sx={(theme) => ({ marginLeft: 'auto',fontSize: 'inherit'})} />
                )
            }
            {
                isAvailableNotInVault || isNew ? null : (
                    <Tooltip title={tooltipData}>
                        <BadgeIcon size="small" sx={(theme) => ({ fontSize: 'inherit', ...!late ? {} : { color: theme.palette.error.main } })} />
                    </Tooltip>
                )
            }
        </span>
    );
}

export default AssetDescription;
