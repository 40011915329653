import React from 'react';

import { FormattedMessage } from 'react-intl';
import isString from 'lodash/isString';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

import FormSelect from 'components/FormSelect';
import DatePicker from 'components/DatePicker';
import FileInput from 'components/FileInput';
import {fileDelivery, deliveryTypes, } from './constants';

import { useConstants } from 'contexts/constants';

function FileDestination(props) {
    const {
        delivery, onChange, currentServiceIndex,
    } = props;

    const {
        deliveryType, deliverTo, tapeDeliveryType, otherDelivery,
        fileDeliveryType, hardDiskDeliveryType, deliveryAddress,
    } = delivery;



    const { vodOptions: {
        fileDeliveryOptions, hardDiskId, tapeHdDeliveryTypes, pickUpId,
        clientServerId
    } } = useConstants();

    const handleDeliveryChange = React.useCallback(({ target: { name, value, type, checked } }) => {
        if (onChange) {
            onChange((currentDelivery) => {
                return currentDelivery.map((delivery, index) => {
                    if (index === currentServiceIndex) {
                        return {...delivery, [name]: type === 'checkbox' ? checked : value, }
                    } else {
                        return delivery
                    }
                });
            });
        }
    }, [onChange]);

    React.useEffect(() => {

        if (fileDeliveryType !== hardDiskId && !!hardDiskDeliveryType) {
            onChange((currentDelivery) => {
                return currentDelivery.map((delivery, index) => {
                    if (index === currentServiceIndex) {
                        return {...delivery, hardDiskDeliveryType: '', }
                    } else {
                        return delivery
                    }
                });
            });
        } else {
            onChange((currentDelivery) => {
                return currentDelivery.map((delivery, index) => {
                    if (index === currentServiceIndex) {
                        return {...delivery, deliveryAddress: '', }
                    } else {
                        return delivery
                    }
                });
            });
        }

    }, [fileDeliveryType, hardDiskDeliveryType])

    return (
        <Grid style={{ marginTop: 0 }} container spacing={2} alignItems="center">
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    name="deliverTo"
                    value={deliverTo}
                    label={<FormattedMessage id="CARE_OF" />}
                    onChange={handleDeliveryChange}
                    required
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="fileDeliveryType"
                    fullWidth
                    label={<FormattedMessage id="DELIVERY_TYPE" />}
                    value={fileDeliveryType}
                    onChange={handleDeliveryChange}
                >
                    {
                        fileDeliveryOptions.map(({ id, description, tip } ) => (
                            <MenuItem value={id} key={id}>
                               {tip ? description.concat(' (', tip, ')') : description}
                            </MenuItem>
                        ))
                    }
                </FormSelect>
            </Grid>
            {
                fileDeliveryType !== hardDiskId ? null : (
                    <Grid item xs={12}>
                        <FormSelect
                            fullWidth
                            required
                            label={<FormattedMessage id="DELIVERY_METHOD" />}
                            name="hardDiskDeliveryType"
                            value={hardDiskDeliveryType}
                            // onChange={handleDestinationChange}
                            onChange={handleDeliveryChange}
                        >
                            {
                                tapeHdDeliveryTypes.map(({ id, description, tip } ) => (
                                    <MenuItem value={id} key={id}>
                                       {tip ? description.concat(' (', tip, ')') : description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                fileDeliveryType === hardDiskId || fileDeliveryType === '' ? null : (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline={fileDeliveryType === clientServerId}
                            variant={fileDeliveryType === clientServerId ? "outlined" : undefined}
                            rows={fileDeliveryType === clientServerId ? 10 : undefined}
                            name="deliveryAddress"
                            type={fileDeliveryType === clientServerId ? undefined : "email"}
                            value={deliveryAddress}
                            label={<FormattedMessage id={fileDeliveryType === clientServerId ? "SUP_INFO" : "EMAIL"} />}
                            // onChange={handleDestinationChange}
                            onChange={handleDeliveryChange}
                            required
                        />
                    </Grid>
                )
            }

            {
                fileDeliveryType !== hardDiskId || hardDiskDeliveryType === pickUpId || hardDiskDeliveryType === '0' || hardDiskDeliveryType === '' ? null : (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            variant={fileDeliveryType === hardDiskId ? "outlined" : undefined}
                            name="deliveryAddress"
                            value={deliveryAddress}
                            label={<FormattedMessage id="POSTAL_ADDRESS" />}
                            // onChange={handleDestinationChange}
                            onChange={handleDeliveryChange}
                            required
                        />
                    </Grid>
                )
            }
            {
                (fileDeliveryType !== '' || fileDeliveryType !== 'other') ? null : (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={10}
                            name="deliveryAddress"
                            value={deliveryAddress}
                            label={<FormattedMessage id="SUP_INFO" />}
                            // onChange={handleDestinationChange}
                            onChange={handleDeliveryChange}
                            required
                        />
                    </Grid>
                )
            }
        </Grid>

    );
}

export default FileDestination;
