import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const propTypes = {
    intlText: PropTypes.string,
    className: PropTypes.string,
    intlTextVariant: PropTypes.string,
    intlText2: PropTypes.string,
    intlText2Variant: PropTypes.string,
};

const defaultProps = {
    intlText: '',
    className: '',
    intlTextVariant: 'subtitle1',
    intlText2: '',
    intlText2Variant: 'subtitle2',
};


const useStyles = makeStyles((theme) => createStyles({
    panel: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    text: {
        marginTop: theme.spacing(2),
    },
}));


function Loading(props) {
    const { intlText, intlTextVariant, intlText2, intlText2Variant, className } = props;
    const classes = useStyles();

    return (
        <div className={`${classes.panel} ${className}`}>
            <CircularProgress color="inherit" />
            {
                !intlText ? null : (
                    <Typography className={classes.text} variant={intlTextVariant}>
                        <FormattedMessage id={intlText} />
                    </Typography>
                )
            }
            {
                !intlText2 ? null : (
                    <Typography className={classes.text} variant={intlText2Variant}>
                        <FormattedMessage id={intlText2} />
                    </Typography>
                )
            }
        </div>
    );
}

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;
