import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import { useUser } from 'contexts/user';

const propTypes = {
    children: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    fullWidth: PropTypes.bool,
    inProgressClass: PropTypes.string,
    progressBarClass: PropTypes.string,
    progressContainerClass: PropTypes.string,
    classes: PropTypes.string,
    hideContentOnProgress: PropTypes.bool,
    url: PropTypes.string.isRequired,
    urlData: PropTypes.shape({}),
    fileName: PropTypes.string,
    handleFailed: PropTypes.func,
    progressType: PropTypes.oneOf(['linear', 'circular', 'none']),
    progressSize: PropTypes.number,
    progressThickness: PropTypes.number,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    disabled: PropTypes.bool,
};

const defaultProps = {
    children: null,
    component: undefined,
    progressSize: undefined,
    progressThickness: undefined,
    classes: undefined,
    inProgressClass: undefined,
    progressBarClass: undefined,
    progressContainerClass: undefined,
    size: undefined,
    fullWidth: undefined,
    progressType: 'circular',
    hideContentOnProgress: false,
    urlData: {},
    handleFailed: () => {},
    disabled: false,
    variant: 'text',
    newTab: false,
    fileName: '',
};

const defaultSize = {
    small: 14,
    medium: 16,
    large: 18,
    [undefined]: 16, // assuming medium as default
};

function DownloadButton(props) {
    const {
        children, component, size, classes,
        inProgressClass, progressBarClass, progressType, progressContainerClass,
        hideContentOnProgress, url, fileName, handleFailed,
        fullWidth, progressSize, progressThickness, urlData,
        disabled, variant, newTab, apiOptions = {}, color, startIcon: defaultIcon,
        iconButton = false
    } = props;


    const { api } = useUser();

    const showProgress = progressType !== 'none';
    const downloadAnchor = React.useRef(null);
    const [currentProgress, setCurrentProgress] = React.useState(0);
    const [inProgress, setInProgress] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [objectUrl, setObjectUrl] = React.useState(null);
    const [responseFileName, setResponseFileName] = React.useState('');

    function handleDownloadBlob() {
        setTimeout(() => {
            URL.revokeObjectURL(objectUrl);
        }, 150);
    }

    function resetButton() {
        setCurrentProgress(0);
        setInProgress(false);
        setFailed(false);
    }

    const handleDownload = async () => {
        setInProgress(true);
        setFailed(false);
        try {
            const abortController = new AbortController();

            const response = await api.get(url, urlData, { signal: abortController.signal, responseType: 'blob', artificialDelay: 100, ...apiOptions });
            const totalBytes = response.headers.get('Content-Length');
            const contentType = response.headers.get('Content-Type');
            const contentDisposition = response.headers.get('Content-Disposition');

            if (contentDisposition) {
                setResponseFileName(decodeURI(contentDisposition.split(';')[1].split('=')[1]));
            }

            const chunks = [];
            let bytesReceived = 0;
            if (response.ok) {
                const reader = response.body.getReader();
                while (true) {
                    const result = await reader.read();
                    if (result.done) {
                        break;
                    }
                    chunks.push(result.value);
                    bytesReceived += result.value.length;
                    const percentage = parseInt((bytesReceived / totalBytes) * 100, 10);
                    if (percentage !== currentProgress) {
                        setCurrentProgress(percentage);
                    }
                }
                const blob = new Blob(chunks, { type: contentType });
                setObjectUrl(window.URL.createObjectURL(blob));
                downloadAnchor.current.click();
            } else {
                setFailed(true);
                handleFailed();
            }
        } catch (error) {
            console.error(error);
            setFailed(true);
            handleFailed();
        }
        setTimeout(resetButton, 6000);
    };

    let currentClasses = classes;
    if (inProgress && inProgressClass) {
        currentClasses = currentClasses.split(',').concat(inProgressClass.split(',')).join(', ');
    }

    let Progress = null;

    if (progressType === 'circular') {
        Progress = CircularProgress;
    } else if (progressType === 'linear') {
        Progress = LinearProgress;
    }

    let startIcon = defaultIcon;

    if (inProgress && showProgress) {
        if (currentProgress !== 100 && !failed) {
            startIcon = (
                <Progress
                    size={progressSize || defaultSize[size]}
                    thickness={progressThickness}
                    value={currentProgress}
                    variant={currentProgress > 0 ? 'determinate' : 'indeterminate'}
                />
            );
        } else if (currentProgress === 100 && !failed) {
            startIcon = (
                <CheckIcon
                    color="primary"
                />
            );
        } else if (failed) {
            startIcon = (
                <ErrorIcon
                    color="error"
                />
            );
        }
    }

    let buttonContent = hideContentOnProgress && inProgress && showProgress ? null : children;
    let Component = Button;

    if (iconButton) {
        Component = IconButton;
        buttonContent = startIcon || children
    }

    return (
        <>
            <Component
                component={component}
                className={currentClasses}
                size={size}
                onClick={handleDownload}
                disabled={disabled || inProgress}
                {... iconButton ? {} : { fullWidth, startIcon }}
                variant={variant}
                color={color}
            >
                {buttonContent}
            </Component>
            <a
                style={{ display: 'none' }}
                ref={downloadAnchor}
                onClick={handleDownloadBlob}
                href={objectUrl}
                download={fileName || responseFileName || true}
                disabled
                target={newTab ? '_blank' : undefined}
            >
                {fileName}
            </a>
        </>
    );
}

DownloadButton.propTypes = propTypes;
DownloadButton.defaultProps = defaultProps;

export default DownloadButton;
