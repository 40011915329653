import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';

import { DEFAULT_LANGUAGE, DEFAULT_THEME } from 'utils/constants';
import { useUser } from 'contexts/user';

const useStyles = makeStyles((theme) => createStyles({
    input: {
        marginRight: theme.spacing(2),
    },
    fullWidth: {
        width: '100%',
    },
}));

const propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';
const AUTO_THEME = 'auto';

const EMAIL_DAILY = 'DAILY';
const EMAIL_YES = 'REAL_TIME';
const EMAIL_HOURLY = 'HOURLY';
const EMAIL_NO = 'NONE';

const POPUP_YES = 'REAL_TIME';
const POPUP_NO = 'NONE';

function Settings(props) {
    const { open, onClose } = props;
    const {
        user, auth, fetchUser, api,
    } = useUser();
    const [state, setState] = React.useState({
        firstName: user && (user.firstName || ''),
        lastName: user && (user.lastName || ''),
        language: user && (user.language.toLowerCase() || DEFAULT_LANGUAGE),
        theme: user.theme && (user.theme.toLowerCase() || DEFAULT_THEME),
        email: user && (user.email || ''),
        byPopUp: POPUP_YES,
        byEmail: EMAIL_YES,
        newPassword: '',
        retypePassword: '',
    });
    const [inProgress, setInProgress] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [showRetypePassword, setShowRetypePassword] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {
        language, theme, newPassword, retypePassword,
        email, firstName, lastName, byPopUp, byEmail
    } = state;

    const classes = useStyles();
    const passwordValid = isEmpty(newPassword) ? undefined : newPassword.length >= 8;

    React.useEffect(() => {
        setState({
            firstName: user && (user.firstName || ''),
            lastName: user && (user.lastName || ''),
            language: user && (user.language.toLowerCase() || DEFAULT_LANGUAGE.toLowerCase()),
            theme: user.theme && (user.theme.toLowerCase() || DEFAULT_THEME),
            email: user && (user.email || ''),
            newPassword: '',
            retypePassword: '',
            byPopUp:  user && (user.byPopUp || POPUP_YES),
            byEmail: user && (user.byEmail || EMAIL_YES),
        });
    }, [user]);


    function handleChange({ target: { value, name } }) {
        setState({ ...state, [name]: value });
    }

    async function updateUser() {
        const passwordsValid = passwordValid && newPassword === retypePassword;
        if (isEmpty(newPassword) || passwordsValid) {
            const data = {
                firstName,
                lastName,
                uid: user.uid,
                language,
                theme,
                email,
                byPopUp,
                byEmail,
            };
            if (passwordsValid) {
                data.password = newPassword;
                data.passwordMatch = retypePassword;
            }
            if (!isEmpty(data)) {
                setInProgress(true);
                const snackId = enqueueSnackbar(<FormattedMessage id="USER_UPDATING" />, { variant: 'loading' });
                const response = await api.put('/user/settings', data);

                if (response.ok) {
                    if (auth.isAuthenticated() && auth.getAccessToken()) {
                        const token = auth.getAccessToken();
                        auth.getUserInfo(token, (error, profile) => {
                            if (error) {
                                return;
                            }
                            fetchUser(() => {
                                closeSnackbar(snackId);
                                enqueueSnackbar(<FormattedMessage id="USER_UPDATED" />, { variant: 'success' });
                                localStorage.setItem('theme', data.theme);
                                onClose();
                            });
                        });
                    }
                } else {
                    let message = '';
                    switch (response.status) {
                        case 452:
                            message = 'ERROR_UPDATING_USER';
                            break;
                        case 413:
                            message = 'FILE_SIZE_TOO_LARGE';
                            break;
                        case 456:
                            message = 'OLD_PASSWORD';
                            break;
                        default:
                            message = 'ERROR_UPDATING_USER';
                            break;
                    }
                    closeSnackbar(snackId);
                    enqueueSnackbar(<FormattedMessage id={message} />, { variant: 'error' });
                }
                setInProgress(false);
            } else {
                enqueueSnackbar(<FormattedMessage id="NO_CHANGES_TO_SUBMIT" />, { variant: 'error' });
            }
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <FormattedMessage id="SETTINGS" />
            </DialogTitle>
            <DialogContent>
                <form>
                    <Grid container wrap="wrap" spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                size="small"
                                fullWidth
                                className={classes.inputs}
                                name="firstName"
                                value={firstName}
                                onChange={handleChange}
                                label={<FormattedMessage id="FIRST_NAME" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                size="small"
                                fullWidth
                                className={classes.inputs}
                                name="lastName"
                                value={lastName}
                                onChange={handleChange}
                                label={<FormattedMessage id="LAST_NAME" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                size="small"
                                fullWidth
                                className={classes.inputs}
                                name="email"
                                value={email}
                                onChange={handleChange}
                                label={<FormattedMessage id="EMAIL" />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl size="small" component="fieldset" margin="dense" fullWidth>
                                <FormLabel component="legend">
                                    <FormattedMessage id="MODIFY_PASSWORD" />
                                </FormLabel>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    type={showPassword ? 'text' : 'password'}
                                    value={newPassword}
                                    name="newPassword"
                                    onChange={handleChange}
                                    label={<FormattedMessage id="NEW_PASSWORD" />}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    margin="dense"
                                                    size="small"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    type={showRetypePassword ? 'text' : 'password'}
                                    value={retypePassword}
                                    name="retypePassword"
                                    disabled={!passwordValid}
                                    onChange={handleChange}
                                    error={newPassword !== retypePassword}
                                    helperText={newPassword !== retypePassword ? <FormattedMessage id="PW_DO_NOT_MATCH" /> : ''}
                                    label={<FormattedMessage id="RE_TYPE_PASSWORD" />}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    margin="dense"
                                                    onClick={() => setShowRetypePassword(!showRetypePassword)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                >
                                                    {showRetypePassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl size="small" component="fieldset" margin="dense" fullWidth>
                                <FormLabel component="legend">
                                    <FormattedMessage id="PREFERED_LANGUAGE" />
                                </FormLabel>
                                <RadioGroup
                                    name="language"
                                    value={language}
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value="en"
                                        control={<Radio size="small" margin="dense" />}
                                        label={<FormattedMessage id="ENGLISH" />}
                                    />
                                    <FormControlLabel
                                        value="fr"
                                        control={<Radio size="small" margin="dense" />}
                                        label={<FormattedMessage id="FRENCH" />}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl component="fieldset" size="small" margin="dense" fullWidth>
                                <FormLabel component="legend">
                                    <FormattedMessage id="THEME" />
                                </FormLabel>
                                <RadioGroup
                                    name="theme"
                                    value={theme}
                                    onChange={handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value={LIGHT_THEME}
                                        control={<Radio size="small" margin="dense" />}
                                        label={<FormattedMessage id="LIGHT" />}
                                    />
                                    <FormControlLabel
                                        value={DARK_THEME}
                                        control={<Radio size="small" margin="dense" />}
                                        label={<FormattedMessage id="DARK" />}
                                    />
                                    <FormControlLabel
                                        value="auto"
                                        control={<Radio size="small" margin="dense" />}
                                        label={<FormattedMessage id="AUTO" />}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <FormattedMessage id="DISCUSSION_NOTIFICATION" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl component="fieldset" margin="dense" size="small">
                                    <FormLabel component="legend">
                                        <FormattedMessage id="BY_EMAIL" />
                                    </FormLabel>
                                    <RadioGroup
                                        name="byEmail"
                                        value={byEmail}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel
                                            value={EMAIL_YES}
                                            control={<Radio margin="dense" size="small" />}
                                            label={<FormattedMessage id="YES" />}
                                        />
                                        <FormControlLabel
                                            value={EMAIL_DAILY}
                                            control={<Radio margin="dense" size="small" />}
                                            label={<FormattedMessage id="DAILY" />}
                                        />
                                        <FormControlLabel
                                            value={EMAIL_HOURLY}
                                            control={<Radio margin="dense" size="small" />}
                                            label={<FormattedMessage id="HOURLY" />}
                                        />
                                        <FormControlLabel
                                            value={EMAIL_NO}
                                            control={<Radio margin="dense" size="small" />}
                                            label={<FormattedMessage id="NO" />}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl component="fieldset" margin="dense" size="small">
                                    <FormLabel component="legend">
                                        <FormattedMessage id="BY_POP_UP" />
                                    </FormLabel>
                                    <RadioGroup
                                        name="byPopUp"
                                        value={byPopUp}
                                        onChange={handleChange}
                                        row
                                    >
                                        <FormControlLabel
                                            value={POPUP_YES}
                                            control={<Radio margin="dense" size="small" />}
                                            label={<FormattedMessage id="YES" />}
                                        />
                                        <FormControlLabel
                                            value={POPUP_NO}
                                            control={<Radio margin="dense" size="small" />}
                                            label={<FormattedMessage id="NO" />}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                >
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    onClick={updateUser}
                    disabled={inProgress}
                >
                    <FormattedMessage id="SAVE" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

Settings.propTypes = propTypes;

export default Settings;
