import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Skeleton from '@mui/material/Skeleton';

import EntityCard from './Card';
import { useUser } from 'contexts/user';
import InfiniteScroll from 'components/InfiniteScroll';

const useStyles = makeStyles((theme) => createStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 150px)',
        gridAutoRows: 'auto',
        gridGap: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        justifyContent: 'space-between',
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(auto-fill, 200px)',
        }
    },
    entityTitle: {
        marginTop: theme.spacing(2),
    },
    entityTitle2: {
        marginTop: theme.spacing(0.4),
    },
    entity: { // modify Card.jsx as well
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'visible',
        textOverflow: 'hidden',
        position: 'relative',
        padding: theme.spacing(1),
        '& + &': {
            margin: 0,
        },
    },
    entityAction: { // modify Card.jsx as well
        width: '100%',
        display: 'flex',
        textAlign: 'inherit',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'initial',
        height: '225px',
        [theme.breakpoints.up('lg')]: {
            height: '300px',
        },
        '& .MuiCardActionArea-focusHighlight, & .MuiTouchRipple-root': {
            aspectRatio: '2/3',
        },
    },
}));

const propTypes = {
    titles: PropTypes.arrayOf(PropTypes.shape({ fullCount: PropTypes.number })).isRequired,
    fetchingTitles: PropTypes.bool.isRequired,
    selectionEnabled: PropTypes.bool.isRequired,
    selectedTitles: PropTypes.arrayOf(PropTypes.number),
    onTitleClick: PropTypes.func,
    loadMore: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    currentClientId: PropTypes.number.isRequired,
};
const defaultProps = {
    selectedTitles: [],
    onTitleClick: () => {},
};

function Entities(props) {

    const {
        titles, fetchingTitles, selectionEnabled,
        selectedTitles, onTitleClick, currentClientId,
        loadMore, limit
    } = props;

    const { api, user } = useUser();
    const scrollableTarget = useRef(null);
    const classes = useStyles();

    const userLang = user.language.toLowerCase();
    const imageLang = ['fr', 'en'].includes(userLang) ? `default_${userLang}.png` : 'default.png';
    const defaultImage = api.static(`/images/titles/${imageLang}`, {}, 'SERVICE');

    const fullCount = (titles.length > 0 ? titles[0].fullCount : 0);

    return (
        <div style={{ overflow: 'auto' }} ref={scrollableTarget}>
            <InfiniteScroll
                component="div"
                hasMore={titles.length < fullCount}
                className={classes.grid}
                callback={loadMore}
                root={scrollableTarget.current}
                isLoading={fetchingTitles}
                loader={range(limit).map((i) => (
                    <div key={i} className={classes.entity}>
                        <Skeleton variant="rectangular" className={classes.entityAction} />
                        <Skeleton width="60%" className={classes.entityTitle} />
                        <Skeleton width="40%" className={classes.entityTitle2} />
                    </div>
                ))}
            >

                {titles.map(({
                    title: originalTitle, poster, id, inVault: entityInVault, entityType: entityTypeLabel,
                    aggregator,
                }) => (
                    <EntityCard
                        key={id}
                        classes={classes}
                        id={id}
                        originalTitle={originalTitle}
                        entityType={entityTypeLabel}
                        inVault={entityInVault}
                        defaultImage={defaultImage}
                        // poster={`/${currentClientId}/entity/${id}/poster`}
                        poster={poster ? api.static(`/storage/${poster}`, null, 'SERVICE') : defaultImage}
                        selected={selectionEnabled && selectedTitles.includes(id)}
                        onClick={selectionEnabled ? onTitleClick : undefined}
                        href={selectionEnabled ? undefined : `/distribution/${currentClientId}/entities/${id}/`}
                        aggregator={aggregator}
                    />
                ))}
            </InfiniteScroll>
        </div>
    );
}

Entities.propTypes = propTypes;
Entities.defaultProps = defaultProps;

export default Entities;
