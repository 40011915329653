import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from 'contexts/user';


const propTypes = {
    children: PropTypes.node.isRequired,
    currentClientId: PropTypes.number,
    permissions: PropTypes.shape({}),
};

const defaultProps = {
    currentClientId: 0,
    permissions: {},
};

export function permissionCheck(routePermissions, clientDefinition = {}) {
    return Object.keys(routePermissions).every((permission) => (
        clientDefinition[permission] >= routePermissions[permission]
    ));
}

function ForbiddenCheck(props) {
    const { currentClientId, permissions, children } = props;
    const { user: { clients = [] } } = useUser();

    const currentClient = clients.find((c) => c.client === currentClientId);

    const forbidden = !permissionCheck(permissions, currentClient);
    return !forbidden ? children : (
        <Redirect
            to={{
                pathname: `/${currentClientId}`,
            }}
        />
    );
}

ForbiddenCheck.propTypes = propTypes;
ForbiddenCheck.defaultProps = defaultProps;

export default ForbiddenCheck;
