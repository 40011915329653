import React from 'react';
import clsx from 'clsx';
import isString from 'lodash/isString';
import range from 'lodash/range';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles((theme) => createStyles({
    chips: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
    break: {
        overflowWrap: 'break-word',
    },
}));



function Detail(props) {
    const {
        label, children, xs, sm,
        md, lg, xl, chips,
        max, breakWord = false,
        style, loading, contentClassName,
    } = props;

    const classes = useStyles();

    if (loading) {
        return (
            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} style={style}>
                {
                    !label ? null : (
                        <Typography color="textSecondary" variant="body1">
                            <Skeleton width="6rem" />
                        </Typography>
                    )
                }
                {
                    !chips ? (
                        <Typography color="textPrimary" variant="body2" className={!breakWord ? undefined : classes.break}>
                            <Skeleton width={[xs, sm, md, lg, xl].includes('auto') ? '10rem' : '100%'} />
                        </Typography>
                    ) : (
                        <Grid container spacing={1} className={contentClassName}>
                            {
                                range(max).map((val) => (
                                    <Grid item key={val}>
                                        <Skeleton width="4rem" />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    )
                }
            </Grid>
        )
    }


    if (!children || children.length <= 0 || (chips && !Array.isArray(children))) {
        return null;
    }
    let value = children;
    if (chips && children[0] instanceof Object && 'label' in children[0]) {
        value = children.map((v) => v.label);
    } else if (children instanceof Object && 'label' in children) {
        value = children.label;
    }

    let labelComponent = null;

    if (label) {
        if (isString(label)) {
            labelComponent = (
                <Typography color="textSecondary" variant="body1">
                    <FormattedMessage id={label} />
                </Typography>
            );
        } else { // find out a better way of checking if label is a node i.e. can be rendered
            labelComponent = label;
        }
    }

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} style={style}>
            { labelComponent }
            {
                !chips ? null : (
                    <Grid container spacing={1}>
                        {
                            value.slice(0, max).map((val) => (
                                <Grid item key={val}>
                                    <Chip
                                        className={classes.chips}
                                        label={val}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                )
            }
            {
                chips ? null : (
                    <Typography color="textPrimary" variant="body2" className={clsx((!breakWord ? undefined : classes.break), contentClassName)}>
                        {value}
                    </Typography>
                )
            }

        </Grid>
    );
}

export default Detail;
