import React from 'react';
import PropTypes from 'prop-types';
import { useParams, generatePath, Redirect, useHistory } from 'react-router-dom';

import Paths from 'routes/Paths';
import { useUser } from 'contexts/user';

const Dashboard = (props) => {
    const { user: { clients } } = useUser();
    const { page, client } = useParams();
    const history = useHistory();

    const clientParam = !client && page ? page : client;

    let currentClientId = parseInt(clientParam, 10) || clients.find((c) => c.default).client;
    let currentClient = clients.find(({ client: c }) => c === currentClientId);

    if (!currentClient) {
        currentClient = clients.find((c) => c.default);
        currentClientId = currentClient.client;
    }

    const toPath = (Paths.find(({ path, permissions = {} }) => (
        path.indexOf(`/${page}/${client}`) === 0 && Object.keys(permissions).every((permission) => (
            currentClient[permission] >= permissions[permission]
        ))
    )) || Paths.find(({ permissions = {} }) => (
        Object.keys(permissions).every((permission) => (
            currentClient[permission] >= permissions[permission]
        ))
    ))).path;

    return (<Redirect to={{  pathname: generatePath(toPath, { client: currentClientId }), search: history.location.search }} />);
};

export default Dashboard;





