import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import format from 'date-fns/format';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { useUser } from 'contexts/user';
import DownloadButton from 'components/DownloadButton';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'none',
        padding: 0,
        height: 0,
        opacity: 0,
        pointerEvents: 'none',
        transition: `all ${theme.transitions.easing.easeInOut}`,
    },
    visible: {
        display: 'flex',
        height: 'auto',
        opacity: 1,
        pointerEvents: 'auto',
        padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
    },
}));

const propTypes = {
    selectionEnabled: PropTypes.bool.isRequired,
    hasSelection: PropTypes.bool.isRequired,
    selectionCount: PropTypes.number,
    openModal: PropTypes.func.isRequired,
    selectedTitles: PropTypes.arrayOf(PropTypes.number),
    currentClientId: PropTypes.number.isRequired,
    managePermission: PropTypes.bool.isRequired,
    nonVisibleSelected: PropTypes.number,
};
const defaultProps = {
    selectionCount: 0,
    nonVisibleSelected: 0,
    selectedTitles: [],
};

function SelectionMenu(props) {

    const {
        selectionEnabled, hasSelection, selectionCount,
        openModal, selectedTitles, currentClientId, managePermission,
        nonVisibleSelected, clientName
    } = props;

    const classes = useStyles();
    const { user } = useUser();
    const visible = selectionEnabled && managePermission;

    const exportFileName = React.useMemo(() => {
        const dateFileName = format(Date.now(), 'yyyy-MM-dd_HH-m');
        const cleanClientName = clientName.replace(/\./g, '').replace(/\s+/g, '_');
        return `${cleanClientName}_${dateFileName}.xlsx`;
    }, [clientName]);

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            className={`${classes.root} ${visible ? classes.visible : ''}`}
        >
            <Grid item xs>
                <Typography>
                    <FormattedMessage id="X_TITLES_SELECTED" values={{ itemCount: selectionCount }} />
                    {
                        nonVisibleSelected <= 0 ? null : (
                            <Typography component="em" color="textSecondary">
                                <FormattedMessage id="X_TITLES_HIDDEN" values={{ itemCount: nonVisibleSelected }} />
                            </Typography>
                        )
                    }
                </Typography>
            </Grid>
            <Grid item>
                <Tooltip title={<FormattedMessage id="EXPORT_DEFINITION" />}>
                    <span>
                        <DownloadButton
                            url={`${currentClientId}/forest/`}
                            urlData={{ entityIds: selectedTitles, export: true }}
                            disabled={!hasSelection}
                            fileName={exportFileName}
                            variant="outlined"
                        >
                            <FormattedMessage id="EXPORT" />
                        </DownloadButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item>
                <Button
                    value="WORKSPACE"
                    onClick={openModal}
                    disabled={!hasSelection}
                    variant="outlined"
                >
                    <FormattedMessage id="ADD_TO_WORKSPACE" />
                </Button>
            </Grid>
            <Grid item>
                <Button
                    value="ORDER"
                    onClick={openModal}
                    disabled={!hasSelection}
                    variant="outlined"
                >
                    <FormattedMessage id="ORDER" />
                </Button>
            </Grid>
        </Grid>
    );
}

SelectionMenu.propTypes = propTypes;
SelectionMenu.defaultProps = defaultProps;

export default SelectionMenu;
