import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import DateRangePicker from 'components/DateRangePicker';

const propTypes = {
    initialFilters: PropTypes.shape().isRequired,
    applyFilters: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {
};


const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
    },
}));

function InvoiceFilter(props) {
    const {
        initialFilters,
        applyFilters,
        isLoading,
    } = props;
    const classes = useStyles();

    const [filters, setFilters] = React.useState(initialFilters);

    function handleInputChange({ target: { name, value } }) {
        setFilters({ ...filters, [name]: value });
    }

    const {
        fromDate, toDate, po,
        invoiceNumber,
    } = filters;

    function filterInvoices(event) {
        event.preventDefault();
        applyFilters(filters);
    }

    return (
        <Grid
            component="form"
            container
            spacing={1}
            alignItems="center"
            onSubmit={filterInvoices}
        >
            <Grid item xs>
                <TextField
                    variant="outlined"
                    name="invoiceNumber"
                    size="small"
                    fullWidth
                    margin="dense"
                    value={invoiceNumber}
                    label={<FormattedMessage id="INVOICE_NUMBER" />}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    variant="outlined"
                    name="po"
                    size="small"
                    fullWidth
                    margin="dense"
                    value={po}
                    label={<FormattedMessage id="PO_NUMBER" />}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs>
                <DateRangePicker
                    clearable
                    fullWidth
                    inputformat="yyyy-MM-dd"
                    inputVariant="outlined"
                    margin="dense"
                    size="small"
                    disableToolbar
                    startValue={fromDate}
                    startName="fromDate"
                    startLabel={<FormattedMessage id="BILLED_FROM" />}
                    endName="toDate"
                    endValue={toDate}
                    endLabel={<FormattedMessage id="BILLED_TO" />}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item>
                <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    disabled={isLoading}
                >
                    <FormattedMessage id="SEARCH" />
                </Button>
            </Grid>
        </Grid>
    );
}

InvoiceFilter.propTypes = propTypes;
InvoiceFilter.defaultProps = defaultProps;

export default InvoiceFilter;
