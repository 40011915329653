import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage, FormattedDate } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import StepContent from '@mui/material/StepContent';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import ItemDetail from 'components/Detail';
import RouteLink from 'components/RouteLink';
import Documents from 'components/Documents';

const propTypes = {
    orderType: PropTypes.oneOf(['VOD', 'VAULT', 'CREATION', 'DISTRIBUTION']).isRequired,
    poNumber: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    orderStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    availabilityStart: PropTypes.string, // ISO Date string.
    availabilityEnd: PropTypes.string, // ISO Date string.
    orderDate: PropTypes.string.isRequired, // ISO Date string.
    orderBy: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    deliverTo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    deliverDate: PropTypes.string, // ISO Date string.
    packageContent: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
    })),
    assetLink: PropTypes.string,
    cancelledDate: PropTypes.string, // ISO Date string.
    hasProblem: PropTypes.bool,
    additionalDetails: PropTypes.string,
};

const defaultProps = {
    poNumber: null,
    availabilityStart: null,
    availabilityEnd: null,
    orderBy: null,
    deliverTo: null,
    deliverDate: null,
    packageContent: [],
    assetLink: null,
    cancelledDate: null,
    hasProblem: false,
    additionalDetails: null,
};

const useStyles = makeStyles((theme) => createStyles({
    strikethrough: {
        textDecoration: 'line-through',
    },
    problemContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    problemIcon: {
        marginRight: theme.spacing(1),
    },
    statusIndicator: {
        marginLeft: 'auto',
        textAlign: 'end',
    },
    block: {
        '& + &': {
            marginTop: theme.spacing(2),
        },
    },
    lastBaseline: {
        alignItems: 'last baseline',
    },
    stepper: {
        padding: theme.spacing(1),
    },
    warning: {
        color: theme.palette.warning.main,
    },
}));

const QontoConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => createStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    error: {
        color: theme.palette.error.main,
        zIndex: 1,
        fontSize: 18,
    },
}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed, error } = props;
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {!error ? null : <ErrorIcon className={classes.error} /> }
            {!completed || error ? null : <Check className={classes.completed} />}
            {completed || error ? null : <div className={classes.circle} />}
        </div>
    );
}

function getSteps() {
    return ['PENDING', 'WAITING_FOR_MASTER', 'MASTER_RECIEVED', 'IN_PRODUCTION', 'WORK_COMPLETED', 'BILLED'];
}

function getActiveStep(statusId) {
    return {
        [undefined]: 0,
        0: 0,
        3: 1,
        4: 2,
        5: 3,
        6: 4,
        7: 5,
    }[statusId];
}

function Details(props) {
    const {
        orderType, poNumber, title, description,
        orderStatus, availabilityStart, availabilityEnd,
        orderDate, orderBy, deliverTo, deliverDate,
        packageContent, assetLink, cancelledDate,
        hasProblem, additionalDetails, orderStatusId,
        broadcastDelay, cancelledReason, cancelledBy,
        orderDocuments, orderId, createdClientName
    } = props;

    const classes = useStyles();
    const steps = getSteps();
    const defaultSpacing = 2;
    return (
        <Grid container spacing={defaultSpacing}>
            {
                orderStatusId !== 8 ? null : (
                    <Grid item xs={12}>
                        <Typography variant="h6" color="error">
                            <FormattedMessage
                                id={cancelledBy && cancelledDate ? "CANCELLED_ON_BY" : cancelledDate ? "CANCELLED_ON" : "CANCELLED"}
                                values={{ name: cancelledBy, date: parseISO(cancelledDate) }}
                            />
                        </Typography>
                        {
                            !cancelledReason ? null : (
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="CANCELLED_REASON"
                                        values={{ reason: cancelledReason }}
                                    />
                                </Typography>
                            )
                        }
                    </Grid>
                )
            }
            <Grid item xs={4}>
                <Stepper
                    className={classes.stepper}
                    activeStep={getActiveStep(orderStatusId)}
                    orientation="vertical"
                >
                    {
                        steps.map((label, index) => {
                            const error = hasProblem && !cancelledDate && index === getActiveStep(orderStatusId);

                            return (
                                <Step
                                    key={label}
                                    disabled={(orderStatusId !== 8 || cancelledDate) && index > getActiveStep(orderStatusId)}
                                    completed={index < getActiveStep(orderStatusId) || (index >= 2 && index <= getActiveStep(orderStatusId))}
                                >
                                    <StepLabel
                                        error={error}
                                        optional={error ? <Typography variant="caption" color="error" />
                                     : undefined}
                                    >
                                        <FormattedMessage id={label} />
                                    </StepLabel>
                                    <StepContent>
                                        {
                                            // broadcastDelay <= 1
                                            broadcastDelay === null || broadcastDelay > 1 ? null : (
                                                <Typography variant="caption" color="error" component="p">
                                                    <strong>
                                                        <FormattedMessage id="LATE_FOR_BROADCAST" />
                                                    </strong>
                                                </Typography>
                                            )
                                        }
                                        {
                                            // broadcastDelay <= 7
                                            broadcastDelay === null || broadcastDelay > 7 || broadcastDelay <= 1 ? null : (
                                                <Typography variant="caption" component="p" className={classes.warning}>
                                                    <strong>
                                                        <FormattedMessage id="MIGHT_BE_LATE_FOR_BROADCAST" />
                                                    </strong>
                                                </Typography>
                                            )
                                        }
                                        {
                                            !error ? null : (
                                                <Typography variant="caption" component="p">
                                                    <em>
                                                        <FormattedMessage id="THERE_IS_A_PROBLEM_SEE_DISCUSSIONS" />
                                                    </em>
                                                </Typography>
                                            )
                                        }
                                    </StepContent>
                                </Step>
                            );
                        })

                    }
                </Stepper>
            </Grid>
            <Grid item container xs={8} alignContent="flex-start">
                {/* <Grid container item className={classes.block}> */}
                {/*     <Grid item> */}
                {/*         {orderType} */}
                {/*     </Grid> */}
                {/*     <ItemDetail> */}
                {/*         {poNumber} */}
                {/*     </ItemDetail> */}
                {/* </Grid> */}
                <Grid container item alignContent="flex-start" className={classes.block}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            !assetLink ? description : (
                                <RouteLink
                                    to={assetLink}
                                >
                                    {description}
                                </RouteLink>
                            )
                        }
                    </Grid>
                    <ItemDetail xs={12}>
                        {
                            availabilityStart || !availabilityEnd ? null : (
                                <FormattedMessage
                                    id="AVAILABLE_UNTIL_DATE"
                                    values={{ windowEnd: parseISO(availabilityEnd) }}
                                />
                            )
                        }
                        {
                            !availabilityStart || availabilityEnd ? null : (
                                <FormattedMessage
                                    id="AVAILABLE_FROM_DATE"
                                    values={{ windowStart: parseISO(availabilityStart) }}
                                />
                            )
                        }
                        {
                            !availabilityStart || !availabilityEnd ? null : (
                                <FormattedMessage
                                    id="AVAILABILITY_DATE"
                                    values={{ windowStart: parseISO(availabilityStart), windowEnd: parseISO(availabilityEnd) }}
                                />
                            )
                        }
                    </ItemDetail>
                    <ItemDetail xs={12}>
                        {
                            !additionalDetails ? null : (
                                <em>{additionalDetails}</em>
                            )
                        }
                    </ItemDetail>
                </Grid>
                <Grid container item alignContent="flex-start" className={classes.block}>
                    <ItemDetail>
                        {
                            deliverDate || !deliverTo ? null : (
                                <FormattedMessage id="DELIVER_TO_NAME" values={{ deliverTo }} />
                            )
                        }
                        {
                            !deliverDate || deliverTo ? null : (
                                <FormattedMessage id="DELIVER_BY_DATE" values={{ deliverDate: parseISO(deliverDate) }} />
                            )
                        }
                        {
                            !deliverDate || !deliverTo ? null : (
                                <FormattedMessage id="DELIVER_TO_NAME_BY_DATE" values={{ deliverTo, deliverDate: parseISO(deliverDate) }} />
                            )
                        }
                    </ItemDetail>
                    <ItemDetail>
                        {
                            orderDate || !orderBy ? null : (
                                <FormattedMessage id="ORDER_BY_NAME" values={{ orderBy, createdClientName: createdClientName || null }} />
                            )
                        }
                        {
                            !orderDate || orderBy ? null : (
                                <FormattedMessage id="ORDER_ON_DATE" values={{ orderDate: parseISO(orderDate), createdClientName: createdClientName || null }} />
                            )
                        }
                        {
                            !orderDate || !orderBy ? null : (
                                <FormattedMessage id="ORDER_ON_DATE_BY_NAME" values={{ orderBy, orderDate: parseISO(orderDate), createdClientName: createdClientName || null }} />
                            )
                        }
                    </ItemDetail>
                </Grid>
                <Grid container item alignContent="flex-start" className={classes.block}>
                    <PackageContent content={packageContent} />
                </Grid>
                <Grid container item alignContent="flex-start" className={classes.block}>
                    <Documents
                        relationType="order_item"
                        relationId={orderId}
                        managementPermission={false}
                        listProps={{ sx: { width: '100%' } }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

function PackageContent({ content = [] }) {

    if (!content || isEmpty(content)) {
        return null;
    }

    return (
        <>
            <Typography variant="body1" gutterBottom>
                <FormattedMessage id="CONTENT" />
            </Typography>
            <Grid container spacing={1}>
                {
                    content.map(({ description, status }, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={8}>
                                {description}
                            </Grid>
                            <Grid item xs={4}>
                                {status}
                            </Grid>
                        </React.Fragment>
                    ))
                }
            </Grid>
        </>
    );
}

Details.propTypes = propTypes;
Details.defaultProps = defaultProps;

export default Details;
