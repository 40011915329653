import React from 'react';
import { IntlProvider } from 'react-intl';
import { getLocalizedMessages } from 'utils/ofLocalization';
import { SnackbarProvider } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import fr from 'date-fns/locale/fr';
import { useIdleTimer } from 'react-idle-timer';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Theme2 from 'utils/Theme2';
import { useLocale } from 'contexts/locale';
import { useUser } from 'contexts/user';
import labels from 'locales/labels';
import messages from 'locales/messages';

function Main(props) {

    const { language: currentLanguage, locale: currentLocale } = useLocale();
    const { user: currentUser, auth, clearUser } = useUser();
    const [osTheme, setOsTheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light' );

    const intlFormats = {
        date: {
            short: {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            },
        },
    };
    const defaultTheme = 'light';
    const storedTheme = localStorage.getItem('theme');
    const userTheme = (currentUser.theme || storedTheme || defaultTheme).toLowerCase();
    let darkThemeEnabled = userTheme === 'dark';

    if (userTheme === 'auto') {
        darkThemeEnabled = osTheme === 'dark';
    }

    const materialTheme = Theme2({ darkMode: darkThemeEnabled, lang: currentLanguage });

    const handleOnIdle = () => {
        if (auth.isAuthenticated()) {
            clearUser({ valid: false, text: 'INACTIVITY_LOGOUT' });
        }
    };

    React.useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
            setOsTheme(e.matches ? 'dark' : 'light');
        });
    }, []);

    useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: handleOnIdle,
    });

    return (
        <IntlProvider
            formats={intlFormats}
            locale={currentLocale}
            key={currentLocale}
            messages={getLocalizedMessages(currentLanguage, [labels, messages])}
        >
            <StyledEngineProvider injectFirst>
                <ThemeProvider
                    theme={materialTheme}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLanguage === 'fr' ? fr : undefined}>
                        <CssBaseline />
                        <SnackbarProvider
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            maxSnack={1}
                            iconVariant={{
                                loading: <CircularProgress size={20} style={{ marginRight: materialTheme.spacing(1) }} />,
                            }}
                        >
                            <div id="app">
                                {props.children}
                            </div>
                            <div id="react-error-render" />
                            <div id="modals" />
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </IntlProvider>
    );
}

export default Main;
