import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import flatten from 'lodash/flatten';
import extend from 'lodash/extend';
import each from 'lodash/each';
import toPairs from 'lodash/toPairs';

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from 'utils/constants';


function languageFromLocale(locale) {
    let lang = locale.split('-')[0].toLowerCase();
    if (!includes(SUPPORTED_LANGUAGES, lang)) {
        lang = DEFAULT_LANGUAGE;
    }
    return lang;
}

function parseLocalizedMessages(datas) {
    return reduce(flatten(datas), (messages, data) => {
        if ('en' in data) {
            SUPPORTED_LANGUAGES.forEach((lang) => {
                const langDeclarations = data[lang];
                if (langDeclarations) {
                    messages[lang] = extend(messages[lang], langDeclarations);
                }
            });
        } else {
            each(toPairs(data), (i) => {
                SUPPORTED_LANGUAGES.forEach((lang) => {
                    const langDeclarations = i[1][lang];
                    if (langDeclarations) {
                        messages[lang][i[0]] = langDeclarations;
                    }
                });
            });
        }

        return messages;
    }, { en: {}, fr: {} });
}

function getLocalizedMessages(locale, datas) {
    return parseLocalizedMessages(datas)[languageFromLocale(locale)];
}

export {
    getLocalizedMessages,
    languageFromLocale,
};