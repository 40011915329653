import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

import md5 from 'uuid/lib/md5-browser';

export function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
    }

    let colour = '';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff; // eslint-disable-line no-bitwise
        colour += `00${value.toString(16)}`.substr(-2);
    }

    if (parseInt(colour, 16) > 15658734) {
        colour = 'eeeeee';
    }
    return `#${colour}`;
}

function rstr2hex(input) {
    const hexTab = '0123456789abcdef';
    let output = '';
    let x;
    let i;
    for (i = 0; i < input.length; i += 1) {
        x = input[i];
        output += hexTab.charAt((x >>> 4) & 0x0f) + hexTab.charAt(x & 0x0f);
    }
    return output;
  }

function UserAvatar(props) {
    const {
        style, className, username = '', avatar = undefined,
        sx, email,
    } = props;

    const [error, setError] = React.useState(false);
    const color = stringToColor(username);
    const theme = useTheme();

    let initials = username;
    let gavatar = null;

    if (username.length >= 1) {
        initials = `${username.split(' ')[0][0]}${username.split(' ')[1][0]}`;
    }

    if (!avatar && !error && email) {
        const hash = rstr2hex(md5(email.trim().toLowerCase()));
        gavatar = `https://www.gravatar.com/avatar/${hash}?d=404`
    }

    return (
        <Avatar
            className={className}
            style={{
                ...style,
                backgroundColor: color,
                color: theme.palette.getContrastText(color),
            }}
            sx={sx}
            src={avatar || gavatar}
            alt={username}
            onError={() => setError(true)}
        >
            { (avatar || gavatar) ? false : initials }
        </Avatar>
    );
}

UserAvatar.propTypes = {
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    style: PropTypes.shape({}),
    className: PropTypes.string,
};

UserAvatar.defaultProps = {
    style: {},
    className: '',
    avatar: undefined,
}

export default UserAvatar;
