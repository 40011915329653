import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';

import { useNotification } from 'contexts/notification';
import { useSocketListener } from 'contexts/socket';
import { useUser } from 'contexts/user';
import { queryStringFromObj, queryStringToObj } from 'utils/ofQuery';

function CommentNotification() {
    const intl = useIntl();
    const history = useHistory();
    const { api, user } = useUser();
    const { pushNotification, notificationsEnabled } = useNotification();
    const notifsEnabled = user.byPopUp == 'REAL_TIME';

    if (notifsEnabled) {
        console.debug('notifs enabled');
    } else {
        console.debug('notifs disabled');
    }

    const showComment = React.useCallback((event, comment) => {
        if (comment.discussionId) {
            const { location } = history;
            const search = queryStringToObj(location.search);
            search.discussion = comment.discussionId;
            const newLocation = {
                pathname: location.pathname,
                search: queryStringFromObj(search),
            };
            history.push(newLocation);
        }
    }, [history]);


    const handleEvent = React.useCallback((event) => {
        if (!notifsEnabled) {
            return;
        }
        const data = JSON.parse(event.data);
        const { location } = history;
        const search = queryStringToObj(location.search);

        if (data.id && data.id !== search.discussion) {
            const title = `${intl.formatMessage({ id: 'NEW_MESSAGE' })} • ${data.by}`;
            const comment = { discussionId: data.id };
            const actions = [{ action: 'default', title: intl.formatMessage({ id: 'VIEW' }), callback: showComment }];
            pushNotification(data.text, MessageIcon, title, actions, comment);
        }
    }, [notificationsEnabled, showComment, notifsEnabled ]);

    useSocketListener('discussion', handleEvent);

    return null;
}

export default CommentNotification;
