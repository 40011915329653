import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import range from 'lodash/range';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';

import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import NotFound from 'components/NotFound';
import isEmpty from 'lodash/isEmpty';

import EnhancedTableRow from './EnhancedTableRow';
import EnhancedTableHeader from './EnhancedTableHeader';
import EnhancedTableCell from './EnhancedTableCell';

const useStyles = makeStyles((theme) => createStyles({
    additionalActions: {
        padding: theme.spacing(1, 0, 2, 1),
        '&:last-child': {
            padding: theme.spacing(1, 0, 2, 1),
        },
    },
    toolbar: {
        minHeight: theme.spacing(2),
    },
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: alpha(theme.palette.background.paper, 0.67),
    },
    pointer: {
        cursor: 'pointer',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
}));

const propTypes = {
    page: PropTypes.number.isRequired,
    pageRows: PropTypes.arrayOf(PropTypes.shape({})),
    totalRowCount: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        align: PropTypes.oneOf(['right', 'left', 'center']),
        Component: PropTypes.func,
    })).isRequired,
    showDivisions: PropTypes.bool,
    showHover: PropTypes.bool,
    stickyHeader: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    additionalActions: PropTypes.node,
    pagenate: PropTypes.bool,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    onRowClick: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    isLoading: PropTypes.bool,
    onRowSelect: PropTypes.func,
    onSelectAll: PropTypes.func,

    isSortable: PropTypes.bool,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    handleRequestSort: PropTypes.func,

    uniqueField: PropTypes.string.isRequired,
    selectable: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    disabledRow: PropTypes.func,
    disabledRowMessage: PropTypes.string,
    disabledSelect: PropTypes.func,
    disabledSelectMessage: PropTypes.string,
    emptyMessage: PropTypes.string,
};

const defaultProps = {
    pageRows: [],
    totalRowCount: null,
    showDivisions: true,
    showHover: true,
    stickyHeader: true,
    size: 'medium',
    additionalActions: null,
    pagenate: true,
    rowsPerPageOptions: [50, 100, 200],
    rowsPerPage: 50,
    onRowClick: null,
    onChangePage: null,
    onChangeRowsPerPage: null,
    isLoading: false,

    isSortable: false,
    order: 'asc',
    orderBy: '',
    handleRequestSort: null,

    onRowSelect: null,
    onSelectAll: null,
    selectable: [],
    selected: [],
    disabledRow: null,
    disabledRowMessage: '',
    disabledSelect: null,
    disabledSelectMessage: '',
    emptyMessage: '',
};

function EnhancedTableCommon(props) {

    const {
        columns,
        page, pageRows, totalRowCount,
        stickyHeader, size,
        isSortable, order, orderBy, handleRequestSort,
        additionalActions, rowsPerPageOptions, rowsPerPage,
        pagenate, onChangePage, onChangeRowsPerPage, onRowClick, isLoading,
        selectable, selected, uniqueField, onRowSelect, onSelectAll, disabledRow,
        disabledRowMessage, disabledSelect, disabledSelectMessage,
        showDivisions, showHover, emptyMessage, multiple,
    } = props;

    const classes = useStyles();
    const scrollerRef = React.useRef();

    const handleChangePage = React.useCallback((event, newPage) => {
        if (scrollerRef.current && scrollerRef.current.scrollTo) {
            scrollerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (onChangePage) {
            onChangePage(newPage);
        }
    }, [onChangePage]);

    const handleChangeRowsPerPage = React.useCallback(({ target: { value } }) => {
        if (onChangeRowsPerPage) {
            onChangeRowsPerPage(value);
        }
    }, [onChangeRowsPerPage]);

    const handleSelectClick = React.useCallback(({ target: { value } }) => {
        if (onRowSelect) {
            const isString = pageRows[0][uniqueField] instanceof String;
            onRowSelect(isString ? value : parseInt(value, 10));
        }
    }, [onRowSelect, uniqueField, pageRows[0]]);

    const handleRowClick = React.useCallback((row) => {
        if (!isLoading && onRowClick) {
            onRowClick(row);
        }
    }, [isLoading, onRowClick]);

    React.useEffect(() => {
        if (rowsPerPage * page > totalRowCount) {
            handleChangePage(null, 0);
        }
    }, [rowsPerPage, page, totalRowCount]);

    const showEmptyStatus = emptyMessage && !isLoading && totalRowCount === 0;

    let pageRowsOrFiller = pageRows;
    let columnsOrFiller = columns;
    const fullColspan = columns.length + (onRowSelect ? 1 : 0);

    if (showEmptyStatus) {
        pageRowsOrFiller = [{
            empty: (
                <Typography variant="h6">
                    <FormattedMessage id={emptyMessage} tagName="em" />
                </Typography>
            ),
            uniqueField: -1,
        }];
        columnsOrFiller = [{
            field: 'empty',
            align: 'center',
            colspan: fullColspan,
        }];
    } else if (isLoading && isEmpty(pageRows)) {
        pageRowsOrFiller = range(pagenate ? rowsPerPage : 3).map((row) => ({ [uniqueField]: row }));
    }

    const mainStyle = { maxHeight: '100%', overflow: isLoading || showEmptyStatus ? 'hidden' : 'auto', position: 'relative', padding: '0 8px' };

    if (pagenate) {
        mainStyle.height = '100%';
    }

    const renderRow = React.useCallback((row, rowIndex) => (
        <Row
            key={row[uniqueField] || rowIndex}
            row={row}
            rowIndex={rowIndex}
            disabledRow={disabledRow}
            disabledSelect={disabledSelect}
            disabledSelectMessage={disabledSelectMessage}
            handleSelectClick={handleSelectClick}
            pageRowsOrFillerLength={pageRowsOrFiller.length}
            showDivisions={showDivisions}
            uniqueField={uniqueField}
            showHover={showHover}
            disabledRowMessage={disabledRowMessage}
            onRowClick={onRowClick}
            isLoading={isLoading}
            handleRowClick={handleRowClick}
            selected={selected}
            onRowSelect={onRowSelect}
            showEmptyStatus={showEmptyStatus}
            columnsOrFiller={columnsOrFiller}
            classes={classes}
        />
    ), [disabledRow, disabledSelect, disabledSelectMessage, handleSelectClick, pageRowsOrFiller.length, showDivisions, uniqueField, showHover, disabledRowMessage, onRowClick, isLoading, handleRowClick, selected, onRowSelect, showEmptyStatus, columnsOrFiller]);


    return (
        <>
            <div style={mainStyle} ref={scrollerRef}>
                <Table size={size} stickyHeader={stickyHeader}>
                    <EnhancedTableHeader
                        columns={columns}
                        isSortable={isSortable}
                        order={order}
                        multiple={multiple}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        isSelectable={!!(onRowSelect)}
                        // isSelectable={!!(onRowSelect) && multiple}
                        numSelectable={selectable.length}
                        numSelected={selected.length}
                        rowCount={totalRowCount}
                        onSelectAllClick={onSelectAll}
                        showDivisions={showDivisions}
                    />
                    <TableBody>
                        {
                            pageRowsOrFiller.map(renderRow)
                        }
                    </TableBody>
                </Table>
            </div>
            <Grid component={Toolbar} container alignItems="center" alignContent="center" className={classes.toolbar}>
                <Grid item xs>
                    { additionalActions }
                </Grid>
                {
                    !pagenate ? false : (
                        <TablePagination
                            labelDisplayedRows={({ to, count, from }) => <FormattedMessage id="DISPLAYED_ROWS" values={{ from, count, to: (to === -1 ? count : to) }} />}
                            labelRowsPerPage={<FormattedMessage id="ROWS_PER_PAGE" />}
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={totalRowCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )
                }
            </Grid>
        </>
    );
}

const rowStyles = makeStyles((theme) => createStyles({
    pointer: {
        cursor: 'pointer',
    },
    noPadding: {
        padding: 0,
    },
}));

function Row(props) {
    const {
        row, rowIndex, disabledRow, disabledSelect, disabledSelectMessage,
        handleSelectClick, pageRowsOrFillerLength, showDivisions, uniqueField,
        showHover, disabledRowMessage, onRowClick, isLoading, handleRowClick,
        selected, onRowSelect, showEmptyStatus, columnsOrFiller,
    } = props;
    const classes = rowStyles();
    const isRowDisabled = disabledRow ? disabledRow(row) : false;
    const isSelectDisabled = disabledSelect ? disabledSelect(row) : false;
    const Wrapper = isSelectDisabled && disabledSelectMessage ? Tooltip : React.Fragment;

    const borders = rowIndex + 1 === pageRowsOrFillerLength ? false : showDivisions;

    const renderData = React.useCallback((columnDefinition, index) => {
        const {
            field, align, Component, colspan,
        } = columnDefinition;

        let toRender = Component ? Component(row, index) : row[field];
        if (isLoading) {
            toRender = <Skeleton />;
        }

        return (
            <EnhancedTableCell
                key={`${row[uniqueField] || rowIndex}-${columnDefinition.field}`}
                align={align}
                borders={borders}
                component={index === 0 ? 'th' : ''}
                scope={index === 0 ? 'row' : ''}
                disabled={isRowDisabled}
                colspan={colspan}
            >
                {toRender}
            </EnhancedTableCell>
        );
    }, [row, borders, isRowDisabled, isLoading]);

    return (
        <EnhancedTableRow
            rowData={row}
            hover={!showHover ? showHover : !isRowDisabled}
            disabled={isRowDisabled}
            disabledMessage={disabledRowMessage}
            className={onRowClick && !isLoading ? classes.pointer : undefined}
            onClick={handleRowClick}
            selected={
                onRowSelect ? selected.indexOf(row[uniqueField]) !== -1 : undefined
            }
        >
            {
                !onRowSelect || showEmptyStatus ? null : (
                    <EnhancedTableCell align="center" padding="checkbox" borders={borders}>
                        <Wrapper
                            {...(!isSelectDisabled || !disabledSelectMessage ? {} :
                                { title: <FormattedMessage id={disabledSelectMessage} /> })}
                        >
                            <span>
                                <Checkbox
                                    className={!showDivisions ? classes.noPadding : undefined }
                                    checked={selected.includes(row[uniqueField])}
                                    value={row[uniqueField]}
                                    onChange={handleSelectClick}
                                    disabled={isLoading || isRowDisabled || isSelectDisabled}
                                />
                            </span>
                        </Wrapper>
                    </EnhancedTableCell>
                )
            }
            {
                columnsOrFiller.map(renderData)
            }
        </EnhancedTableRow>
    );
}

EnhancedTableCommon.propTypes = propTypes;
EnhancedTableCommon.defaultProps = defaultProps;

export default EnhancedTableCommon;
// export { Styles, EnhancedTableCommon };
