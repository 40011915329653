import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import EnhancedTableCommon from './EnhancedTableCommon';

function EnhancedPagedTable(props) {
    const {
        fetchPage, isLoading, setPage,
        page, disabledSelect, isSelectable, uniqueField,
        defaultOrderBy, defaultOrder, fetchPageDependencies, setSelected,
        selected, pageRows, columns, showDivisions,
        showHover, stickyHeader, size, additionalActions,
        pagenate, rowsPerPageOptions, onRowClick, disabledRow,
        disabledRowMessage, disabledSelectMessage, emptyMessage, totalRowCount,
        isSortable, multiple,
    } = props;

    const selectable = React.useMemo(() => (
        pageRows.filter((o) => (disabledSelect ? !disabledSelect(o) : true)).map((o) => (o[uniqueField]))
    ), [pageRows, disabledSelect, uniqueField]);

    const [allChecked, selectedOnPage] = React.useMemo(() => ([
        selectable.reduce((acc, id) => (acc && selected.includes(id)), true),
        selectable.filter((id) => (selected.includes(id))),
    ]), [selected, selectable]);

    const [prevOrderBy, setPrevOrderBy] = React.useState('');

    let {
        order, setOrder, orderBy,
        setOrderBy, rowsPerPage, setRowsPerPage,
    } = props;

    if (order === undefined || setOrder === undefined) {
        [order, setOrder] = React.useState(defaultOrder);
    }

    if (orderBy === undefined || setOrderBy === undefined) {
        [orderBy, setOrderBy] = React.useState(defaultOrderBy);
    }

    if (rowsPerPage === undefined || setRowsPerPage === undefined) {
        [rowsPerPage, setRowsPerPage] = React.useState(50);
    }

    const setSorting = React.useCallback((newOrderBy, newOrder) => {
        setOrderBy(newOrderBy);
        setOrder(newOrder);
    }, []);

    const handleRequestSort = React.useCallback((event, property) => {
        if (property !== orderBy) {
            setPrevOrderBy(orderBy);
            setSorting(property, 'asc');
        } else if (order === 'asc') {
            setSorting(orderBy, 'desc');
        } else {
            setSorting(prevOrderBy, 'asc');
        }
    }, [order, orderBy, prevOrderBy]);

    const handleRowSelect = React.useCallback((objectId) => {
        if (selectable.includes(objectId)) {
            setSelected((currentSelected) => {
                let selLst = [];
                if (!multiple) {
                    selLst = currentSelected[0] === objectId ? [] : [objectId]
                } else if (Array.isArray(objectId)) {
                    selLst = objectId; // Weird!!! Does this ever happen?
                } else if (currentSelected.includes(objectId)) {
                    selLst = currentSelected.filter((id) => id !== objectId);
                } else {
                    selLst = [...currentSelected, objectId];
                }

                return selLst;
            });
        }
    }, [selectable, multiple]);

    const handleSelectAll = React.useCallback(() => {
        if (allChecked) {
            const remaining = selectedOnPage.filter((id) => (!selectable.includes(id)));

            setSelected(remaining); // check if this makes sense
        } else {
            setSelected((currentSelected) => {
                const added = selectable.filter((id) => (!currentSelected.includes(id)));
                return [...currentSelected, ...added];
            });
        }
    }, [allChecked, selectedOnPage, selectable]);

    React.useEffect(() => {
        setPage(0);
        if (rowsPerPage) {
            const abortController = new AbortController();

            fetchPage(true, 0, rowsPerPage, orderBy, order, abortController);

            return function cleanup() {
                if (isLoading) {
                    abortController.abort();
                }
            };
        }
        return undefined;
    }, [...fetchPageDependencies, orderBy, order, rowsPerPage]);

    React.useEffect(() => {
        if (rowsPerPage && isEmpty(pageRows)) {
            const abortController = new AbortController();

            fetchPage(false, page, rowsPerPage, orderBy, order, abortController);

            return () => {
                if (isLoading) {
                    abortController.abort();
                }
            };
        }
        return undefined;
    }, [page]);

    return (
        <EnhancedTableCommon
            fetchPageDependencies={fetchPageDependencies}
            fetchPage={fetchPage}
            isLoading={isLoading}
            setPage={setPage}
            page={page}
            disabledSelect={disabledSelect}
            totalRowCount={totalRowCount}
            uniqueField={uniqueField}
            setSelected={setSelected}
            defaultOrderBy={defaultOrderBy}
            defaultOrder={defaultOrder}
            isSortable={isSortable}
            isSelectable={isSelectable}
            multiple={multiple}

            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={setRowsPerPage}
            orderBy={orderBy}
            order={order}
            setSorting={setSorting}
            onChangePage={setPage}
            selectable={selectable}
            selected={selectedOnPage}
            onRowSelect={isSelectable ? handleRowSelect : null}
            onSelectAll={handleSelectAll}
            pageRows={pageRows}
            handleRequestSort={handleRequestSort}

            columns={columns}
            showDivisions={showDivisions}
            showHover={showHover}
            stickyHeader={stickyHeader}
            size={size}
            additionalActions={additionalActions}
            pagenate={pagenate}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowClick={onRowClick}
            disabledRow={disabledRow}
            disabledRowMessage={disabledRowMessage}
            disabledSelectMessage={disabledSelectMessage}
            emptyMessage={emptyMessage}
        />
    );
}

EnhancedPagedTable.propTypes = {
    fetchPageDependencies: PropTypes.array,
    fetchPage: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    disabledSelect: PropTypes.func,
    totalRowCount: PropTypes.number,
    uniqueField: PropTypes.string.isRequired,
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    setSelected: PropTypes.func,
    defaultOrderBy: PropTypes.string,
    defaultOrder: PropTypes.string,
    pageRows: PropTypes.arrayOf(PropTypes.object),
    isSortable: PropTypes.bool,
    isSelectable: PropTypes.bool,
    multiple: PropTypes.bool,
};

EnhancedPagedTable.defaultProps = {
    fetchPageDependencies: [],
    totalRowCount: undefined,
    selected: [],
    setSelected: null,
    disabledSelect: undefined,
    defaultOrderBy: '',
    defaultOrder: 'asc',
    pageRows: [],
    isSortable: false,
    isSelectable: false,
    multiple: true,
};


export default EnhancedPagedTable;
