import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.node,
    dense: PropTypes.bool,
    disableGutters: PropTypes.bool,
};

const defaultProps = {
    component: 'li',
    dense: false,
    disableGutters: false,
};

const useStyles = makeStyles(() => createStyles({
    root: {
        backgroundColor: 'transparent',
        '&:hover, &:active': {
            backgroundColor: 'transparent',
        },
    },
}));

function InputMenuItem(props) {
    const {
        component, dense, disableGutters,
        children,
    } = props;

    const classes = useStyles();

    const handleKeyDown = React.useCallback((e) => e.stopPropagation(), []);

    return (
        <MenuItem
            className={classes.root}
            component={component}
            dense={dense}
            disableGutters={disableGutters}
            onKeyDown={handleKeyDown}
        >
            {children}
        </MenuItem>
    );
}

InputMenuItem.propTypes = propTypes;
InputMenuItem.defaultProps = defaultProps;

export default InputMenuItem;
