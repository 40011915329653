import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import { useUser } from 'contexts/user';

const propTypes = {
};

const CallBackRoute = () => {
    const { auth } = useUser();
    const location = useLocation();

    if (/access_token|id_token|error/.test(location.hash)) {
        auth.handleAuthentication();
    }

    return <Loading intlText="SIGNING_IN" />;
};

CallBackRoute.propTypes = propTypes;

export default CallBackRoute;
