import each from 'lodash/each';


function camelToSnakeCase(key) {
    return key.replace(/([A-Za-z])([0-9])/g, (snip, char, digit) => `${char}_${digit}`).replace(/([A-Z])/g, (snip) => `_${snip.toLowerCase()}`);
}


function formatPostData(data, files) {
    const postData = new FormData();
    const stringedData = JSON.stringify(data);
    postData.append('data', stringedData.replace(/"([^"]*)"\s*:/g, camelToSnakeCase));

    if (files instanceof FileList || files instanceof Array) {
        each(files, (file, index) => {
            postData.append(`file_${index}`, file);
        });
    } else if (files instanceof Object) {
        Object.entries(files).forEach(([key, file]) => {
            postData.append(camelToSnakeCase(key), file);
        });
    }
    return postData;
}

export { formatPostData, camelToSnakeCase };
