import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';

import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';

import Discussion from 'components/Discussion/Discussion';
import DiscussionList from 'components/Discussion/List';

const DRAWER_WIDTH = 320;

const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2),

    },
    currentDiscussion: {
        backgroundColor: theme.palette.action.selected,
        borderRadius: theme.shape.borderRadius,
    },
    discussionList: {
    },
    drawer: {
        // position: 'relative',
        [theme.breakpoints.up('md')]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
        },
    },
    menuButton: {
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        border: 'none',
        height: '100%',
        width: DRAWER_WIDTH,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
}));

const propTypes = {
    relationId: PropTypes.number,
    relation: PropTypes.string,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    includeRelationDetails: PropTypes.bool,
};

const defaultProps = {
    relationId: null,
    relation: null,
    className: null,
    isLoading: false,
    includeRelationDetails: false,
};

function DiscussionView(props) {
    const classes = useStyles();
    const {
        relationId, relation,
        className, isLoading: parentLoading,defaultEnableCreation=false,
        includeRelationDetails, discussionId: controlledId, setDiscussionId,
    } = props;

    const [activeId, setActiveId] = React.useState();
    const [createDiscussionEnabled, setCreateDiscussion] = React.useState(defaultEnableCreation);
    const [refreshList, setRefreshList] = React.useState(false);

    const discussionId = setDiscussionId ? controlledId : activeId;

    function handleSetDiscussionId(id) {
        if (setDiscussionId) {
            setDiscussionId(id);
        } else {
            setActiveId(id);
        }
        setMobileOpen(false);
    }

    function disableCreateDiscussion() {
        setCreateDiscussion(false);
    }

    function enableCreateDiscussion() {
        setCreateDiscussion(true);
    }

    function forceRefreshList() {
        setRefreshList(true);
    }

    function cancelRefreshList() {
        setRefreshList(false);
    }

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = React.useCallback(() => {
        setMobileOpen((currentValue) => !currentValue);
    }, []);


    return (
        <Grid container className={[classes.root, className].join(' ')}>
            <Drawer
                classes={{
                    root: classes.drawer,
                    paper: classes.drawerPaper,
                }}
                variant="persistent"
                elevation={0}
                open={!isMobile || mobileOpen}
                onClose={handleDrawerToggle}
            >
                <DiscussionList
                    handleDrawerToggle={handleDrawerToggle}
                    enableCreateDiscussion={enableCreateDiscussion}
                    createDiscussionEnabled={createDiscussionEnabled}
                    relationId={relationId}
                    relation={relation}
                    setActive={handleSetDiscussionId}
                    activeId={discussionId}
                    isLoading={parentLoading}
                    cancelRefreshList={cancelRefreshList}
                    refreshList={refreshList}
                    includeRelationDetails={includeRelationDetails}
                />
           </Drawer>
            <Grid item xs container className={classes.currentDiscussion}>
                <Discussion
                    handleDrawerToggle={handleDrawerToggle}
                    discussionId={discussionId}
                    isLoading={parentLoading}
                    createDiscussionEnabled={createDiscussionEnabled}
                    relationId={relationId}
                    relation={relation}
                    forceRefreshList={forceRefreshList}
                    disableCreateDiscussion={disableCreateDiscussion}
                    setActive={handleSetDiscussionId}
                />
            </Grid>
        </Grid>
    );
}

DiscussionView.propTypes = propTypes;
DiscussionView.defaultProps = defaultProps;

export default DiscussionView;
