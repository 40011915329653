import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Redirect, useLocation } from 'react-router-dom';

import AppPanel from 'components/AppPanel';
import { useUser } from 'contexts/user';

const propTypes = {
    children: PropTypes.node.isRequired,
};

const defaultProps = {
};

function LoginCheck(props) {
    const { children } = props;
    const { auth, user } = useUser();
    const location = useLocation();

    if (auth.isAuthenticated() && !isEmpty(user)) {
        return (
            <AppPanel>
                {children}
            </AppPanel>
        );
    }

    return (
        <Redirect
            to={{
                pathname: '/login',
                state: {
                    nextPathname: location.pathname,
                    nextSearch: location.search,
                },
            }}
        />
    );
}

LoginCheck.propTypes = propTypes;
LoginCheck.defaultProps = defaultProps;

export default LoginCheck;
