import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import random from 'lodash/random';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import { useParams, generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaptionTwoTone';
import SubtitlesIcon from '@mui/icons-material/SubtitlesTwoTone';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import ImageIcon from '@mui/icons-material/ImageTwoTone';
import GraphicEqIcon from '@mui/icons-material/GraphicEqTwoTone';
import DescriptionIcon from '@mui/icons-material/DescriptionTwoTone';
import AllInboxIcon from '@mui/icons-material/AllInboxTwoTone';
import TheatersIcon from '@mui/icons-material/TheatersTwoTone';
import PanoramaIcon from '@mui/icons-material/PanoramaTwoTone';
import CloudCircleIcon from '@mui/icons-material/CloudCircleTwoTone';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import { useUser } from 'contexts/user';
import NotFound from 'components/NotFound';
import RouteLink from 'components/RouteLink';
import NotInterestedIcon from '@mui/icons-material/NotInterestedTwoTone';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadButton from 'components/DownloadButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

import { DistributionEntityDefinition, DistributionEntityDefinitionAsset } from 'routes/Paths/Definitions';

function capitalizeFirstLetter(str, locale = navigator.language) {
    if (isEmpty(str)) {
        return str;
    }
    const [ first, ...rest ] = str;
    return first.toLocaleUpperCase(locale) + rest.map((c) => c.toLocaleLowerCase(locale)).join('');
}

const useStyles = makeStyles((theme) => createStyles({
    List: {
        display: 'inline-flex',
    },
    Indented: {
        marginLeft: theme.spacing(2),
    },
    AssetIcon: {
        opacity: 1,
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        '.Mui-checked ~ &, .MuiAvatar-root:hover > &, .MuiListItem-root:hover &': {
            opacity: 0,
        },
    },
    InputCheck: {
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}, color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        padding: '0rem',
        margin: '0rem',
        position: 'absolute',
        left: 'auto',
        right: 'auto',
        opacity: 0,
        '&.Mui-checked': {
            opacity: 1,
            pointerEvents: 'auto',

        },
        '.MuiAvatar-root:hover &, .MuiListItem-root:hover &': {
            opacity: 1,
            pointerEvents: 'auto',
            color: theme.palette.primary.main,
        },
    },
    activeLink: {
        color: theme.palette.primary.main,
    },
    inVaultIcon: {
        color: theme.palette.text.primary,
    },
    activeAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
}));

const IconForType = ({ type, className }) => ({
    [type]: <InsertDriveFileIcon className={className} />,
    CC: <ClosedCaptionIcon className={className} />,
    VIDEO: <TheatersIcon className={className} />,
    AUDIO: <GraphicEqIcon className={className} />,
    PACKAGE: <AllInboxIcon className={className} />,
    SUBTITLE: <SubtitlesIcon className={className} />,
    DOCUMENT: <DescriptionIcon className={className} />,
    PICTURE: <PanoramaIcon className={className} />,
    POSTER: <ImageIcon className={className} />,
})[type];

const inVaultStatuses = ['ARCHIVED', 'PENDING_QC', 'PENDING_CLIENT', 'APPROVED'];
const vaultStatuses = [...inVaultStatuses, 'REMOVED'];
const assetTypes = ['CC', 'VIDEO', 'AUDIO', 'PACKAGE', 'SUBTITLE', 'DOCUMENT', 'PICTURE', 'POSTER'];

const tempAssets = range(3).map((i) => ({
    qcId: [0, 1][random(0, 1)],
    vaultStatusId: vaultStatuses[random(0, vaultStatuses.length - 1)],
    recu: [0, 1][random(0, 1)],
    id: `skel-${i}`,
    assetTypeId: assetTypes[random(0, assetTypes.length - 1)],
}));

function Assets(props) {
    const classes = useStyles();
    const { api, user } = useUser();
    const { client, id } = useParams();
    const [assets, setAssets] = React.useState(tempAssets);
    const location = useLocation();
    const [fetchingAssets, setFetchingAssets] = React.useState(false);
    const {
        handleAssetSelection, activeAssetId, selectedAssets, path, showInVaultOnly,
    } = props;

    const userPermission = user.clients.find((c) => c.client === parseInt(client, 10)) || {};
    const managementPermission = userPermission.distribution === 2;

    async function fetchAssets(abortController = new AbortController()) {
        if (client && id) {
            try {
                setFetchingAssets(true);
                const response = await api.get(`${client}/entity/${id}/assets`, { }, { signal: abortController.signal, artificialDelay: 100 });
                if (response.ok) {
                    const data = await response.json();
                    setAssets(data);
                } else {
                    setAssets([]);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setFetchingAssets(false);
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchAssets(abortController);
        return () => {
            abortController.abort();
        };
    }, [client, id]);

    function handleSelectAsset({ target: { value } }) {
        if (handleAssetSelection) {
            handleAssetSelection([parseInt(value, 10)]);
        }
    }

    const defaultPath = `/distribution/${client}/entities/${id}/asset/`;


    const displayedAssets = fetchingAssets ? tempAssets : assets;
    return (
        <>
            <List disablePadding>
                {
                    displayedAssets.map((asset) => (
                        <Asset
                            key={`assetkey-${asset.id}`}
                            skeleton={displayedAssets === tempAssets}
                            id={asset.id}
                            qcId={asset.qcId}
                            client={client}
                            vaultStatusId={asset.vaultStatusId}
                            recu={asset.recu}
                            managementPermission={managementPermission}
                            classes={classes}
                            handleSelectAsset={handleSelectAsset}
                            assetTypeId={asset.assetTypeId}
                            assetDescription={asset.assetDescription}
                            isSelected={selectedAssets.includes(asset.id)}
                            qcSheets={asset.qcSheets}
                            // assetPath={`${defaultPath}${asset.id}/?view=details`}
                            assetPath={generatePath(DistributionEntityDefinitionAsset.path, {
                                client,
                                id,
                                assetId:asset.id,
                            })}
                            isActive={activeAssetId == asset.id}
                            inVault={inVaultStatuses.includes(asset.vaultStatusId)}
                            showInVaultOnly={showInVaultOnly}
                            hasParentDcp={asset.hasParentDcp}
                        />
                    ))
                }
            </List>
        </>
    );
}

function Asset(props) {
    const {
        id, qcId, vaultStatusId, recu, managementPermission,
        classes, handleSelectAsset, assetTypeId,
        assetDescription, isSelected, qcPath, assetPath, isActive,
        skeleton, inVault, showInVaultOnly, isHidden, qcSheets,
    } = props;

    if (!skeleton && ((showInVaultOnly && !inVault) || isHidden)) {
        return null;
    }
    const isAvailableNotInVault = recu && !inVault;

    const BadgeIcon = inVault ? CloudCircleIcon : NotInterestedIcon;
    return (
        <ListItem disableGutters alignItems={qcId ? 'flex-start' : undefined} className={classes.List}>
            <ListItemAvatar>
                {
                    isAvailableNotInVault ? null : (
                        <Badge
                            badgeContent={(
                                <Tooltip title={<FormattedMessage id={inVault ? 'IN_VAULT' : 'UNAVAILABLE'} />}>
                                    <BadgeIcon className={classes.inVaultIcon} />
                                </Tooltip>
                            )}
                            invisible={(!inVault && recu === 1) || skeleton}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Avatar>
                                {
                                    !managementPermission ? null : (
                                        <Checkbox
                                            className={classes.InputCheck}
                                            checked={isSelected}
                                            name={`asset-${id}`}
                                            onChange={handleSelectAsset}
                                            value={id}
                                            disabled={skeleton}
                                        />
                                    )
                                }
                                {skeleton ? null : <IconForType type={assetTypeId} className={classes.AssetIcon} />}
                            </Avatar>
                        </Badge>
                    )
                }
                {
                    !isAvailableNotInVault ? null : (
                        <Avatar>
                            {
                                !managementPermission ? null : (
                                    <Checkbox
                                        className={classes.InputCheck}
                                        checked={isSelected}
                                        name={`asset-${id}`}
                                        onChange={handleSelectAsset}
                                        value={id}
                                        disabled={skeleton}
                                    />
                                )
                            }
                            {skeleton ? null : <IconForType type={assetTypeId} className={classes.AssetIcon} />}
                        </Avatar>
                    )
                }
            </ListItemAvatar>
            <ListItemText
                primary={skeleton ? <Skeleton width={['80%', '40%', '35%', '64%', '70%'][random(0, 4)]} /> : (
                    <RouteLink
                        underline="none"
                        className={isActive ? classes.activeLink : null}
                        to={assetPath}
                        color="textPrimary"
                    >
                        {assetDescription}
                    </RouteLink>
                )}
                secondary={
                    skeleton ? <Skeleton width="4rem" /> : <QCSheets sheets={qcSheets} assetId={id} />
                }
            />
        </ListItem>
    );
}

function QCSheets(props) {
    const { sheets = [], assetId } = props;
    const [target, setTarget] = React.useState();

    function handleClick({ currentTarget }) {
        setTarget(currentTarget);
    }

    function handleClose() {
        setTarget(null);
    }

    if (!sheets || isEmpty(sheets)) {
        return null;
    }

    if (sheets.length === 1) {
        const sheet = sheets[0];
        return (
            <QCSheet
                qcId={sheet.qcId}
                assetId={assetId}
                variant={sheet.variant}
                title={<FormattedMessage id="QC_REPORT" />}
                pdfName={sheet.title}
            />
        );
    }

    return (
        <>
            <Link
                component="button"
                underline="none"
                color="textSecondary"
                onClick={handleClick}
            >
                <FormattedMessage id="QC_REPORT" />
            </Link>
            <Menu open={Boolean(target)} anchorEl={target} keepMounted onClose={handleClose}>
                {
                    sheets.map(({ qcId, variant, title }) => (
                        <MenuItem onClick={handleClose} key={qcId} dense>
                            <QCSheet qcId={qcId} variant={variant} title={capitalizeFirstLetter(title)} assetId={assetId} pdfName={title} />
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
}

function QCSheet(props) {
    const { qcId, variant, title, assetId, pdfName } = props;

    const { api } = useUser();
    const { client, id } = useParams();
    const location = useLocation();
    const { path } = useRouteMatch();
    const intl = useIntl();

    if (isEmpty(title)) {
        return null;
    }

    if (variant === 'FILE') {
        // const filename = intl.formatMessage({ id: 'TECHNICAL_SHEET_FILENAME' }, { qcId })
        let filename = null
        let normalizedString = Array.from(pdfName).map((c) => c.normalize('NFD').replace(/[\u0300-\u036f]/g, '')).join('');
        let documentTitle = normalizedString.toLowerCase();
        let documentName = `QC_${qcId}`;
        if (documentTitle !== "" && documentTitle !== null) {
          documentTitle = documentTitle.replace("Bande-Annonce", "BA");
          documentTitle = documentTitle.replace(" - ", "_").replace("-", "");
          documentTitle = documentTitle.replace(" ", "").replace("/", "_");
          documentName += "_" + documentTitle;
          documentName += '.pdf';
          filename = documentName
        }
        return (
            <Link
                underline="none"
                href={
                    api.static(
                        `/${client}/asset/qc/${qcId}`,
                        {
                            filename,
                        },
                    )
                }
                download={filename}
                color="textPrimary"
                target="_blank"
            >
                {title}
            </Link>
        );
    }

    return (
        <RouteLink
            underline="none"
            color="textPrimary"
            to={{
                pathname: generatePath(DistributionEntityDefinitionAsset.path, {
                    client,
                    id,
                    assetId,
                    detailId:qcId,
                    detailType:'qc'
                }),
                search: location.search,
            }}
        >
            {title}
        </RouteLink>
    );
}

Assets.propTypes = {
    handleAssetSelection: PropTypes.func.isRequired,
    activeAssetId: PropTypes.number,
    selectedAssets: PropTypes.arrayOf(PropTypes.number),
    path: PropTypes.string.isRequired,
    showInVaultOnly: PropTypes.bool.isRequired,
};

Assets.defaultProps = {
    selectedAssets: [],
};

export default Assets;
