import React from 'react';

import { useUser } from 'contexts/user';

const EventContext = React.createContext();
EventContext.displayName = "EventContext";

function useEventListener(category, listener, dependencies = []) {
    const context = React.useContext(EventContext);

    if (!context) {
        throw new Error('useEvent must be used within a EventProvider');
    }

    const { eventSource, base } = context;

    React.useEffect(() => {
        if (eventSource && category) {
            const callback = (e) => {
                const data = JSON.parse(e.data);
                if ((data.category === category && category.indexOf('/') < 0) || category === `${data.category}/${data.id}`) {
                    listener(e);
                }
            };
            eventSource.addEventListener(base, callback);
            return () => eventSource.removeEventListener(base, callback);
        }
        return undefined;
    }, [eventSource, category, ...dependencies]);
}

function EventProvider(props) {
    const { user, api } = useUser();

    const eventSource = React.useRef();
    const [status, setStatus] = React.useState('init');

    const isEmployee = !!user.employee;

    const category = user.employee || user.contact ? `/${isEmployee ? 'employee' : 'contact'}/${user.employee || user.contact}` : null;

    function createEvent() {
        return new EventSource(api.static('', null, 'SSE'));
    }

    function handleError(e) {
        setStatus('error');
    }

    function handleOpen(e) {
        setStatus('open');
    }

    function handleMessage(m) {
        console.debug(m);
    }

    function setupEvent() {
        const es = createEvent();
        es.addEventListener('open', handleOpen);
        es.addEventListener('error', handleError);
        es.addEventListener('message', handleMessage);
        es.addEventListener('keepalive', handleMessage);
        eventSource.current = es;
        return es;
    }

    React.useEffect(() => {
        if (category) {
            const es = setupEvent();

            return () => {
                eventSource.current = null;
                es.close();
            };
        }

        return undefined;
    }, [category]);


    return <EventContext.Provider value={{ eventSource: eventSource.current, base: category, status }} {...props} />;
}

export { EventProvider, EventContext, useEventListener };
