import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import { generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadButton from 'components/DownloadButton';
import { useUser } from 'contexts/user';
// import AssetDescription from 'components/Distribution/Assets/Description';
import AssetDescription from 'components/Distribution/Assets/Description';
// import OrderDetails from 'components/Distribution/Orders/Details';
import { DistributionEntityDefinition } from 'routes/Paths/Definitions';

import Chip from '@mui/material/Chip';

import { OrderObject, DeliveryDate, fncTooltip } from 'components/Distribution/Orders/OrderData';


import Table from 'components/Table/EnhancedPagedTable';

const propTypes = {
    clientId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchPage: PropTypes.func.isRequired,
    filters: PropTypes.shape().isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pageRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalRowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
}));

const columns = [
    // {
    //     field: 'itemId',
    //     title: '#',
    //     align: 'left'
    // },
    {
        field: 'orderTypeDescription',
        title: 'DETAILS',
        Component: OrderObject,
    },
    {
        field: 'deliveryDate',
        title: 'ESTIMATED_DELIVERY_DATE',
        align: 'left',
        Component: DeliveryDate,
    },
    {
        field: 'statusDescription',
        title: 'STATUS',
        Component: fncTooltip,
    },
    
];

function AssetOrders(props) {
    const {
        clientId, isLoading, filters,
        fetchPage, setPage, page, pageRows,
        totalRowCount, order, setOrder, orderBy,
        setOrderBy, rowsPerPage, setRowsPerPage,
    } = props;
    const match = useRouteMatch();
    const history = useHistory();
    const classes = useStyles();
    const { user } = useUser();

    const handleRowClick = React.useCallback(({ itemId }) => {
        history.push({
            pathname: generatePath(DistributionEntityDefinition.path, { 0:'asset', detailType:'order', detailId: itemId, id: match.params.id,
            assetId: match.params.assetId, client: clientId }),
        });
    }, [match.path, clientId, history.location.search]);
    const fetchPageDependencies = React.useMemo(() => [clientId, filters], [clientId, filters]);

    return (
        <Paper className={classes.root}>
            <Table
                size="small"
                showDivisions={false}
                isSortable
                uniqueField="itemId"
                onRowClick={handleRowClick}
                emptyMessage="NOT_FOUND"
                columns={columns}
                fetchPageDependencies={fetchPageDependencies}
                fetchPage={fetchPage}
                setPage={setPage}
                page={page}
                pageRows={pageRows}
                // totalRowCount={totalRowCount}
                isLoading={isLoading}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                pagenate={false}
                // rowsPerPage={rowsPerPage}
                // setRowsPerPage={setRowsPerPage}
            />
        </Paper>
    );
}

AssetOrders.propTypes = propTypes;
DeliveryDate.propTypes = {
    deliveryDate: PropTypes.number,
};
DeliveryDate.defaultProps = {
    deliveryDate: undefined,
};

export default AssetOrders;
