import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useUser } from 'contexts/user';
import Notes from 'components/Notes/List';
import CURRENT from 'utils/environment';

const propTypes = {
    language: PropTypes.string.isRequired,
};

function NewReleaseDialog(props) {
    const { language } = props;
    const { api } = useUser();

    const [notes, setNotes] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    async function fetchNotes(abortController = new AbortController()) {
        try {
            const appVersion = CURRENT.appVersion();
            setIsLoading(true);

            const response = await api.get('/notes', { appVersion }, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setNotes(data);
                if (!isEmpty(data)) {
                    setIsOpen(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }
    React.useEffect(() => {
        const abortController = new AbortController();
        fetchNotes(abortController);
        return () => {
            abortController.abort();
        };
    }, []);

    function readNotes() {
        const noteIds = notes.map((note) => note.noteId);
        api.post('/contacts/notes', { notes: noteIds });
        setIsOpen(false);
    }

    return (
        <Dialog open={isOpen}>
            <DialogContent>
                <Notes
                    notes={notes}
                    language={language}
                    background={false}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={readNotes}
                >
                    <FormattedMessage id="OK" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NewReleaseDialog.propTypes = propTypes;

export default NewReleaseDialog;
