import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { alpha, useTheme } from '@mui/material/styles';
import ListSubheader from '@mui/material/ListSubheader';

import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import VideoLibraryTwoToneIcon from '@mui/icons-material/VideoLibraryTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import HelpCenterTwoToneIcon from '@mui/icons-material/HelpCenterTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Support from 'components/Support';
import Discussions from 'components/Discussion/Followed';
import Settings from 'components/Settings';
import { useUser } from 'contexts/user';
import UserAvatar from 'components/UserAvatar';

import { userDefinition, clientDefinition } from 'utils/ofAuth';
import RouteLink from 'components/RouteLink';
import ProfileActions from 'components/ProfileActions';
import DynamicLogoImg from 'components/DynamicLogoImg';
import ClientSelector from 'components/ClientSelector';

const propTypes = {
    currentClient: PropTypes.shape(clientDefinition).isRequired,
    user: PropTypes.shape(userDefinition).isRequired,
};

const useStyles = makeStyles((theme) => createStyles({
    navList: {
        padding: theme.spacing(0, 2),
    },
    navHeader: {
        ...theme.typography.overline,
        color: theme.palette.text.primary,
        padding: theme.spacing(3, 0, 1, 2),
        backgroundColor: 'transparent',
    },
    navItem: {
        ...theme.typography.body2,
        height: 48,
        position: 'relative',
        textTransform: 'capitalize',
        padding:  theme.spacing(1, 1.5, 1, 2),
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(0, 0, .5),
        transition: `background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,

    },
    link: {
        fontWeight: 600,
        color: 'inherit',
        '&:hover, &:active': {
            textDecoration: 'none',
            backgroundColor: 'transparent',
        },
    },
    activeLink: {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        fontWeight: 600,
        '&:before': {
            display: 'block'
        },
    },
    logo: {
        display: 'flex',
        justifyItems: 'flex-start',
        alignItems: 'flex-start',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

        width: '100%',

    },
    logoImg: {
        height: 'auto',
        maxWidth: '100%',
        maxHeight: theme.spacing(4.75),
        padding: theme.spacing(0, 1.5, 0, 2),
    },
    footer: {
        marginTop: 'auto',
        padding: theme.spacing(0, 2),
    },
    nav: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        height: '100%',
        [theme.breakpoints.up('md')]: {
            minHeight: '100%',
        },
    },
}));


const melsLogo = require('resources/img/melodie.png');
const melsLogoDark = require('resources/img/melodie_white.png');

function Navigator(props)  {

    const theme = useTheme();
    const classes = useStyles();

    const {
        currentClient = {}
    } = props;

    const [supportModalOpen, setSupportModalOpen] = React.useState(false);
    const [profileModalOpen, setProfileModalOpen] = React.useState(false);
    const { user: { name: userName = "", avatar, email }, user, clearUser } = useUser();

    function handleProfileModalClose() {
        setProfileModalOpen(false);
    }

    function handleProfileModalOpen() {
        setProfileModalOpen(true);
    }

    function handleSupportModalClose() {
        setSupportModalOpen(false);
    }

    function handleSupportModalOpen() {
        setSupportModalOpen(true);
    }


    function handleLogout() {
        clearUser();
    }

    const userAccessOrder = currentClient.vodOrders >= 1;
    const userCreateOrder = currentClient.vodOrders >= 2;
    const userAccessDistribution = currentClient.distribution >= 1;
    const userAccessBilling = currentClient.invoices >= 1;
    const userManagement = currentClient.clientManagement >= 1;
    const userAccessPhysicalVault = currentClient.physicalVault >= 1;
    const userAccessDcp = currentClient.dcp >= 1;
    const currentClientId = currentClient.client;
    console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz',currentClient)

    const navigationMenus = React.useMemo(() => {
        let menus = [];
        if (userAccessDistribution) {
            let menu = {
                key: 'DISTRIBUTION',
                title: 'DISTRIBUTION',
                items: []
            };
            menu.items.push({
                title: 'CATALOGUE',
                to: `/distribution/${currentClientId}/entities/`,
                icon: <VideoLibraryTwoToneIcon />
            });
            if (currentClient.distribution === 2) {
                menu.items.push({
                    title: 'WORKSPACE_DISTRIBUTION',
                    to: `/distribution/${currentClientId}/workspace/`,
                    icon: <FavoriteTwoToneIcon />
                });
            }
            menu.items.push({
                title: 'ORDERS',
                to: `/distribution/${currentClientId}/orders/`,
                icon: <AssignmentTwoToneIcon />
            });
            menus.push(menu)
        }

        if (userAccessOrder) {
            let menu = {
                key: 'VOD',
                title: 'VOD',
                items: []
            };
            if (userCreateOrder) {
                menu.items.push({
                    title: 'ADD_ORDER',
                    to: `/vod/${currentClientId}/order`,
                    icon: <AddBoxTwoToneIcon />
                });
                menu.items.push({
                    title: 'PENDING',
                    to: `/vod/${currentClientId}/pending`,
                    icon: <PendingActionsTwoToneIcon />
                });
            }
            menu.items.push({
                title: 'ORDERS',
                to: `/vod/${currentClientId}/orders`,
                icon: <AssignmentTwoToneIcon />
            });
            menus.push(menu)
        }

        if (userAccessPhysicalVault) {
            menus.push({
                key: 'PHYSICAL_VAULT',
                items: [{
                    title: 'PHYSICAL_VAULT',
                    to: `/physical-vault/${currentClientId}`,
                    icon: <Inventory2TwoToneIcon />
                }]
            });
        }

        if (userAccessDcp) {
            let menu = {
                key: 'DCP',
                title: 'DCP',
                items: []
            };
            menu.items.push({
                    title: 'CATALOGUE',
                    to: `/dcp/${currentClientId}`,
                    icon: <Inventory2TwoToneIcon />
            });
            menus.push(menu)
        }

        if (userAccessBilling || userManagement) {
            let menu = {
                key: 'MANAGEMENT',
                title: 'MANAGEMENT',
                items: []
            };
            if (userAccessBilling) {
                menu.items.push({
                    title: 'INVOICES',
                    to: `/invoices/${currentClientId}`,
                    icon: <ReceiptTwoToneIcon />
                });
            }
            if (userManagement) {
                menu.items.push({
                    title: 'USERS',
                    to: `/management/${currentClientId}`,
                    icon: <PeopleOutlineTwoToneIcon />
                });
            }
            menus.push(menu)
        }

        menus.push({
            key: 'NOTIFICATION',
            items: [
                {
                    key: 'DISCUSSIONS',
                    title: 'YOUR_DISCUSSIONS',
                    component: Discussions,
                    icon: <ForumTwoToneIcon />
                },
                {
                    title: 'NOTIFICATION',
                    to: `/releases/${currentClientId}`,
                    icon: <NewReleasesTwoToneIcon />
                },
                {
                    onClick: handleProfileModalOpen,
                    title: 'SETTINGS',
                    icon: <SettingsTwoToneIcon />
                }
            ]
        });

        return menus;

    }, [currentClientId, userAccessDistribution, userAccessOrder, userAccessPhysicalVault, userAccessBilling, userManagement]);

    return (
        <nav className={classes.nav}>
            <div className={classes.logo}>
                <img
                    className={classes.logoImg}
                    src={DynamicLogoImg(theme.palette.background.paper, melsLogo, melsLogoDark)}
                    alt="MELODIE"
                />
            </div>
            <ClientSelector
                clients={user.clients}
                currentClientId={currentClient.client}
            />
            {
                navigationMenus.map((menu) => (
                    <List
                        className={classes.navList}
                        key={menu.key}
                    >
                        <ListSubheader className={classes.navHeader} disableSticky>
                            {
                                !menu.title ? undefined : (
                                    <FormattedMessage id={menu.title} />
                                )
                            }
                        </ListSubheader>

                        {
                            menu.items.map((menuItem) => (
                                <ListItemButton
                                    key={menuItem.to || menuItem.title || menuItem.key}
                                    component={menuItem.component || (menuItem.to ? RouteLink : "li")}
                                    {... !!menuItem.to ? { navLink: true} : {}}
                                    className={classes.navItem}
                                    {... menuItem.to ? { activeClassName: classes.activeLink} : {}}
                                    onClick={menuItem.onClick}
                                    to={menuItem.to}
                                >
                                    <ListItemIcon>
                                        {menuItem.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        disableTypography
                                        primary={<FormattedMessage id={menuItem.title}/>}
                                    />
                                </ListItemButton>
                            ))
                        }
                    </List>
                ))
            }

            <List className={classes.footer}>
                <ListItemButton onClick={handleSupportModalOpen} className={classes.navItem}>
                    <ListItemIcon>
                        <HelpCenterTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={<FormattedMessage id="SUPPORT"/>}
                    />
                </ListItemButton>
                <ListItemButton onClick={handleLogout} className={classes.navItem}>
                    {/* <ListItemAvatar> */}
                    {/*     <UserAvatar */}
                    {/*         // sx={{ width: 24, height: 24 }} */}
                    {/*         avatar={avatar} */}
                    {/*         username={userName} */}
                    {/*         email={email} */}
                    {/*     /> */}
                    {/* </ListItemAvatar> */}
                    <ListItemIcon>
                        <LogoutTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                        // disableTypography
                        primary={userName}
                        secondary={<FormattedMessage id="SIGN_OUT"/>}
                    />
                </ListItemButton>
                <ListItemButton
                    href="http://mels-studios.com/"
                    component={Link}
                    className={[classes.navItem, classes.link].join(' ')}
                    underline="hover"
                >
                    <ListItemText
                        primaryTypographyProps={{ variant: 'caption' }}
                        primary={(
                            <FormattedMessage
                                id="POWERED_BY_MELS"
                                values={{ year: (new Date()).getFullYear() }}
                            />
                        )}
                    />
                </ListItemButton>
            </List>
            <Support
                isOpen={supportModalOpen}
                handleClose={handleSupportModalClose}
                user={user}
                clientName={currentClient.name}
                currentClient={currentClient}
            />
            <Settings
                open={profileModalOpen}
                onClose={handleProfileModalClose}
            />
        </nav>
    );
}

Navigator.propTypes = propTypes;


export default Navigator;
