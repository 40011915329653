import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime, FormattedTimeParts } from 'react-intl';
import isToday from 'date-fns/isToday';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, Date, PropTypes.number]).isRequired,
    seconds: PropTypes.string,
    hour: PropTypes.string,
    minute: PropTypes.string,
    year: PropTypes.string,
    day: PropTypes.string,
    month: PropTypes.string,
    ifRelevantIncludeYear: PropTypes.bool,
    includeTime: PropTypes.bool,
};

const defaultProps = {
    seconds: undefined,
    hour: undefined,
    minute: undefined,
    year: undefined,
    day: undefined,
    month: undefined,
    ifRelevantIncludeYear: false,
    includeTime: false,
};

const useStyles = makeStyles((theme) => createStyles({
    time: {
        marginLeft: theme.spacing(1),
        display: 'inline-block',
    },
}));

function isRelavant(date, toCheck) {
    if (toCheck === 'year') {
        return date.getUTCFullYear() !== new Date().getUTCFullYear();
    }

    return false;
}

function FormattedDateOrTime(props) {
    const {
        value, seconds, hour, minute,
        year, day, month, ifRelevantIncludeYear,
        includeTime,
    } = props;
    const classes = useStyles();

    const date = new Date(value);

    if (isToday(date)) {
        return (
            <FormattedTimeParts value={value}>
                {
                    (parts) => (
                        <>
                            {parts[0].value}{parts[1].value.trim()}{parts[2].value}{parts.length > 3 ? parts[3].value : null}
                        </>
                    )
                }
            </FormattedTimeParts>
        );
    }

    return (
        <>
            <FormattedDate
                value={value}
                day={day}
                month={month}
                year={year && ifRelevantIncludeYear && isRelavant(date, 'year') ? year : undefined}
            />
            {
                !includeTime ? null : (
                    <FormattedTimeParts value={value}>
                        {
                            (parts) => (
                                <span className={classes.time}>
                                    {parts[0].value}{parts[1].value.trim()}{parts[2].value}{parts.length > 3 ? parts[3].value : null}
                                </span>
                            )
                        }
                    </FormattedTimeParts>
                )
            }
        </>
    );
}

FormattedDateOrTime.propTypes = propTypes;
FormattedDateOrTime.defaultProps = defaultProps;

export default FormattedDateOrTime;
