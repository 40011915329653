'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var CURRENT = {

    ENVIRONMENT: typeof ENVIRONMENT != 'undefined' ? ENVIRONMENT : process.env.NODE_ENV,

    DEVELOPMENT: 'development',
    TESTING: 'testing',
    STAGING: 'staging',
    PRODUCTION: 'production',

    isDevelopment: function isDevelopment() {
        return typeof CURRENT.ENVIRONMENT != 'undefined' && CURRENT.ENVIRONMENT == CURRENT.DEVELOPMENT;
    },
    isTesting: function isTesting() {
        return typeof CURRENT.ENVIRONMENT != 'undefined' && CURRENT.ENVIRONMENT == CURRENT.TESTING;
    },
    isStaging: function isStaging() {
        return typeof CURRENT.ENVIRONMENT != 'undefined' && CURRENT.ENVIRONMENT == CURRENT.STAGING;
    },
    isProduction: function isProduction() {
        return typeof CURRENT.ENVIRONMENT != 'undefined' && CURRENT.ENVIRONMENT == CURRENT.PRODUCTION;
    },
    appVersion: function appVersion() {
        return typeof VERSION != 'undefined' ? VERSION : 'unknown';
    },
    shouldLogDispatch: function shouldLogDispatch() {
        return typeof LOGDISPATCH != 'undefined' && LOGDISPATCH;
    },
    altDebugEnabled: function altDebugEnabled() {
        return typeof ALTDEBUG != 'undefined' && ALTDEBUG;
    }
};

exports.default = CURRENT;