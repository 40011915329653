import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { useUser } from 'contexts/user';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import EntityList from 'components/Distribution/Catalogue/List';
import Filter from 'components/Distribution/Catalogue/Filter';
import { freeSelectServices }  from './constants';

const propTypes = {
    selectedTitles: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func.isRequired,
};
const defaultProps = {};

const defaultFilter = {
    inVault: false,
    title: '',
    entityType: [],
    searchAllTitleLangauges: false,
};

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'max-content auto',
        height: '100%',
    },
    loading: {
        backgroundColor: 'transparent',
        height: '100%',
        width: '100%',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
        '& > svg': {
            height: 'auto !important',
        },
    },
}));

function TitleSelection(props) {
    const { selectedTitles, onChange, selectedService, setTitlesDescription, resetAssetSelection } = props;
    const { api, user } = useUser();
    const classes = useStyles();

    const { client } = useParams();

    const [filter, setFilter] = React.useState(defaultFilter);

    const {
        inVault, title, entityType, searchAllTitleLangauges,
    } = filter;

    const limit = 25;
    const [failed, setFailed] = React.useState(false);
    const [titles, setTitles] = React.useState([]);
    const [fetchingTitles, setFetchingTitles] = React.useState(false);
    const [viewLimit, setViewLimit] = React.useState(limit);
    const fullCount = (titles.length > 0 ? titles[0].fullCount : 0);
    const multiple = freeSelectServices.includes(selectedService);

    async function fetchTitles(abortController = new AbortController(), offset) {
        if (client) {
            try {
                setFetchingTitles(true);
                setFailed(false);
                const formattedSearch = filter.title ? {
                    filter: {
                        title: {
                            value: filter.title,
                            condition: 'ilike',
                        },
                        parent_id: {
                            value: null,
                        },
                    },
                } : {
                    filter: {
                        parent_id: {
                            value: null,
                        },
                    },
                };

                if (!isEmpty(filter.entityType)) {
                    formattedSearch.filter.entity_type_id = { value: filter.entityType, condition: 'in' };
                }

                if (searchAllTitleLangauges && formattedSearch.filter.title) {
                    formattedSearch.filter.title = {
                        ...formattedSearch.filter.title,
                        OR: {
                            value: {
                                value: filter.title,
                                condition: 'ilike',
                            },
                        },
                    };
                }
                const body = {
                    inVault, ...formattedSearch, offset, limit,
                };
                const response = await api.get(`/${client}/entity/`, body, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    let newTitles = data;
                    if (offset !== 0) {
                        newTitles = [...titles, ...data];
                    }
                    setTitles(newTitles);
                    setTitlesDescription(newTitles.filter((t) => selectedTitles.includes(t.id) ))
                } else {
                    setTitles([]);
                    setFailed(true);
                }
                setFetchingTitles(false);
            } catch (error) {
                setFetchingTitles(false);
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        setViewLimit(limit);
        const abortController = new AbortController();
        fetchTitles(abortController, 0);
        return () => { abortController.abort(); };
    }, [client, inVault, title, entityType, searchAllTitleLangauges]);

    React.useEffect(() => {
        const abortController = new AbortController();
        if (viewLimit !== limit && titles.length !== 0) { // only run when asking for more titles.
            fetchTitles(abortController, titles.length);
        }
        return () => { abortController.abort(); };
    }, [viewLimit]);

    const loadMore = React.useCallback(() => {
        if (titles.length < fullCount) {
            setViewLimit((prevViewLimit) => prevViewLimit + limit);
        }
    }, [titles.length, fullCount]);

    // React.useEffect(() => {
    //     const abortController = new AbortController();
    //     setTitlesDescription(titles.filter((t) => selectedTitles.includes(t.id) ))
    //     return () => {
    //         abortController.abort();
    //     };
    // }, [selectedTitles]);


    const handleTitleClick = React.useCallback(({ currentTarget: { value } }) => {
        onChange((currentTitles) => {
            const titleId = parseInt(value, 10);
            const isSelected = currentTitles.includes(titleId);

            if (!isSelected) {
                resetAssetSelection();
            }

            if (!multiple) {
                setTitlesDescription(titles.filter((t) => titleId === t.id));
                return isSelected ? [] : [titleId];
            }
            if (isSelected) {
                setTitlesDescription(titles.filter((t) => titleId !== t.id));
                return currentTitles.filter((t) => t !== titleId);
            }

            const newSelection = currentTitles.concat([titleId]);
            setTitlesDescription(titles.filter((t) => newSelection.includes(t.id)));
            return newSelection;
        })

    }, [onChange, multiple, setTitlesDescription, titles]);

    const currentClientId = parseInt(client, 10);

    const isLoading = isEmpty(titles) && fetchingTitles;
    const notFound = !isLoading && !fetchingTitles && isEmpty(titles);
    const hasResults = !notFound;


    return (
        <div className={classes.wrapper}>
            <div className={classes.root}>
                <Filter
                    selectionEnabled
                    initialFilters={filter}
                    applyFilters={setFilter}
                    fetchingTitles={isLoading}
                    defaultFilter={defaultFilter}
                    managePermission={false}
                />
                {
                    !isLoading ? null : (
                        <Loading className={classes.loading} />
                    )
                }
                {
                    !notFound ? null : (
                        <NotFound className={classes.notFound} message="NOT_FOUND" />
                    )
                }
                {
                    !hasResults ? null : (
                        <EntityList
                            limit={limit}
                            loadMore={loadMore}
                            titles={titles}
                            fetchingTitles={fetchingTitles}
                            selectionEnabled
                            selectedTitles={selectedTitles}
                            onTitleClick={handleTitleClick}
                            currentClientId={currentClientId}
                        />
                    )
                }
            </div>
        </div>
    );
}

TitleSelection.propTypes = propTypes;
TitleSelection.defaultProps = defaultProps;

export default TitleSelection;
