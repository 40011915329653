import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import omit from 'lodash/omit';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import DownloadButton from 'components/DownloadButton';
import Filter from 'components/Vault/Filter';
import Details from 'components/Vault/Details';
import Table from 'components/Table/EnhancedPagedTable';
import useLocationSearch from 'hooks/useLocationSearch';

import RequestOutput from 'components/Vault/RequestOutput';
import { useUser } from 'contexts/user';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    paper: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    additionalButton: {
        marginLeft: theme.spacing(2),
    },
    card: {
        alignSelf: 'flex-start',
    }
}));

const propTypes = {
    currentClient: PropTypes.number.isRequired,
};

function DisplayFormatedFilmingDate({ filmingDate }) {
    return (
        !filmingDate ? null : (
            <FormattedDate
                value={filmingDate}
                timeZone="UTC"
                year="numeric"
                day="numeric"
                month="numeric"
            />
        )
    );
}

const tableColumns = [
    { field: 'barcode', title: 'BARCODE' },
    { field: 'description', title: 'OBJECT_TYPE' },
    { field: 'title', title: 'TITLE' },
    { field: 'title2', title: 'OTHER_TITLE' },
    { field: 'serviceCenter', title: 'SERVICE_CENTER' },
    { field: 'clientCode', title: 'CLIENT_CODE' },
    {
        field: 'filmingDate',
        title: 'FILMING_DATE',
        Component: DisplayFormatedFilmingDate,
        align: 'right'
    },
    { field: 'format', title: 'FORMAT' },
    { field: 'status', title: 'STATUS' },
];

const defaultFilter = {
    objectTypeId: '',
    barcode: '',
    title: '',
    title2: '',
    serviceCenter: '',
    clientCode: '',
    filmingDate: null,
    formatId: '',
    statusId: [],
};

function PhysicalVault(props) {

    const { api, user: { language }, user } = useUser();
    const { client: clientId } = useParams();

    const [pages, setPages] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [failed, setFailed] = React.useState(false); // TODO handle failed
    const [selected, setSelected] = React.useState([]);
    const [showOutputDialog, setShowOutputDialog] = React.useState(false);
    const [branches, setBranches] = React.useState([]);
    const [coordinators, setCoordinators] = React.useState([]);

    const match = useRouteMatch();
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();

    const [page, setPage] = useLocationSearch('page', 0);
    const [rowsPerPage, setRowsPerPage] = useLocationSearch('rowsPerPage', 50);
    const [order, setOrder] = useLocationSearch('order', 'asc');
    const [orderBy, setOrderBy] = useLocationSearch('orderBy', 'barcode');
    const [filters, setFilters] = useLocationSearch(Object.keys(defaultFilter), defaultFilter);

    const { currentClient } = props;
    const userPermission = user.clients.find((c) => c.client === currentClient);
    const WritePhysicalVault = userPermission.physicalVault === 2;
    const objects = (Object.values(pages) || []).reduce((acc, lst) => ([...acc, ...lst]), []);

    const fullCount = (objects.length > 0 ? objects[0].fullCount : 0);

    const curPageRows = pages[page] || [];


    const fetchObjects = React.useCallback(async (flushObjects = false, pageToFetch = 0, limit = 50, sortBy, sort, abortController = new AbortController()) => {
        if (currentClient) {
            setIsLoading(true);
            setFailed(false);

            try {
                const query = {
                    ...filters,
                    orderBy: sortBy,
                    order: sort,
                    offset: pageToFetch * limit,
                    limit,
                };
                const response = await api.get(`/physical-vault/${currentClient}`, query, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setPages((currentPages) => (flushObjects ? { [pageToFetch]: data } : { ...currentPages, [pageToFetch]: data }));
                } else {
                    setPages((currentPages) => (flushObjects ? {} : omit(currentPages, pageToFetch)));
                    setFailed(true);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error('fetchObjects failed', error);
                } else {
                    console.error('ABORTED!!!', error);
                }
            }
            setIsLoading(false);
        }
    }, [filters, currentClient]);

    async function fetchBranches(abortController = new AbortController()) {
        if (clientId && language) {
            try {
                setFailed(false);
                const response = await api.get(`/physical-vault/${clientId}/branches`, { language }, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setBranches(data);
                } else {
                    setFailed(true);
                    setBranches([]);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    async function fetchCoordinators(abortController = new AbortController()) {
        if (clientId) {
            try {
                setFailed(false);
                const response = await api.get(`/physical-vault/${clientId}/coordinators`, {}, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setCoordinators(data);
                } else {
                    setFailed(true);
                    setCoordinators([]);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    const handleRowClick = React.useCallback(({ objectId }) => {
        history.push({
            pathname: generatePath(match.path, { objectId, client: currentClient }),
            search: history.location.search,
        });
    }, [currentClient, match.path, history.location.search]);

    const onVaultOutputClick = React.useCallback(() => {
        setShowOutputDialog((prevValue) => !prevValue);
    }, []);

    const handleVaultOutputClose = React.useCallback((refresh) => {
        if (refresh) {
            setSelected([]);
            setPage(0);
        }

        setShowOutputDialog(false);
    }, []);

    const handlePageChange = React.useCallback((newPage) => {
        if (isEmpty(pages[newPage])) {
            setIsLoading(true);
        }
        setPage(newPage);
    }, [pages]);

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchBranches(abortController);
        fetchCoordinators(abortController);
        return () => { abortController.abort(); };
    }, [clientId]);

    const exportFileName = React.useMemo(() => {
        const clientName = (user.clients.find((c) => parseInt(currentClient, 10) === c.client) || {}).name || currentClient;
        const dateFileName = format(Date.now(), 'yyyy-MM-dd_HH-m');
        const cleanClientName = clientName.replace(/\./g, '').replace(/\s+/g, '_');
        return `${cleanClientName}_${dateFileName}.xlsx`;
    }, [currentClient, user.clients]);

    return (
        <div className={classes.root}>
            <Card>
                <Helmet>
                    <title>
                        {`Melodie - ${intl.formatMessage({ id: 'PHYSICAL_VAULT' })}`}
                    </title>
                </Helmet>
                <div className={classes.root}>
                    <Toolbar>
                        <Filter
                            currentClient={currentClient}
                            initialFilters={filters}
                            isLoading={isLoading}
                            applyFilters={setFilters}
                            defaultFilter={defaultFilter}
                        />
                    </Toolbar>
                    <Paper className={classes.paper}>
                        <Table
                            size="small"
                            showDivisions={false}
                            fetchPageDependencies={[currentClient, filters]}
                            fetchPage={fetchObjects}
                            setPage={handlePageChange}
                            page={page}
                            pageRows={curPageRows}
                            isSortable
                            isSelectable={WritePhysicalVault}
                            setSelected={setSelected}
                            selected={selected}
                            isLoading={isLoading}
                            columns={tableColumns}
                            emptyMessage="NOT_FOUND"
                            totalRowCount={fullCount}
                            onRowClick={handleRowClick}
                            uniqueField="objectId"
                            order={order}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            disabledSelect={({ statusId }) => (
                                statusId === 11 // 11 = objet retiré
                            )}
                            disabledSelectMessage="OBJECT_NOT_AVAILABLE_REMOVED"
                            additionalActions={(
                                <>
                                    <Tooltip title={<FormattedMessage id="EXPORT_DEFINITION" />}>
                                        <span>
                                            <DownloadButton
                                                size="small"
                                                url={`/physical-vault/${currentClient}`}
                                                urlData={{ ...filters, action: 'xlsx' }}
                                                fileName={exportFileName}
                                            >
                                                <FormattedMessage id="EXPORT" />
                                            </DownloadButton>
                                        </span>
                                    </Tooltip>

                                {!WritePhysicalVault ? null : (

                                        <Button
                                            size="small"
                                            disabled={isEmpty(selected)}
                                            onClick={onVaultOutputClick}
                                            className={classes.additionalButton}
                                        >
                                            <FormattedMessage id="VAULT_OUTPUT" />
                                        </Button>

                                )}
                                </>
                            )}
                        />
                    </Paper>
                </div>
                <Details />
                <RequestOutput
                    open={showOutputDialog}
                    onClose={handleVaultOutputClose}
                    objects={objects}
                    objectIds={selected}
                    branches={branches}
                    coordinators={coordinators}
                />
            </Card>
        </div>
    );
}

PhysicalVault.propTypes = propTypes;
DisplayFormatedFilmingDate.propTypes = {
    filmingDate: PropTypes.string,
};
DisplayFormatedFilmingDate.defaultProps = {
    filmingDate: undefined,
};

export default PhysicalVault;
