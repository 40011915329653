import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';
import DiscussionIcon from '@mui/icons-material/ForumTwoTone';

import DownloadButton from 'components/DownloadButton';
import Table from 'components/Table/EnhancedPagedTable';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

import { useUser } from 'contexts/user';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
}));

const propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape({
    })).isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchPage: PropTypes.func.isRequired,
    filters: PropTypes.shape().isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pageRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalRowCount: PropTypes.number.isRequired,
};

const gridDate = {
    timeZone: 'UTC',
    year: 'numeric',
    day: '2-digit',
    month: 'long',
};

function fncContentOwner({ contentOwner = '', contentAggregator = '' }) {
    const length = 8;
    const maxLength = contentAggregator ? length : length * 2;
    const styles = {
        maxWidth: `${maxLength}ch`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    };

    const title = `${contentOwner}${contentAggregator ? ` / ${contentAggregator}` : ''}`;
    return (
        <span title={title} style={{ display: 'inline-flex' }}>
            <span style={styles}>{contentOwner}</span>
            {
                !contentAggregator ? null : (
                    <>
                        <span style={{ ...styles, margin: '0 1ch' }}>/</span>
                        <span style={styles}>{contentAggregator}</span>
                    </>
                )
            }
        </span>
    );
}

function ItemStatusTooltip({ itemStatus, hasProblem }) {
    let statusDefinition = '';
    if (itemStatus === 'En attente de master') {
        statusDefinition = 'MELS est en attente d\'un ou plusieurs éléments sources provenant du distributeur (vidéo,cc, etc.).'
    } else if (itemStatus === 'Annulé') {
        statusDefinition = 'La commande a été annulée soit par MELS ou par le client'
    } else if (itemStatus === 'En production') {
        statusDefinition = 'MELS travaille actuellement sur la création du livrable.'
    } else if (itemStatus === 'Facturé') {
        statusDefinition = 'MELS a envoyé le livrable et la facture au client.'
    } else if (itemStatus === 'Master reçu') {
        statusDefinition = 'MELS a reçu tous les éléments nécessaires pour la création du livrable. Aucun travail n\'a encore débuté.'
    } else if (itemStatus === 'Travaux terminés') {
        statusDefinition = 'MELS a envoyé le livrable au client. La facture n\'a toujours pas été envoyée.'
    } else if (itemStatus === 'Billed') {
        statusDefinition = 'MELS has sent the delivery file and the bill to the client.'
    } else if (itemStatus === 'Cancelled') {
        statusDefinition = ' The order has been cancelled by MELS or the client.'
    } else if (itemStatus === 'In production') {
        statusDefinition = ' MELS is currently working to create the delivery file.'
    } else if (itemStatus === 'Master received') {
        statusDefinition = 'MELS has received all the source files to produce the delivery file.  No work has been started.'
    } else if (itemStatus === 'Waiting for master') {
        statusDefinition = 'MELS is waiting for the source files from the provider (video,cc,  etc.).'
    } else if (itemStatus === 'Work completed') {
        statusDefinition = 'MELS has sent the delivery file to the client. The bill has not been  sent.'
    }

    return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={statusDefinition}>
                <span>
                    {itemStatus}
                </span>
            </Tooltip>
            { !hasProblem ? null : <ErrorIcon fontSize="small" color="error" style={{ marginLeft: '8px' }} /> }
        </span>
    );
}

function fncBroadcastDate({ itemStatus, windowStart, broadcastDelay}) {

    const noColor = ['Annulé', 'Travaux terminés', 'Cancelled', 'Work completed'].includes(itemStatus)
    let colorClass = null;
    if (broadcastDelay !== null) {
        if (broadcastDelay <= 1) {
            colorClass = 'error';
        } else if (broadcastDelay <= 7) {
            colorClass = 'warning';
        }
    }


    return (
        !windowStart ? null : (
            <Box sx={(theme)=> ({color: noColor || !colorClass ? undefined : theme.palette[colorClass].main})} >
                <FormattedDate value={windowStart} {...gridDate} />
            </Box>
        )
    );
}

function fncDeliveryDate({ deliveryDate }) {
    return (
        !deliveryDate ? null : <FormattedDate value={deliveryDate} {...gridDate} />
    );
}

function HasDiscussion({ hasDiscussion, itemId }) {

    return (
        <span style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            {itemId}
            {
                !hasDiscussion ? null : (
                    <DiscussionIcon size="small" sx={(theme) => ({ marginLeft: '8px', fontSize: 'inherit' })} />
                )
            }

        </span>
    );
}

const defaultColumns = [
    { field: 'title', title: 'TITLE' },
    { field: 'language', title: 'VERSION' },
    // {field: 'orderType', title: 'ORDER_TYPE' },
    { field: 'contentOwner', title: 'DESTINATION', Component: fncContentOwner },
    {
        field: 'deliveryDate',
        title: 'ESTIMATED_DELIVERY_DATE',
        align: 'right',
        Component: fncDeliveryDate,
    },
    {
        field: 'windowStart',
        title: 'BROADCAST_DATE',
        align: 'right',
        Component: fncBroadcastDate,
    },
    { field: 'encodingFormat', title: 'FORMAT' },
    { field: 'itemStatus', title: 'STATUS', Component: ItemStatusTooltip },
    { field: 'itemId', title: '#', align: 'right', Component: HasDiscussion },
];

function VODOrders(props) {
    const {
        clientId, orders, isLoading,
        filters,
        fetchPage, setPage, page, pageRows, totalRowCount,
        order, setOrder, orderBy, setOrderBy, rowsPerPage, setRowsPerPage,
        haveProductionInfo,
    } = props;
    const { user } = useUser();
    const history = useHistory();
    const match = useRouteMatch();
    const classes = useStyles();

    const handleRowClick = React.useCallback(({ itemId }) => {
        history.push({
            pathname: generatePath(match.path, { order: itemId, client: clientId }),
            search: history.location.search,
        });
    }, [clientId, match.path, history.location.search]);

    const exportFileName = React.useMemo(() => {
        const clientName = (user.clients.find((c) => parseInt(clientId, 10) === c.client) || {}).name || clientId;
        const dateFileName = format(Date.now(), 'yyyy-MM-dd_HH-m');
        const cleanClientName = clientName.replace(/\./g, '').replace(/\s+/g, '_');
        return `${cleanClientName}_${dateFileName}.xlsx`;
    }, [clientId, user.clients]);

    let columns = !haveProductionInfo ? defaultColumns : [...defaultColumns.slice(0,2), { field: 'produit', title: 'PRODUCT' }, ...defaultColumns.slice(2)];
    return (
        <Paper className={classes.root}>
                <Table
                    size="small"
                    showDivisions={false}
                    emptyMessage="NOT_FOUND"
                    isSortable
                    columns={columns}
                    onRowClick={handleRowClick}
                    uniqueField="item_id"
                    additionalActions={(
                        <Tooltip title={<FormattedMessage id="EXPORT_DEFINITION" />}>
                            <span>
                                <DownloadButton
                                    size="small"
                                    url={`/vod/orders/${clientId}`}
                                    urlData={{ ...filters, action: 'xlsx' }}
                                    fileName={exportFileName}
                                    disabled={isEmpty(orders) && !isLoading}
                                >
                                    <FormattedMessage id="EXPORT" />
                                </DownloadButton>
                            </span>
                        </Tooltip>

                    )}
                    fetchPageDependencies={[clientId, filters]}
                    fetchPage={fetchPage}
                    setPage={setPage}
                    page={ page || 0}
                    pageRows={pageRows}
                    totalRowCount={totalRowCount}
                    isLoading={isLoading}
                    order={order}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    rowsPerPage={rowsPerPage || 50}
                    setRowsPerPage={setRowsPerPage}
                />
              
        </Paper>
    );
}


VODOrders.propTypes = propTypes;

export default VODOrders;
