import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Wizard from 'components/Wizard';
import ServiceSelection from './ServiceSelection';
import TitleSelection from './TitleSelection';
import AssetSelection from './AssetSelection';
import AssetCreation from './AssetCreation';
import TitleCreation from './TitleCreation';
import OutputTechInfo from './OutputTechInfo';
// import VaultOptions from './VaultOptions';
import Destination from './Destination';
import Confirmation from './Confirmation';
import FileDestination from './FileDestination'
import {
    validateEmail, initialState, defaultFormOptions, deliveryTypes,
    fileDelivery, tapeDelivery, vaultService,
    closedCaptionService,
} from './constants';
import { useUser } from 'contexts/user';

const emptyAsset =  {
    'assetType' : '',
    'tvDefinition' : '',
    'assetLanguage' : '',
    'translationTypes' : '',
    'frameRate' : '',
    'ratio' : '',
    'runTime' : '',
    'element' : '',
    'version' : '',
    'season' : '',
    'seasonTitle' : '',
    'season' : '',
    'episodeTitle' : '',
    'partNumber' : '',
    'audioTrack' : [],
}

const emptyAudio = {
    'language' : '',
    'audioTrackType' : '',
    'audioContenType' : '',
}

const useStyles = makeStyles((theme) => createStyles({
    overflowWrapper: {
        overflowY: 'auto',
        display: 'grid',
        gridTemplateRows: '100%',
        gridTemplateColumns: '100%',
    },
    transitionWidth: {
        transition: 'inherit',
        transitionProperty: 'width',
    },
    ...Object.keys(theme.breakpoints.values).reduce((m,k) => {
        m[`width${k.toUpperCase()}`] = {width: `${theme.breakpoints.values[k]}px`}
        return m
    }, {}),
}));

const propTypes = {
    closeModal: PropTypes.func,
};
const defaultProps = {
    closeModal: null,
};

function DestinationsGroups(props) {
    const {
        setDestination, selectedService, currentServiceIndex,
        delivery, destination, setOutputData, outputData, setDelivery,
        deliverys, destinations, setCurrentServiceIndex, broadcasterOptions,
    } = props;
    const { api } = useUser();
    const { client } = useParams();
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const destinationValid = React.useMemo(() => {
        const {
            externalRecipient, sendTo, deliveryDate, specFile, virtualVaultEntry,
            jointFileFiles, deliveryClient, deliveryInfoSupplied,
        } = destinations[currentServiceIndex] || destinations[0] ;

        const {
            deliveryType, fileDeliveryType, hardDiskDeliveryType,
            deliveryAddress, deliverTo,
        } = deliverys[currentServiceIndex] || deliverys[0];
        const Vvtype = selectedService === vaultService && !isNull(deliveryDate) && currentServiceIndex === 0;
        const supportedClient = !externalRecipient && !isEmpty(sendTo) && !isNull(deliveryDate);
        const vvSelection = virtualVaultEntry && !isNull(deliveryDate);

        const externalNoSpec = externalRecipient && !isNull(deliveryDate) && deliveryClient;

        return supportedClient || externalNoSpec || Vvtype || vvSelection;
    }, [destinations[currentServiceIndex], deliverys[currentServiceIndex]]);

    const otherDestinationValid = React.useMemo(() => {
        const {
            sendTo, deliveryDate,
            deliveryInfoSupplied,
        } = destinations[currentServiceIndex] || destinations[0];

        const {
            deliveryType, fileDeliveryType, hardDiskDeliveryType,
            deliveryAddress, deliverTo,
        } = deliverys[currentServiceIndex] || deliverys[0];
        const deliveryInfoValid = !deliveryInfoSupplied && deliverTo && fileDeliveryType && ( (fileDeliveryType === 20 && deliveryAddress) || validateEmail(deliveryAddress));

        return deliveryInfoValid;
    }, [destinations[currentServiceIndex], deliverys[currentServiceIndex]]);

    const disableOutputTech = React.useMemo(() => {
        const {
            externalRecipient, sendTo, deliveryDate, specFile,
            deliveryClient, asSource, virtualVaultEntry, deliveryInfoSupplied,
        } = destinations[currentServiceIndex] || destinations[0];

        const { deliveryType } = deliverys[currentServiceIndex] || deliverys[0];

        return (
            (!externalRecipient && !isEmpty(sendTo) && !isNull(deliveryDate))
            || (externalRecipient && !!specFile && deliveryTypes.includes(deliveryType) && !isNull(deliveryDate) && deliveryClient)
            || virtualVaultEntry
            || deliveryInfoSupplied
        );
    }, [selectedService, destinations[currentServiceIndex], deliverys[currentServiceIndex]]);

    const outputTechInfoValid = React.useMemo(() => {
        const { asSource } = destinations[currentServiceIndex] || destinations[0];

        if (asSource) {
            return asSource;
        }

        const {
            codec, wrapper, frame,
            piste, loudness, rubanType, ardoise,
        } = outputData[currentServiceIndex] || outputData[0];

        const fileDetailsValid = fileDelivery && codec && wrapper && loudness;
        return frame && !isEmpty(piste) && fileDetailsValid;
    }, [destinations[currentServiceIndex], outputData[currentServiceIndex], deliverys[currentServiceIndex]]);


    const resetFile = React.useCallback((event, buttonNavigation) => {
        setDelivery((currentDeliveries) => currentDeliveries.map((d, i) => i !== currentServiceIndex ? d : initialState.delivery[0]))
        buttonNavigation(event);
    });

    const resetOutput = React.useCallback((event, buttonNavigation) => {
        setOutputData((currentOutputData) => currentOutputData.map((d, i) => i !== currentServiceIndex ? d : initialState.outputData[0]));
        buttonNavigation(event);
    });

    const resetAll = React.useCallback((event, buttonNavigation) => {
        // resetOutput();
        // setDestination((currentDestinations) => currentDestinations.map((d, i) => i !== currentServiceIndex ? d : initialState.destination[0]));
        //
        setCurrentServiceIndex((c) => c > 0 ? c - 1 : 0);
        setDelivery((currentDelivery) => currentDelivery.filter((del, index) => index !== currentServiceIndex));
        setOutputData((currentOutput) => currentOutput.filter((out, index) => index !== currentServiceIndex));
        setDestination((currentDestination) => currentDestination.filter((des, index) => index !== currentServiceIndex));
        buttonNavigation(event);
    });

    return ([
        <Destination
            altStep="services"
            shouldReturn
            contentClassName={classes.widthMD}
            isDisabled={isEmpty(delivery)}
            nextValid={destinationValid}
            onChange={setDestination}
            stepHeader={<FormattedMessage id="SELECTION_DESTINATION" />}
            selectedService={selectedService}
            currentServiceIndex={currentServiceIndex}
            destination={destination}
            previousAction={resetAll}
            broadcasterOptions={broadcasterOptions}
        />,
        <OutputTechInfo
            stepHeader={<FormattedMessage id="CONTENT" />}
            contentClassName={classes.widthMD}
            altStep="services"
            isDisabled={disableOutputTech || isEmpty(delivery)}
            nextValid={outputTechInfoValid}
            onChange={setOutputData}
            outputData={outputData}
            currentServiceIndex={currentServiceIndex}
            previousAction={resetOutput}
        />,
        <FileDestination
            stepHeader={<FormattedMessage id="FILE_DESTINATION" />}
            contentClassName={classes.widthMD}
            altStep="services"
            isDisabled={disableOutputTech || isEmpty(delivery)}
            nextValid={otherDestinationValid}
            onChange={setDelivery}
            currentServiceIndex={currentServiceIndex}
            delivery={delivery}
            previousAction={resetFile}
        />
    ]);
}

DestinationsGroups.propTypes = propTypes;
DestinationsGroups.defaultProps = defaultProps;

export default DestinationsGroups;
