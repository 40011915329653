import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import { queryStringToObj, snakeCase } from 'utils/ofQuery';
import { useUser } from 'contexts/user';
import RouteLink from 'components/RouteLink';
import { EditMetadataDialog, ViewMetadataDialog } from 'components/Distribution/Entities/MetadataDialog';
import Detail from 'components/Detail';
import FallbackImage from 'components/FallbackImageOLD';
import { formatPostData } from 'utils/ofApi';
import Documents from 'components/Documents';

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
    },
    hiddenPaper: {
        display: 'grid',
    },
    titleDetails: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridGap: theme.spacing(2),
        alignItems: 'stretch',
    },
    hiddenTitleDetails: {
        display: 'inline-flex',
    },
    poster: {
        // objectFit: 'cover',
        // backgroundColor: theme.palette.divider,
        // borderRadius: theme.shape.borderRadius,
        // height: '30vh',
        width: '20.25vh', // 0.675 ratio
        marginRight: theme.spacing(2),
    },
    'posterImage': {
        width: '100%',
        borderRadius: theme.shape.borderRadius,
    },
    hiddenPoster: {
        marginRight: theme.spacing(2),
        height: '8vh',
        display: 'none',
    },
    chips: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
    summary: {
        overflow: 'auto',
        maxHeight: 'calc(1.57143em * 2.5)',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc(1.57143em * 5.5)',
        },
    }
}));

const defaultEntityState = {
    poster: '',
    hasMore: false,
    details: {},
    editedMetadata: {},
    metadata: {},
};

function EntityTitle(props) {
    const [isHidden, setIsHidden] = React.useState(false);
    const [fetchingEntity, setFetchingEntity] = React.useState(true);
    const [failed, setFailed] = React.useState(false);
    const [entity, setEntity] = React.useState(defaultEntityState);
    const intl = useIntl();
    const { id, client } = useParams();
    const { api, user } = useUser();
    const { language } = user;

    const classes = useStyles();

    const location = useLocation();
    const titleView = queryStringToObj(location.search).view;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { rootId, handleOriginalLanguage, displayLanguage, handleLangChange } = props;
    const {
        poster, details, hasMore,
        metadata, aggregator, additionalOwner,
        additionalOwnerType,
    } = entity;

    const userLang = language.toLowerCase();
    const imageLang = ['fr', 'en'].includes(userLang) ? `default_${userLang}.png` : 'default.png';
    const fallbackImage = api.static(`/images/titles/${imageLang}`, {}, 'SERVICE');
    const titlePoster = api.static(`/storage/${poster}`, null, 'SERVICE');
    // const titlePoster = `/${client}/entity/${id}/title/poster`;

    function handleFetchEntityDetails(data) {
        setEntity((oldEntity) => {
            const posterUrl = data.poster ? api.static(`/storage/${data.poster}`, null, 'SERVICE') : undefined;
            return ({
                ...oldEntity,
                poster: data.poster ? `${data.poster}?${new Date().valueOf()}` : data.poster,
                details: data.details,
                originalTitle: data.details.originalTitle || data.details.title || data.title,
                hasMore: data.hasMore,
                metadata: {...data.metadata, originalPoster: posterUrl, poster: undefined },
            });
        });
    }

    async function fetchEntity(abortController = new AbortController()) {
        if (client && id) {
            try {
                setFetchingEntity(true);
                setFailed(false);
                const response = await api.get(`${client}/entity/${id}/title`, { language }, { signal: abortController.signal });
                if (response.ok) {
                    const {
                        id: titleId, ownerId, ...data
                    } = await response.json();



                    const {
                        originalLanguage,
                    } = data.metadata;

                    const posterUrl = data.poster ? api.static(`/storage/${data.poster}`, null, 'SERVICE') : undefined;

                    let additionalOwner = null;
                    let additionalOwnerType = null;

                    const clientId = parseInt(client, 10);


                    if (ownerId && ownerId !== clientId) {
                        additionalOwner = data.ownerName;
                        additionalOwnerType = 'OWNER';
                    } else if (data.aggregatorId && data.aggregatorId !== clientId) {
                        additionalOwner = data.aggregatorName;
                        additionalOwnerType = 'AGGREGATOR';
                    }



                    setEntity({
                        additionalOwner,
                        additionalOwnerType,
                        aggregator: data.aggregator,
                        poster: data.poster,
                        id: titleId,
                        details: data.details,
                        originalTitle: data.details.originalTitle || data.details.title || data.title,
                        hasMore: data.hasMore,
                        metadata: {...data.metadata, originalPoster: posterUrl, poster: undefined },
                        entityType: data.entityType,
                    });
                    if (handleOriginalLanguage) {
                        handleOriginalLanguage(originalLanguage && originalLanguage.value);
                    }
                    if (handleLangChange) {
                        handleLangChange('fetchEntity',  (originalLanguage && originalLanguage.value) || language.toLowerCase());
                    }
                    setFetchingEntity(false);
                } else {
                    setFetchingEntity(false);
                    setFailed(true);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (rootId) {
            fetchEntity(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [client, rootId]);

    function handleToggleExpand() {
        setIsHidden((prevIsHidden) => !prevIsHidden);
    }

    const handleSave = React.useCallback(async (editedMetadata) => {
        const snackId = enqueueSnackbar(<FormattedMessage id="SAVING_TITLE_METADATA" />, { variant: 'loading', persist: true });
        const newMetadata = [];
        const files = [];

        Object.keys(editedMetadata).forEach((key) => {
            if (editedMetadata[key] instanceof FileList) {
                newMetadata.push({ key: snakeCase(key), value: `file_${files.length}` });
                files.push(...editedMetadata[key]);
            } else if (key === 'poster' && editedMetadata[key] === undefined) {
                newMetadata.push({ key: snakeCase(key), value: poster });
            } else {
                newMetadata.push({ key: snakeCase(key), value: editedMetadata[key] });
            }
        });

        const response = await api.put(`${client}/entity/${rootId}`, formatPostData({ metadata: newMetadata }, files));

        if (response.ok) {
            const data = await response.json();
            handleFetchEntityDetails(data);
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="TITLE_METADATA_SAVED" />, { variant: 'success' });
        } else {
            const data = await response.json();
            let content = <FormattedMessage id="ERROR_SAVING_TITLE_METADATA" />;
            if (data.error) {
                content = (
                    <div>
                        <Typography>
                            <FormattedMessage id="ERROR_SAVING_TITLE_METADATA" />
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            <FormattedMessage id={data.error.reason} />
                        </Typography>
                    </div>
                );
            }
            closeSnackbar(snackId);
            enqueueSnackbar(content, { variant: 'error' });
        }
    }, [client, rootId]);

    const defaultPath = `/distribution/${client}/entities/${id}/`;
    const userPermission = user.clients.find((c) => c.client === parseInt(client, 10)) || {};
    const managementPermission = userPermission.distribution >= 2;
    const originalLanguage = metadata.originalLanguage && metadata.originalLanguage.value;
    const defaultLanguages = originalLanguage && !['en', 'fr'].includes(originalLanguage) ? ['en', 'fr', originalLanguage] : ['en', 'fr'];

    const showSkeleton = fetchingEntity || entity === defaultEntityState;

    return (
        <div className={`${classes.paper} ${isHidden ? classes.hiddenPaper : ''}`}>
            <Helmet>
                <title>
                    Melodie - {`${entity.originalTitle || details.title || entity.title || ''}${details.prodYear ? ` (${details.prodYear})` : ''}${!additionalOwner ? '' : ` [${intl.formatMessage({ id: additionalOwnerType })}: ${additionalOwner}]`}${['detailsTitle', 'editTitle'].includes(titleView) ? ` - ${intl.formatMessage({ id: titleView === 'detailsTitle' ? 'DETAILS' : 'MODIFY' })}` : ''}`}
                </title>
            </Helmet>
            <div className={isHidden ? classes.hiddenTitleDetails : classes.titleDetails}>
                <div className={`${classes.poster} ${isHidden ? classes.hiddenPoster : ''}`}>
                    <FallbackImage
                        alt={details.title || 'No Image Available'}
                        className={classes.posterImage}
                        source={titlePoster}
                        fallback={fallbackImage}
                        // loading={fetchingEntity}
                        loadingProps={{
                            height: "30vh"
                        }}
                    />
                </div>
                {
                    isHidden ? (
                        <Grid container direction="column" spacing={2}>
                            <Grid container item spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="h4">
                                        {
                                            showSkeleton ? <Skeleton /> : (
                                                `${entity.originalTitle || details.title || entity.title || ''}${details.prodYear ? ` (${details.prodYear})` : ''}${!additionalOwner ? '' : ` [${intl.formatMessage({ id: additionalOwnerType })}: ${additionalOwner}]`}`
                                            )
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={handleToggleExpand}
                                    >
                                        <FormattedMessage id="SHOW_MORE" />
                                    </Button>
                                </Grid>
                                <Grid container item xs spacing={1} justifyContent="flex-end">
                                    {
                                        !hasMore ? null : (
                                            <Grid item>
                                                <Button size="small" variant="outlined" underline="none" component={RouteLink} to={`${defaultPath}?view=detailsTitle`}>
                                                    <FormattedMessage id="DETAILS" />
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                    {
                                        !managementPermission ? null : (
                                            <Grid item>
                                                <Button size="small" variant="outlined" underline="none" component={RouteLink} to={`${defaultPath}?view=editTitle`}>
                                                    <FormattedMessage id="EDIT" />
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Detail>
                                {
                                    showSkeleton ? <Skeleton /> : details.shortDescription
                                }
                            </Detail>
                        </Grid>
                    ) : (
                        <div>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="h4">
                                        {
                                            showSkeleton ? <Skeleton width="25vw" /> : (
                                                entity.originalTitle || details.title || entity.title || ''
                                            )
                                        }
                                    </Typography>
                                    {
                                        !additionalOwner ? null : (
                                            <Typography variant="caption" color="textSecondary" component="p">
                                                {
                                                    `${additionalOwner} (${intl.formatMessage({ id: additionalOwnerType })})`
                                                }
                                            </Typography>
                                        )
                                    }
                                </Grid>
                                <Grid item container justifyContent="flex-end" spacing={1} xl={2}>
                                    {
                                        !hasMore ? null : (
                                            <Grid item>
                                                <Button size="small" variant="outlined" underline="none" component={RouteLink} to={`${defaultPath}?view=detailsTitle`}>
                                                    <FormattedMessage id="DETAILS" />
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                    {
                                        !managementPermission ? null : (
                                            <Grid item>
                                                <Button size="small" variant="outlined" underline="none" component={RouteLink} to={`${defaultPath}?view=editTitle`}>
                                                    <FormattedMessage id="EDIT" />
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 0 }}>
                                <Grid item xs={12} lg={6} container spacing={2}>
                                    <Detail chips max={3} xs="auto" label="GENRE" loading={showSkeleton}>
                                        {details.genre}
                                    </Detail>
                                    <Detail xs="auto" label="ORIGINAL_LANGUAGE" loading={showSkeleton}>
                                        {details.originalLanguage}
                                    </Detail>
                                    <Detail xs="auto" label="RUNTIME" loading={showSkeleton}>
                                        {details.runtime}
                                    </Detail>
                                    <Detail xs="auto" label="YEAR" loading={showSkeleton}>
                                        {details.prodYear}
                                    </Detail>
                                    <Detail chips max={3} xs="auto" label="DIRECTOR" loading={showSkeleton}>
                                        {details.director}
                                    </Detail>
                                    <Grid item container xs={12}>
                                        <Detail xs={12} label="SYNOPSIS" loading={showSkeleton} contentClassName={classes.summary}>
                                            {details.summary}
                                        </Detail>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Documents
                                        relationType="entity"
                                        relationId={rootId}
                                        managementPermission={managementPermission}
                                    />
                                </Grid>
                            </Grid>
                            <div>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    // disableRipple
                                    // style={{ padding: 0, backgroundColor: 'transparent' }}
                                    onClick={handleToggleExpand}
                                >
                                    <FormattedMessage id="SHOW_LESS" />
                                </Button>
                            </div>
                        </div>
                    )
                }
            </div>


            <EditMetadataDialog
                defaultPath={defaultPath}
                open={titleView === 'editTitle'}
                metadata={metadata}
                fetchingMetadata={isEmpty(metadata) && fetchingEntity}
                handleSave={handleSave}
                displayLanguage={displayLanguage}
                handleLangChange={handleLangChange}
                originalLanguage={originalLanguage}
                defaultLanguages={defaultLanguages}
            />
            <ViewMetadataDialog
                defaultPath={defaultPath}
                open={titleView === 'detailsTitle'}
                metadata={metadata}
                fetchingMetadata={isEmpty(metadata) && fetchingEntity}
                displayLanguage={displayLanguage}
                handleLangChange={handleLangChange}
            />
        </div>
    );
}

EntityTitle.propTypes = {
    rootId: PropTypes.number,
    handleOriginalLanguage: PropTypes.func,
    displayLanguage: PropTypes.string.isRequired,
    handleLangChange: PropTypes.func,
};

EntityTitle.defaultProps = {
    rootId: null,
    handleOriginalLanguage: null,
    handleLangChange: null,
};

export default EntityTitle;
