import React from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import NewReleaseDialog from 'components/Notes/NewReleaseDialog';
import Navigator from 'components/Navigator';
import { useUser } from 'contexts/user';
// import { EventProvider } from 'contexts/event';
import { SocketProvider } from 'contexts/socket';
import { ConstantsProvider } from 'contexts/constants';
import CommentNotify from 'components/Discussion/CommentNotify';

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => createStyles({
    grid: {
        height: '100vh',
        maxWidth: '100vw',
        display: 'grid',
        gridTemplateRows: 'min-content auto',
        gridTemplateAreas: `
            "navigation"
            "main"
        `,
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: `${DRAWER_WIDTH}px auto `,
            gridTemplateRows: '100%',
            gridTemplateAreas: `
                "navigation main"
            `,
        },
    },
    main: {
        gridArea: 'main',
        padding: theme.spacing(2),
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'grid',
        gridTemplateRows: '100%',
        gridTemplateColumns: '100%',
        maxWidth: theme.breakpoints.values.xl,
        margin: theme.spacing(0, 'auto'),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(14, 2),
        },
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
        },
    },
    menuButton: {
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        border: 'none',
        height: '100%',
        width: DRAWER_WIDTH,
    },
}));

function AppPanel(props) {
    const { user, user: { clients = []} } = useUser();
    const { client } = useParams();
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const lgDrawerHidden = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const { children, ...other } = props;

    const currentClient = React.useMemo(() => {
        let currentClientId = parseInt(client, 10) || clients.find((c) => c.default).client;
        let currClient = clients.find((c) => c.client === currentClientId);
        if (!currClient) {
            currClient = clients.find((c) => c.default);
            currentClientId = currClient.client;
        }
        return currClient;
    }, [client, clients]);

    const handleDrawerToggle = React.useCallback(() => {
        setMobileOpen((currentValue) => !currentValue);
    }, []);

    return (
        // <EventProvider>
            <SocketProvider>
                <CommentNotify />
                <NewReleaseDialog contactId={user.contact} language={user.language} />
                <div className={classes.grid}>
                    <ConstantsProvider>
                        <div className={classes.drawer}>
                            {
                                !isMobile ? null : (
                                    <Drawer
                                        variant="temporary"
                                        anchor="left"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        ModalProps={{
                                            keepMounted: true, // Better open performance on mobile.
                                        }}
                                    >
                                        <Navigator currentClient={currentClient} user={user} />
                                    </Drawer>
                                )
                            }
                            {
                                isMobile ? null : (
                                    <Drawer
                                        classes={{
                                            paper: classes.drawerPaper,
                                        }}
                                        variant="permanent"
                                        open
                                    >
                                        <Navigator currentClient={currentClient} user={user} />
                                    </Drawer>
                                )
                            }
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                                size="large">
                                    <MenuIcon />
                            </IconButton>
                        </div>
                        <div className={classes.main}>
                            { children }
                        </div>
                    </ConstantsProvider>
                </div>
            </SocketProvider>
        // </EventProvider>
    );
}

export default AppPanel;
