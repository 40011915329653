import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import parse, { domToReact } from 'html-react-parser';

import RouteLink from 'components/RouteLink';

const RELEASE_TYPE = 'RELEASE';

const propTypes = {
    notes: PropTypes.arrayOf(PropTypes.shape({
        noteId: PropTypes.number.isRequired,
        content: PropTypes.string.isRequired,
        noteType: PropTypes.oneOf(['RELEASE', 'MESSAGE']),
        displayDate: PropTypes.string,
        title: PropTypes.string,
        version: PropTypes.string,
    })),
    language: PropTypes.string.isRequired,
    background: PropTypes.bool,
};

const defaultProps = {
    notes: [],
    background: true,
};

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        padding: theme.spacing(1, 2),
        '& + &': {
            marginTop: theme.spacing(2),
        },
        '& li > ul > li': {
            listStyle: 'circle inside',
        },
        '& li > ul': {
            marginLeft: '1.2em',
        },
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    backgroundless: {
        padding: 0,
        background: 'transparent',
        boxShadow: 'none',
        '& + &': {
            marginTop: theme.spacing(2),
        },
    },

}));

const htmlParseOptions = {
    replace: (node) => {
        let result = node;

        if (node.type === 'tag' && ['h4', 'h5', 'h6'].includes(node.name)) {
            result = (<Typography variant={node.name}>{domToReact(node.children, htmlParseOptions)}</Typography>);
        } else if (node.type === 'tag' && ['ul', 'ol'].includes(node.name)) {

            result = (
                <List
                    component={node.name}
                    disablePadding
                >
                    {domToReact(node.children, htmlParseOptions)}
                </List>
            );
        } else if (node.type === 'tag' && ['li'].includes(node.name)) {
            result = (
                <ListItem dense>
                    <ListItemText>
                        {domToReact(node.children, htmlParseOptions)}
                    </ListItemText>
                </ListItem>
            );
        } else if (node.type === 'tag' && node.name === 'a') {
            result = (<RouteLink to={node.attribs.href}>{domToReact(node.children, htmlParseOptions)}</RouteLink>);
        } else if (node.type === 'tag' && node.name === 'p') {
            result = (<Typography paragraph>{domToReact(node.children, htmlParseOptions)}</Typography>);
        }
        return result;
    },
};

function NoteList(props) {
    const { notes, language, background, Container = React.Fragment } = props;

    const classes = useStyles();
    const mostRecent = notes.reduce((prev, current) => (current.noteType !== RELEASE_TYPE || prev.displayDate > current.displayDate ? prev : current), {});
    return notes.map(({ noteId, content, noteType, displayDate, title, version }) => {
        if (!content) {
            return null;
        }

        let color = 'default';
        if (noteType === RELEASE_TYPE) {
            color = 'secondary';
            if (noteId === mostRecent.noteId) {
                color = 'primary';
            }
        }
        return (
            <Container>
                <Paper key={noteId} className={background ? classes.paper : classes.backgroundless} >
                    <Grid>
                        <Grid item container justifyContent="space-between" spacing={2} className={classes.header}>
                            <Grid item container spacing={2} xs={8}>
                                <Grid item>
                                    <Chip
                                        variant="outlined"
                                        color={color}
                                        label={(
                                            <>
                                                <FormattedMessage id={noteType} />
                                                {noteType !== RELEASE_TYPE ? null : ` ${version}`}
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormattedDate value={displayDate} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            {parse(content, htmlParseOptions)}
                        </Grid>
                    </Grid>
                </Paper>
                </Container>
        );
    });
}


NoteList.propTypes = propTypes;
NoteList.defaultProps = defaultProps;

export default NoteList;
