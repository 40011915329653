import React from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { Redirect, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';

import { useUser } from 'contexts/user';
import Loading from 'components/Loading';
import DynamicLogoImg from 'components/DynamicLogoImg';

const useStyles = makeStyles((theme) => createStyles({
    "@keyframes appearRight": {
        '0%': {
            opacity: 0,
            transform: 'translateX(1rem)',
        },
        '90%': {
            transform: 'translateX(0rem)',
        },
        '100%': {
            opacity: '1',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            margin: 'none',
            minWidth: '50vw',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '35vw',
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '35vw',
        },
    },
    logoImage: {
        maxWidth: '80%',
        height: 'auto',
        margin: `0 auto ${theme.spacing(4)}`,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '50%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '65%',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '70%',
        },
    },
    action: {
        width: '80wv',
        padding: theme.spacing(1),
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appearRight .5s linear',
        "& input:autofill": {
            boxShadow: 'unset',
            textFillColor: 'unset',
            caretColor: 'unset',
        },
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            width: '50vw',
        },
        [theme.breakpoints.up('md')]: {
            width: '35vw',
        },
        [theme.breakpoints.up('lg')]: {
            width: '40vw',
        },
        [theme.breakpoints.up('xl')]: {
            width: '20vw',
        },
    },
    button: {
        marginLeft: theme.spacing(2),
    },
}));


function Login() {
    const theme = useTheme();
    const location = useLocation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();
    const intl = useIntl();
    const {
        user, feedBack, auth, fetchUser, loginUser,
    } = useUser();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [activePanel, setActivePanel] = React.useState('login');
    const [inProgress, setInProgress] = React.useState(false);

    React.useEffect(() => {
        if (auth.isAuthenticated() && auth.getAccessToken()) {
            const token = auth.getAccessToken();
            auth.getUserInfo(token, fetchUser);
        }
    }, []);

    function isEmailValid() {
        return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email);
    }

    function isPasswordValid() {
        return password.trim().length > 0;
    }

    function handleInputChange({ target: { value, name } }) {
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    }

    function handleLogin(event) {
        event.preventDefault();

        const emailValid = isEmailValid();
        const passwordValid = isPasswordValid();

        let validMessage = '';

        if (!emailValid) {
            validMessage = 'EMAIL_INVALID_ERROR';
        } else if (!passwordValid) {
            validMessage = 'PASSWORD_INVALID_ERROR';
        }

        if (validMessage) {
            enqueueSnackbar(<FormattedMessage id={validMessage} />, { variant: 'error' });
        }

        if (emailValid && passwordValid) {
            setInProgress(true);
            auth.login(email, password, (error) => {
                if (!isEmpty(error)) {
                    enqueueSnackbar(<FormattedMessage id="SIGN_IN_ERROR" />, { variant: 'error' });
                    setInProgress(false);
                } else {
                    setInProgress(false);
                    auth.getUserInfo(auth.getAccessToken(), loginUser);
                }
            });
        }
    }

    function handlePasswordReset(event) {
        event.preventDefault();

        if (isEmailValid()) {
            setInProgress(true);
            auth.resetPassword(email, (error) => {
                if (!isEmpty(error)) {
                    enqueueSnackbar(<FormattedMessage id="PASSWORD_RESET_ERROR" />, { variant: 'error' });
                } else {
                    enqueueSnackbar(<FormattedMessage id="PASSWORD_RESET_SENT" />, { variant: 'success' });
                    setActivePanel('login');
                }
                setInProgress(false);
            });
        } else {
            enqueueSnackbar(<FormattedMessage id="EMAIL_INVALID_ERROR" />, { variant: 'error' });
        }
    }


    if (auth.isAuthenticated() && !isEmpty(user)) {
        const toRoute = { pathname: '/', state: { referrer: '/login' } };
        if (location && location.state && location.state.nextPathname) {
            toRoute.pathname = location.state.nextPathname;
            if (location.state.nextSearch) {
                toRoute.search = location.state.nextSearch;
            }
        }
        return <Redirect to={toRoute} />;
    }

    if (auth.isAuthenticated()) {
        return (
            <>
                <Helmet>
                    <title>
                        Melodie - {intl.formatMessage({id: 'SIGNING_IN'})}
                    </title>
                </Helmet>
                <Loading intlText2="SIGNING_IN" intlText="WELCOME_BACK" />
            </>
        );
    }

    return (
        <div className={classes.container}>
            <Helmet>
                <title>
                    Melodie - {intl.formatMessage({id: 'SIGN_IN'})}
                </title>
            </Helmet>
            <div className={classes.logo}>
                <img
                    className={classes.logoImage}
                    src={DynamicLogoImg(theme.palette.background.paper)}
                    alt="MELS"
                />
            </div>
            {
                activePanel === 'reset' ? (
                    <ResetForm
                        classes={classes}
                        email={email}
                        inProgress={inProgress}
                        onChange={handleInputChange}
                        togglePanel={setActivePanel}
                        onSubmit={handlePasswordReset}
                        feedBackText={feedBack.text}
                        feedBackValid={feedBack.valid}
                    />
                ) : (
                    <SignInForm
                        classes={classes}
                        email={email}
                        password={password}
                        inProgress={inProgress}
                        onChange={handleInputChange}
                        togglePanel={setActivePanel}
                        onSubmit={handleLogin}
                        feedBackText={feedBack.text}
                        feedBackValid={feedBack.valid}
                    />
                )
            }
        </div>
    );
}


function ResetForm(props) {
    const {
        email, inProgress, onChange, togglePanel,
        onSubmit, classes, feedBackText, feedBackValid
    } = props;

    return (
        <div className={classes.action}>
            <Typography variant="h2" gutterBottom>
                <FormattedMessage id="PASSWORD_RESET" />
            </Typography>
            <Typography paragraph>
                <FormattedMessage id="ENTER_EMAIL_RESET" />
            </Typography>
            <form onSubmit={onSubmit}>
                <TextField
                    label={<FormattedMessage id="EMAIL" />}
                    type="email"
                    name="email"
                    value={email}
                    disabled={inProgress}
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                    error={email.length > 0 && !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email)}
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        type="reset"
                        value={false}
                        disabled={inProgress}
                        onClick={() => togglePanel('login')}
                    >
                        <FormattedMessage id="BACK" />
                    </Button>
                    <Button
                        className={classes.button}
                        {... inProgress ? { icon: 'spinner' } : {}}
                        type="submit"
                        color="primary"
                        disabled={inProgress}
                        variant="contained"
                        margin="normal"
                    >
                        {
                            inProgress ? (<CircularProgress color="inherit" size="1.54rem" />) : <FormattedMessage id="SUBMIT" />
                        }
                    </Button>
                </Box>
            </form>
        </div>
    );
}

ResetForm.propTypes = {
    email: PropTypes.string.isRequired,
    feedBackText: PropTypes.string,
    feedBackValid: PropTypes.bool,
    handleValidation: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
};

ResetForm.defaultProps = {
    feedBackText: null,
    feedBackValid: null,
};


function SignInForm(props) {
    const {
        email, password, inProgress, onChange,
        togglePanel, onSubmit, classes, feedBackText, feedBackValid
    } = props;

    return (
        <div className={classes.action}>
            <Typography variant="h2" gutterBottom>
                <FormattedMessage id="SIGN_IN" />
            </Typography>
            {
                !isEmpty(feedBackText) ? (
                    <Typography color="error" paragraph>
                        <FormattedMessage id={feedBackText} />
                    </Typography>
                ) : false
            }
            <form onSubmit={onSubmit}>
                <TextField
                    label={<FormattedMessage id="EMAIL" />}
                    type="email"
                    name="email"
                    value={email}
                    disabled={inProgress}
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                    error={email.length > 0 && !/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email)}
                />
                <TextField
                    type="password"
                    label={<FormattedMessage id="PASSWORD" />}
                    name="password"
                    value={password}
                    disabled={inProgress}
                    onChange={onChange}
                    fullWidth
                    margin="normal"
                    error={isNull(password)}
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        type="submit"
                        disabled={inProgress}
                        color="primary"
                        variant="contained"
                    >
                        {
                            inProgress ? (
                                <CircularProgress color="inherit" size="1.54rem" />
                            ) : (
                                <FormattedMessage id="SIGN_IN" />
                            )
                        }
                    </Button>
                </Box>
            </form>
            <div>
                <Button
                    onClick={() => togglePanel('reset')}
                    disabled={inProgress}
                    color="secondary"
                    size="small"
                >
                    <FormattedMessage id="FORGOT_PASSWORD" />
                </Button>
            </div>
        </div>
    );
}

SignInForm.propTypes = {
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    feedBackText: PropTypes.string,
    inProgress: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
};

SignInForm.defaultProps = {
    feedBackText: null,
    inProgress: null,
};

export default Login;
