import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useUser } from 'contexts/user';
import { useIntl } from 'react-intl';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles(() => createStyles({
    chip: {
        marginLeft: 'auto',
    },
}));

function UserChip(props) {
    const { name, isEmployee } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { user: { employee: employeeId } } = useUser();
    let chipValue = '';

    if (isEmployee) {
        if (employeeId) {
            chipValue = intl.formatMessage({ id: 'INTERNAL' });
        } else {
            chipValue = 'MELS';
        }
    }

    return (
        <>
            {`${name}${!chipValue ? '' : ' '}`}
            {
                !chipValue ? null : (
                    <Chip
                        size="small"
                        variant="outlined"
                        label={chipValue}
                        className={classes.chip}
                    />
                )
            }
        </>

    );
}

function UserChipAlt(props) {
    const {
        name, isEmployee, className,
        disabled, onDelete, tabIndex,
        ...ChipProps
    } = props;
    const intl = useIntl();
    const { user: { employee: employeeId }, user } = useUser();
    let chipValue = '';

    if (isEmployee) {
        if (employeeId) {
            chipValue = 'I';
        } else {
            chipValue = 'M';
        }
    }

    return (
        <Chip
            label={name}
            avatar={!chipValue ? undefined : <Avatar>{chipValue}</Avatar>}
            className={className}
            disabled={disabled}
            tabIndex={tabIndex}
            onDelete={onDelete}
            {...ChipProps}
        />

    );
}

export { UserChip, UserChipAlt };
