import React from 'react';

import { languageFromLocale } from 'utils/ofLocalization';
import { DEFAULT_LOCALE } from 'utils/constants';


const LocaleContext = React.createContext();
LocaleContext.displayName = "LocaleContext";

function useLocale() {
    const context = React.useContext(LocaleContext);
    if (!context) {
        throw new Error('useLocale must be used within a LocaleProvider');
    }

    const [locale, language, setState] = context;

    return {
        language,
        locale,
        setLanguage: (lang) => {
            const l = lang.toLowerCase();
            const loc = l.indexOf('fr') === 0 ? 'fr-CA' : 'en-CA';
            localStorage.setItem('locale', loc);
            window.recaptchaOptions = { lang: l };

            setState({
                locale: loc,
                language: l,
            });
        },
    };
}

function LocaleProvider(props) {
    const [state, setState] = React.useState({
        locale: localStorage.getItem('locale') || navigator.language || navigator.browserLanguage || DEFAULT_LOCALE,
        language: languageFromLocale(localStorage.getItem('locale') || navigator.language || navigator.browserLanguage || DEFAULT_LOCALE),
    });
    const { locale, language } = state;
    const value = React.useMemo(() => [locale, language, setState], [locale, language]);
    return <LocaleContext.Provider value={value} {...props} />;
}

export { LocaleProvider, useLocale, LocaleContext };
