import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedNumber } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import Table from 'components/Table/EnhancedPagedTable';

const ItemPropTypes = PropTypes.shape({
    lineNumber: PropTypes.number,
    description: PropTypes.string,
    rate: PropTypes.number,
    netCost: PropTypes.number,
    units: PropTypes.string,
    id: PropTypes.number,
    quantity: PropTypes.number,
});

const InvoicePropTypes = PropTypes.shape({
    paymentDueDate: PropTypes.number,
    month: PropTypes.number,
    currency: PropTypes.string,
    tax2Rate: PropTypes.number,
    year: PropTypes.number,
    total: PropTypes.number,
    id: PropTypes.number,
    tax1Rate: PropTypes.number,
    tax2: PropTypes.number,
    tax1: PropTypes.number,
    tax2Desc: PropTypes.string,
    items: PropTypes.arrayOf(ItemPropTypes),
    netTotal: PropTypes.number,
    invoiceDate: PropTypes.string,
    tax1Desc: PropTypes.string,
});

const propTypes = {
    clientId: PropTypes.string.isRequired,
    failed: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchPage: PropTypes.func.isRequired,
    filters: PropTypes.shape().isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pageRows: PropTypes.arrayOf(InvoicePropTypes).isRequired,
    totalRowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    exportButton: {
        paddingLeft: theme.spacing(2),
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    numeric: {
        whiteSpace: 'pre',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: "'Roboto Mono', monospace",
        margin: 'auto',
    },
}));

function InvoiceDate({ invoiceDate }) {
    return (!invoiceDate ? null : (
        <FormattedDate
            value={invoiceDate}
            timeZone="UTC"
            year="numeric"
            day="2-digit"
            month="long"
        />
    ));
}

function Invoices(props) {
    const {
        clientId, failed,
        // invoices, fetchingInvoices,
        isLoading, fetchPage, filters,
        setPage, page, pageRows,
        totalRowCount, order, orderBy,
        rowsPerPage, setRowsPerPage,
        setOrder, setOrderBy,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();


    const handleRowClick = React.useCallback(({ numeroFacture }) => {
        history.push({
            pathname: generatePath(match.path, { invoice: numeroFacture, client: clientId }),
            search: history.location.search,
        });
    }, [match.path, clientId, history.location.search]);



    const NetAmount = React.useCallback(({ netAmount, deviseFacture }) => {
        return (!netAmount ? null : (
            <FormattedNumber
                value={netAmount}
                currency={deviseFacture}
                style="currency"
                currencyDisplay="symbol"
            >
                {
                    (val) => <pre className={classes.numeric}>{val}</pre>
                }
            </FormattedNumber>
        ));
    }, [classes.numeric]);

    const TotalAmount = React.useCallback(({ totalAmount, deviseFacture }) => {
        return (!totalAmount ? null : (
            <FormattedNumber
                value={totalAmount}
                currency={deviseFacture}
                style="currency"
                currencyDisplay="symbol"
            >
                {
                    (val) => <pre className={classes.numeric}>{val}</pre>
                }
            </FormattedNumber>
        ));
    }, [classes.numeric]);

    return (
        <Paper className={classes.paper}>
            <Table
                size="small"
                showDivisions={false}
                isSortable
                isLoading={isLoading}
                columns={[
                    {
                        field: 'numeroFacture',
                        title: 'INVOICE_NUMBER',
                    },
                    {
                        field: 'invoiceDate',
                        title: 'DATE',
                        Component: InvoiceDate,
                        align: 'right',
                    },
                    {
                        field: 'poFacture',
                        title: 'PO_NUMBER',
                        align: 'right',
                    },
                    {
                        field: 'netAmount',
                        title: 'BEFORE_TAX',
                        align: 'right',
                        Component: NetAmount,
                    },
                    {
                        field: 'totalAmount',
                        title: 'AMOUNT',
                        align: 'right',
                        Component: TotalAmount,
                    },
                ]}
                onRowClick={handleRowClick}
                uniqueField="numeroFacture"
                emptyMessage="NOT_FOUND"
                fetchPage={fetchPage}
                fetchPageDependencies={[clientId, filters]}
                setPage={setPage}
                page={page}
                pageRows={pageRows}
                totalRowCount={totalRowCount}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </Paper>
    );
}

Invoices.propTypes = propTypes;

export default Invoices;
