import React from 'react';

import { FormattedMessage } from 'react-intl';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DateRangePicker from 'components/DateRangePicker';

import FormSelect from 'components/FormSelect';
import DatePicker from 'components/DatePicker';
import FileInput from 'components/FileInput';

import { useUser } from 'contexts/user';

import {
    fileDelivery, deliveryTypes, vaultService, catalogueElementService,
    closedCaptionService, videoDescriptionService, subtitleService, initialState,
    adaptationService, SECTIONS, PRODUCTS
} from './constants';

import AssetExpectedDate from './AssetExpectedDate';


const filterOptions = createFilterOptions();

function Destination(props) {
    const [isTypingAdvisory, setIsTypingAdvisory] = React.useState(false);

    const {
        selectedService, onChange, destination,
        currentServiceIndex, mainDestination,
        selectedAssets, assetList, assetsDescription,
        onReceptionChange, receptionDates=[], broadcasterOptions=[],
        showRatingAndAdvisory=false,
        ratings: ratingsUnfiltered=[], advisories: advisoriesUnfiltered=[],
        targetLanguage,
    } = props;
    const {
        sendTo, jointFile, deliveryClient, deliveryDate,
        deliveryInfoSupplied, externalRecipient, poNumber, notes,
        startDate, endDate, virtualVaultEntry, workFile, specFile,
        receptionDate, tvodsvod, section, rating, advisoryList,xmlInventoryId, xmlLineupId
    } = destination;

    const {
        hasResolutionRule, resolutionOptions, has3dRule, hasProductRule,
        productOptions, hasContentTypeRule, contentTypeOptions,
        idTypeDiffuseur, needXml, isContentAdvisoryRequired=false, hasInventoryId,
        hasLineupId,
    } = sendTo;


    const displayXmlFields = idTypeDiffuseur === 'VOD' && needXml === 1;
    const alternativeView = [adaptationService, subtitleService, videoDescriptionService, closedCaptionService].includes(selectedService) && mainDestination;

    const displayVirtualVaultOption = ![vaultService, catalogueElementService].includes(selectedService) && !alternativeView;
    const disableWorkFile = ![subtitleService, videoDescriptionService, closedCaptionService, adaptationService].includes(selectedService);

    const provideExpectedDate = receptionDates.length == 1;
    const ratings = sendTo.region
        ? ratingsUnfiltered.filter((item) => item.region == sendTo.region)
        : ratingsUnfiltered.filter((item, index, self) => index === self.findIndex((t) => (t.label === item.label)));

    const { user } = useUser();
    const userLang = user.language.toLowerCase();

    const advisories =
        advisoriesUnfiltered.filter((item) => item.idClientWeb === sendTo.idClient && item.language === (targetLanguage || userLang).substr(0, 2));

    function doucmentType(name) {
        let documentType = ''
        if (name === 'workFile') {
            if (selectedService === subtitleService) {
                documentType = 'WORK_SUBTITLING'
            } else if (selectedService === videoDescriptionService) {
                documentType = 'WORK_DESCRIBED_VIDEO'
            } else if (selectedService === closedCaptionService) {
                documentType = 'WORK_CC'
            }
        } else if (name === 'jointFile') {
            documentType = 'DELIVERY_SPECS'
        }

        return documentType
    }
    const handleDestinationChange = React.useCallback(({ target: { name, value, type, checked, files } }) => {
        if (onChange) {
            onChange((currentDestination) => {
                return currentDestination.map((dest, destIndex) => {
                    if (destIndex === currentServiceIndex) {
                        if (type === 'file') {
                            const docT = doucmentType(name)

                            let docMapping = {...dest.documentMapping}
                            files.forEach((file, fileIndex) => {

                                docMapping[`${destIndex}_${name}_${fileIndex}` ] = docT;
                            });
                            return {...dest, [name]: files, documentMapping: docMapping, specFile: name === 'jointFile' ? !isEmpty(files) : dest.specFile}

                        } else {
                            return {...dest, [name]: type === 'checkbox' ? checked : value }
                        }

                    } else {
                        return dest
                    }
                });
            });

        }
    }, [onChange, destination]);

    const handleClientChange = React.useCallback((event, newValue) => {
        const newDestination = {
            // ...initialState.destination,
            ...destination,
            section: '',
            tvodsvod: '',
            documentMapping: {},
            workFile: '',
            jointFile: ''
        };

        const externalClient = newValue && (newValue.inputValue || isString(newValue));
        if (!newValue) {
            newDestination.sendTo = {};
            newDestination.externalRecipient = false;
            newDestination.deliveryClient = '';
        } else if (externalClient) {
            // Create a new value from the user input
            newDestination.sendTo = { nom: newValue.inputValue || newValue };
            newDestination.externalRecipient = true;
            newDestination.deliveryClient = newValue.inputValue || newValue;
        } else {
            newDestination.sendTo = newValue || {};
            newDestination.externalRecipient = false;
            newDestination.deliveryClient = '';
        }
        onChange((currentDestination) => {
            return currentDestination.map((dest, index) => {
                if (index === currentServiceIndex) {
                    return newDestination
                } else {
                    return dest
                }
            });
        });
    }, [destination, currentServiceIndex]);

    const handleRatingChange = React.useCallback((event, newValue) => {
        onChange((currentDestination) => {
            return currentDestination.map((dest, index) =>
                (index === currentServiceIndex ? { ...dest, rating: newValue } : dest));
        });
    }, [destination, currentServiceIndex]);

    function handleTypingAdvisory({target: {value}}) { setIsTypingAdvisory(!!value); }

    const handleAdvisoryChange = React.useCallback((event, values) => {
        setIsTypingAdvisory(false);
        const textToAdvisoriesValues = values.map((adv) =>
            (typeof adv == "object") ? adv : advisories.find(({ label }, idx) => adv === label) || adv);
        const uniqueValues = textToAdvisoriesValues.filter((item, pos) => textToAdvisoriesValues.indexOf(item) == pos);

        onChange((currentDestination) => {
            return currentDestination.map((dest, index) =>
                (index === currentServiceIndex ? { ...dest, advisoryList: uniqueValues } : dest));
        });
    }, [destination, currentServiceIndex]);

    const advisoryLabelAndWarning = () => (isTypingAdvisory
        ? <Typography color="error"><FormattedMessage id="ADVISORY" /> (<FormattedMessage color="error" id="ADVISORY_PRESS_ENTER" />)</Typography>
        : <FormattedMessage id="ADVISORY"/>
    );

    return (
        <Grid style={{ marginTop: 0 }} container spacing={2} alignItems="center">
            {
                !provideExpectedDate ? null : (
                    <Grid item xs={12}>
                        <AssetExpectedDate
                            onChange={onReceptionChange}
                            receptionDates={receptionDates}
                        />
                    </Grid>
                )
            }

            {
                !mainDestination || selectedService !== vaultService && !alternativeView ? null : (
                    <Grid item xs={2.5}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        name="virtualVaultEntry"
                                        checked={alternativeView ? virtualVaultEntry : true}
                                        value="virtualVaultEntry"
                                        onChange={alternativeView ? handleDestinationChange : undefined}
                                        disabled={selectedService === vaultService || (alternativeView && !isEmpty(sendTo))}
                                    />
                                )}
                                label={<FormattedMessage id="VIRTUAL_VAULT" />}
                            />
                        </FormControl>
                    </Grid>
                )
            }

            {
                !alternativeView ? null : (
                    <Grid item xs={.5} sx={{textAlign: "center"}}>
                        <Typography>
                            <FormattedMessage id="OR" />
                        </Typography>
                    </Grid>
                )
            }
            {
                mainDestination && selectedService === vaultService ? null : (
                    <>
                        <Grid item xs={alternativeView ? 9 : 12}>
                            <Autocomplete
                                disabled={alternativeView && virtualVaultEntry}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={<FormattedMessage id="SEARCH_ADD_CLIENT" />}
                                    />
                                )}
                                fullWidth
                                name="sendTo"
                                // multiple={!externalRecipient}
                                freeSolo
                                clearOnBlur
                                selectOnFocus
                                options={broadcasterOptions}
                                value={sendTo}
                                getOptionLabel={(option) => (option.nom || (isString(option) ? option : ''))}
                                renderOption={(props, option) => {
                                    if (typeof option === 'string') {
                                        return (
                                            <li {...props}>
                                                {option}
                                            </li>
                                        );
                                    }

                                    if (option.inputValue) {
                                        return (
                                            <li {...props}>
                                                <span>
                                                    <FormattedMessage
                                                        id="NEW_DESTINATION_X"
                                                        values={{ name: <em>{option.inputValue}</em> }}
                                                    />
                                                </span>
                                            </li>
                                        );
                                    }

                                    return (
                                        <li {...props}>
                                            {option.nom}
                                        </li>
                                    );
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params);

                                    // Suggest the creation of a new value
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            nom: params.inputValue,
                                        });
                                    }

                                    return filtered;
                                }}
                                onChange={handleClientChange}
                            />
                        </Grid>
                    </>
                )
            }
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    name="poNumber"
                    label={<FormattedMessage id="PO_NUMBER" />}
                    value={poNumber}
                    onChange={handleDestinationChange}
                />
            </Grid>

            <Grid item xs={4}>
                <DatePicker
                    fullWidth
                    disableToolbar
                    disablePast
                    name="deliveryDate"
                    // format="yyyy-MM-dd"
                    value={deliveryDate}
                    onChange={handleDestinationChange}
                    label={<FormattedMessage id="ESTIMATED_DELIVERY_DATE" />}
                    required
                />
            </Grid>
            {
                !displayXmlFields ? null : (
                    <Grid item xs={5}>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            disableToolbar
                            startValue={startDate}
                            startName="startDate"
                            startLabel={<FormattedMessage id="AVAILABLE_FROM" />}
                            endName="endDate"
                            endValue={endDate}
                            endLabel={<FormattedMessage id="AVAILABLE_TO" />}
                            onChange={handleDestinationChange}
                        />
                    </Grid>
                )
            }
            {
                !displayXmlFields ? null : (
                    <Grid item xs={6}>
                        <FormSelect
                            fullWidth
                            label={<FormattedMessage id="SECTION" />}
                            name="section"
                            value={section}
                            onChange={handleDestinationChange}
                        >
                            {
                                SECTIONS.map(({value, label}, index) => (
                                    <MenuItem key={index} value={value}>
                                        <FormattedMessage id={label} />
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                !hasProductRule ? null : (
                    <Grid item xs={4}>
                        <FormSelect
                            clearable={productOptions.find((val) => val === null)}
                            fullWidth
                            label={<FormattedMessage id="TYPE" />}
                            name="tvodsvod"
                            required
                            value={tvodsvod}
                            onChange={handleDestinationChange}
                        >
                            {
                                productOptions.map((val, index) => (
                                    <MenuItem key={index} value={val}>
                                        <FormattedMessage id={val} />
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                disableWorkFile ? null : (
                    <Grid item xs={6}>
                        <FileInput
                            sx={{ display: 'flex' }}
                            name="workFile"
                            onChange={handleDestinationChange}
                            variant="contained"
                            fileList={workFile}
                            multiple
                            label={<FormattedMessage id="WORK_DOCUMENTS" />}
                        />
                    </Grid>
                )
            }
            {
                !externalRecipient ? null : (
                    <>
                        <Grid item xs={12}>
                            <FileInput
                                name="jointFile"
                                onChange={handleDestinationChange}
                                // variant="contained"
                                variant="text"
                                fileList={jointFile}
                                multiple
                                fullWidth={false}
                                clearable
                                // label={<FormattedMessage id="SPEC_FILE" />}
                                label={<FormattedMessage id="ADD_SPEC_FILE" />}
                            />
                        </Grid>
                        {
                            !jointFile ? null : (
                                    <Grid item xs={6} lg={12}>
                                        <FormControl fullWidth>
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        name="deliveryInfoSupplied"
                                                        checked={deliveryInfoSupplied}
                                                        onChange={handleDestinationChange}
                                                    />
                                                )}
                                                label={<FormattedMessage id="SPEC_FILE_CONTAIN_DELIVERY" />}
                                            />
                                        </FormControl>
                                    </Grid>

                            )
                        }
                    </>
                )
            }
            {!showRatingAndAdvisory ? null : (
                <Grid item xs={2}>
                    <Autocomplete
                        options={ratings || []}
                        getOptionLabel={(option) => (option && option.label) || rating || ''}
                        freeSolo
                        value={rating || ''}
                        inputValue={rating || ''}
                        onInputChange={handleRatingChange}
                        renderInput={(params) => (
                            <TextField {...params}
                                label={<span><FormattedMessage id="RATING" /> {sendTo.region ? <>({sendTo.region})</> : null} *</span>}
                            />
                        )}
                    />
                </Grid>
            )}
            {!showRatingAndAdvisory ? null : (
                <Grid item xs={6}>
                    <Autocomplete
                        options={advisories || []}
                        freeSolo
                        multiple
                        value={advisoryList || []}
                        onChange={handleAdvisoryChange}
                        onBlur={handleTypingAdvisory}
                        renderInput={(params) => (
                            <TextField {...params}
                                label={advisoryLabelAndWarning()}
                            />

                        )}
                    />
                </Grid>
            )}
            { hasLineupId === 'N' ?  null : (
                <Grid item xs={4}>
                <TextField
                    fullWidth
                    name="xmlLineupId"
                    required={hasLineupId === 'A'}
                    label={<FormattedMessage id="LINEUP_ID" />}
                    value={xmlLineupId}
                    onChange={handleDestinationChange}
                />
            </Grid>
            )
            }
            
            { hasInventoryId === 'N' ?  null : (
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        name="xmlInventoryId"
                        required={hasInventoryId === 'A'}
                        label={<FormattedMessage id="INVENTORY_ID" />}
                        value={xmlInventoryId}
                        onChange={handleDestinationChange}
                    />
                </Grid>
            )
            }
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="notes"
                    label={<FormattedMessage id="NOTES" />}
                    value={notes}
                    onChange={handleDestinationChange}
                />
            </Grid>
        </Grid>
    );
}

export default Destination;
