import React from 'react';

import { useConstants } from 'contexts/constants'
import EntityDetails from './CreationDetails';
import { useUser } from 'contexts/user';
import { isEmpty } from 'lodash';

const commonLocales = ['en', 'fr-ca', 'fr'];
const commonTypes = ['MOVIE', 'SERIES', 'SHORT', 'DOCU'];

function TitleCreation(props) {
    const {
        onChange, newTitle, selectedTitles,
        setSelectedTitles, setTitlesDescription,
        error, helperText
    } = props;

    const { user: { contact, language } } = useUser();
    const [displayLanguage, setDisplayLanguage] = React.useState(language ? language.toLowerCase() : 'fr');

    const { locales, entityTypes, countries, genres, isLoading, } = useConstants();

    const {
        title, originalLanguage, entityType,
        prodYear, countryOrigin, ratingBC,
        ratingON, ratingQC, runtime, studio,
        genre, actor, director,
        writer, summary, shortDesc, producer,
    } = newTitle;

    // if (!isEmpty(selectedTitles)) {
    //     setSelectedTitles([]);
    //     // onChange({ target: { name: 'selectedTitles', value: [] } });
    // }


    const handleChange = React.useCallback((name, value) => {
        onChange((currentTitle) => {
            return { ...currentTitle, [name]: value };
        });
        // onChange({ target: { value: { ...newTitle, [name]: value }, name: 'newTitle' } });
    }, [newTitle]);


    const handleChangeLocal = React.useCallback(({ target: { name, value } }) => {
        onChange((currentTitle) => {
            return { ...currentTitle, [name]: value };
        });
        // onChange({ target: { value: { ...newTitle, [name]: value }, name: 'newTitle' } });
    }, [newTitle]);

    const handleExternalTitle = React.useCallback((value) => {


        onChange((currentTitle) => {
            const originalTitle = value.TITLE.find(x => x.language === value.ORIGINAL_LANGUAGE);
            const externalTitle = {
                ...currentTitle,
                originalTitle: !isEmpty(value.TITLE) ? value.TITLE[0].value : undefined,
                prodYear: value.PROD_YEAR ? { value: value.PROD_YEAR, label: value.PROD_YEAR.toString()} : value.PROD_YEAR,
                title: value.TITLE,
                countryOrigin: value.COUNTRY_ORIGIN,
                originalLanguage: value.ORIGINAL_LANGUAGE ? { value: value.ORIGINAL_LANGUAGE, label: value.ORIGINAL_LANGUAGE } : value.ORIGINAL_LANGUAGE,
                ratingBC: value.RATING_BC,
                ratingON: value.RATING_ON,
                ratingQC: value.RATING_QC,
                runtime: value.RUNTIME,
                studio: value.STUDIO,
                genre: value.GENRE,
                actor: value.ACTORS,
                director: value.DIRECTOR,
                writer: value.WRITER,
                shortDesc: value.SHORT_DESC,
                summary: value.SUMMARY,
                producer: value.PRODUCER,
                originalPoster: value.ORIGINAL_POSTER,
                poster: value.poster,
            }
            if (originalTitle) {
                externalTitle.originalTitle = originalTitle.value;
            }

            return externalTitle;
        });



    }, [onChange, setTitlesDescription]);

    const handleLangChange = React.useCallback((event, newValue) => {
        setDisplayLanguage(newValue);
    }, []);

    const rootEntityTypes = entityTypes.filter((e) => ['SERIES', 'MOVIE', 'SHORT', 'DOCU'].includes(e.value));
    return (
        <EntityDetails
            externalTitle
            onExternalTitleSelect={handleExternalTitle}
            displayLanguage={displayLanguage}
            metadata={newTitle}
            editMode
            handleInputChange={handleChange}
            handleLangChange={handleLangChange}
            handleChangeLocal={handleChangeLocal}
            rootEntityTypes={rootEntityTypes}
            error={error}
            helperText={helperText}
        />
    );
}

export default TitleCreation;
