import React from 'react';

import { useUser } from 'contexts/user';
import { WSURL } from 'utils/constants';

const SocketContext = React.createContext();
SocketContext.displayName = "SocketContext";

function useSocketListener(channel, action, dependencies = []) {
    const context = React.useContext(SocketContext);

    if (!context) {
        throw new Error('useEvent must be used within a EventProvider');
    }

    const { websocket } = context;

    const callback = React.useCallback((event) => {
        const eventData = JSON.parse(event.data);
        if (eventData.channel === channel) {
            action(event);
        }
    }, [channel, action]);

    React.useEffect(() => {
        if (channel && action) {

            websocket().addEventListener('message', callback);

            return () => {
                console.log(`[${channel}]`, `removing message for ${channel} ${callback}`)
                websocket().removeEventListener('message', callback);
            }

            // return registerChannel(channel, callback);

//             const socket = registerChannel(channel);
//             socket.addEventListener('message', callback);
//
//             return () => {
//                 socket.removeEventListener('message', callback);
//             }
        }
        return undefined;
    }, [callback]);
}

function SocketProvider(props) {
    const { user, api, auth } = useUser();

    const socket = React.useRef();
    const token = auth.getIdToken();
    const [registeredChannels, setRegisteredChannels] = React.useState([]);

    function listener() {
        if (!socket.current) {
            console.log(`Opening socket for ${WSURL}`);
            const ws = new WebSocket(WSURL);
            ws.addEventListener('open', handleOpen);
            ws.addEventListener('close', handleClose);
            ws.addEventListener('error', handleError);
            ws.addEventListener('message', handleMessage);
            ws.addEventListener('keepalive', handleMessage);
            socket.current = ws;
        }

        return socket.current;
    }

    function registerChannel(channel, action) {
        const ws = listener();

        // setRegisteredChannels((currentRegisteredChannels) => {
        //     console.log(`[${channel}]`, 'registeredChannels', currentRegisteredChannels, currentRegisteredChannels.includes(channel), channel)
        //     if (!currentRegisteredChannels.includes(channel)) {
        //         const register = () => ws.send(JSON.stringify({ channel, token }))
        //         console.log(`[${channel}]`, ws, ws.readyState, ws === socket.current)
        //         if (ws.readyState !== 1) {
        //             ws.addEventListener('open', register)
        //         } else {
        //             register()
        //         }
        //         return [...currentRegisteredChannels, channel];
        //     }
        //     return currentRegisteredChannels;
        // });

        ws.addEventListener('message', action);

        return () => {
            console.log(`[${channel}]`, `removing message for ${channel} ${action}`)
            ws.removeEventListener('message', action);
        }
    }

    function handleClose(e) {
        console.debug("CLOSE", e);
    }

    function handleError(e) {
        console.debug("ERROR", e);
    }

    function handleOpen(e) {
        console.debug("OPEN", e);
        socket.current.send(token);
    }

    function handleMessage(e) {
        console.debug("MESSAGE", e);
    }

    React.useEffect(() => {
        return () => {
            console.debug("unmounting Socket", !!socket.current.close );
            socket.current.close && socket.current.close();
        }
    }, []);

    return <SocketContext.Provider value={{ websocket: listener }} {...props} />;
}

export { SocketProvider, SocketContext, useSocketListener };
