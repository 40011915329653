import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useLocale } from 'contexts/locale';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandCircleDownTwoTone';
import ExpandLessIcon from '@mui/icons-material/ExpandLessTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/EditTwoTone';
import ContentCopyIcon from '@mui/icons-material/CopyAllTwoTone';
import MoreIcon from '@mui/icons-material/MoreVertTwoTone';

import AssetDescription from './AssetDescription';
import FormSelect from 'components/FormSelect';
import RadioMulti from 'components/RadioMulti';
import { useConstants } from 'contexts/constants'
import { useUser } from 'contexts/user';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';

import {
    vaultService, adaptationService, subtitleService, emptyAsset,
    serviceAssetTypes, commonLocales, resolutionOptions, frameRateOption,
    entityTypeElementOptions, VERSIONS, versionsTrailer, commonTypes,
    ccOptions, NEVER, ALWAYS, IDK
} from './constants';

const useEntityDetailsStyles = makeStyles((theme) => createStyles({

    container: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    accordionFilter: {
        // justifyContent: 'center',
        // '& .MuiExpansionPanelSummary-root, & .MuiExpansionPanelDetails-root': {
        //     paddingLeft: 0,
        //     paddingRight: 0,
        // },
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        // '&:before': {
        //   display: 'none',
        // },
    },
    block: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    audioTracks: {
        marginBottom: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(2),
    },
    chips: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    titleTop: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    highlightEven: {
        '&:nth-child(2n)': {
            backgroundColor: theme.palette.action.selected
        }
    },
    listWrapper: {
        flexGrow: 1,
        flexBasis: 0,
        maxWidth: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    gridItem: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    loading: {
        height: '100%',
        width: '100%',
    }
}));


function AssetListItem(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
        assetType, tvDefinition, assetLanguage, translationTypes,
        frameRate, ratio, runTime, element,
        version, season, episode, seasonTitle,
        partNumber, episodeTitle, audioTrack,
        duplicateElement, deleteElement, setActiveIndex,
        activeIndex, assetIndex, deleteDisabled,
        assetTypes, ratioImage, className, isPartial
    } = props;


    const intl = useIntl();

    const { locales, vodOptions: { assetTypeOptions, translationTypeOptions, audioTrackTypeOptions } } = useConstants();
    const { language } = useLocale();

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDuplicateElement = React.useCallback((event) => {
        event.stopPropagation();
        setAnchorEl(null);
        duplicateElement(assetIndex);
    }, []);

    const handleDeleteElement = React.useCallback((event) => {
        event.stopPropagation();
        setAnchorEl(null);
        deleteElement(assetIndex);
    }, []);

    const handleActiveElement = React.useCallback(() => {
        setActiveIndex(assetIndex);
    }, []);


    return (
        <ListItem
            disablePadding
            selected={assetIndex === activeIndex}
            onClick={handleActiveElement}
            className={className}
            secondaryAction={
                <>
                    <IconButton onClick={handleClick}>
                        <MoreIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem  onClick={handleDuplicateElement}>
                            <ListItemIcon>
                                <ContentCopyIcon color="secondary" sx={{ transform: 'rotateX(180deg)' }} />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage id="DUPLICATE" />
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            disabled={deleteDisabled}
                            onClick={handleDeleteElement}
                        >
                            <ListItemIcon>
                                <DeleteIcon color="error" />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage id="REMOVE" />
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </>
            }
        >
            <ListItemButton>
                <ListItemText
                    primary={(
                        AssetDescription(
                            {
                                defaultDescription: intl.formatMessage({id: 'UNDEFINED_ASSET'}), assetIndex: assetIndex, assetType: assetType, tvDefinition: tvDefinition,
                                assetLanguage: assetLanguage, translationTypes: translationTypes, frameRate: frameRate, ratio: ratio,
                                runTime: runTime, element: element, season: season, episode: episode,
                                partNumber: partNumber, assetTypes: assetTypes, ratioImage: ratioImage, seasonTitle: seasonTitle,
                                episodeTitle: episodeTitle, audioTrack: audioTrack, version: version, isPartial: isPartial,
                            },
                            intl, language, locales, assetTypeOptions, translationTypeOptions, audioTrackTypeOptions
                        )
                    )}
                />
            </ListItemButton>
        </ListItem>
    );
}


function AssetCreation(props) {
    const {
        onChange, newTitle,
        assetList: newAssets, selectedTitles, entity,
        selectedService, selectedAssets, invalidAssets,
        allowCreation,
    } = props;

    const { api } = useUser();
    const { client } = useParams();

    const { isLoading: fetchingAssetOptions, locales, vodOptions: {
        translationTypeOptions, ratioImage, audioTrackTypeOptions,
        audioContenTypeOptions, assetTypeOptions: assetTypeConstants,
        entityTypeOptions: entityTypeConstants
    } } = useConstants();



    const [assetList, setAssetList] = React.useState(isEmpty(newAssets) ? [emptyAsset] : newAssets);
    const [activeIndex, setActiveIndex] = React.useState(assetList[0].assetIndex);
    const classes = useEntityDetailsStyles();
    // const disableActionBotton = [adaptationService, subtitleService].includes(selectedService);

    let entityType = null;
    if (newTitle.entityType) {
        entityType = newTitle.entityType.value
    } else if (entity && entity.entityType){
        entityType = entity.entityType
    }

    const elementOptions = entityTypeElementOptions(entityType, entityTypeConstants);
    const assetTypes = serviceAssetTypes(selectedService, assetTypeConstants);

    const handleAssetChange = React.useCallback((assetIndex, name, value ) => {
        setAssetList((currentAssets) => {
            const newAssets = currentAssets.map((asset) => {
                if (asset.assetIndex === assetIndex) {
                    return {...asset, [name]: value }
                } else {
                    return asset
                }
            });
            return newAssets;
        });
    }, []);

    const addElement = React.useCallback(() => {
        setAssetList((currentAssets) => {
            const newIndex = (new Date()).valueOf();
            const newAssets = [...currentAssets, { ...emptyAsset, assetIndex: newIndex }];
            setActiveIndex(newIndex);
            return newAssets;
        });
    },[]);

    const handleAssetAudio = React.useCallback((assetIndex, audioL ) => {
        setAssetList((currentAssets) => {
            const newAssets = currentAssets.map((asset) => {
                if (asset.assetIndex === assetIndex) {
                    return { ...asset, audioTrack: [ ...asset.audioTrack, { ...audioL, audioIndex: (new Date()).valueOf() } ] };
                } else {
                    return asset;
                }
            });
            return newAssets;
        });
    }, []);

    const duplicateElement = React.useCallback((assetIndex) => {
        setAssetList((currentAssets) => {
            const newIndex = (new Date()).valueOf();
            const toDuplicate = currentAssets.find((asset) => asset.assetIndex === assetIndex);
            const newAssets = [ ...currentAssets, { ...toDuplicate, assetIndex: newIndex } ];
            setActiveIndex(newIndex);
            return newAssets;
        });

    }, []);

    const deleteElement = React.useCallback((assetIndex) => {
        setAssetList((currentAssets) => {
            const newAssets = currentAssets.filter((asset) => asset.assetIndex !== assetIndex);
            if (newAssets.length >= 1) {
                setActiveIndex(newAssets[newAssets.length - 1].assetIndex);
            }
            return newAssets;
        });
    },[]);

    const deleteAudioTrack = React.useCallback((assetIndex, audioIndex) => {
        setAssetList((currentAssets) => {
            const newAssets = currentAssets.map((asset) => {
                if (asset.assetIndex === assetIndex) {
                    return { ...asset, audioTrack: asset.audioTrack.filter((audio) => audio.audioIndex !== audioIndex) }
                } else {
                    return asset
                }
            });
            return newAssets;
        });
    }, []);


    React.useEffect(() => {
        if (isEmpty(assetList)) {

        }
        if (selectedService === adaptationService) {
            handleAssetChange(0,"assetType", 'VIDEO')
        } else if (selectedService === subtitleService) {
            handleAssetChange(0,"assetType", 'SUBTITLE')
        }
    }, [])


    let activeElement = activeIndex ? assetList.find((asset) => asset.assetIndex === activeIndex) : {};

    if (!allowCreation) {
        activeElement = assetList[0];
    }
    React.useEffect(() => {
        onChange(assetList);
    }, [assetList]);

    React.useEffect(() => {
        if (!allowCreation) {
            return null;
        }
        setAssetList((currentAssets) => {
            const newAssets = currentAssets.map((asset) => {
                if (asset.assetIndex === activeElement.assetIndex) {
                    const {assetType: activeAssetType, element: currentElement, } = asset;

                    const assetReset = {...asset};
                    const assetOption = assetTypes.find((a) => a.assetTypeId === activeAssetType);

                    if (currentElement !== 'PART') {
                        assetReset.partNumber = emptyAsset.partNumber;
                    }
                    if (!['OTHER_VERSION', 'TRAILER', 'PREVIEW'].includes(currentElement)) {
                        assetReset.version = emptyAsset.version;
                    }

                    if (entityType !== 'SERIES') {
                        assetReset.season = emptyAsset.season;
                        assetReset.seasonTitle = emptyAsset.seasonTitle;
                        assetReset.episode = emptyAsset.episode;
                        assetReset.episodeTitle = emptyAsset.episodeTitle;
                    }

                    if (!assetOption || assetOption.hasLocale === NEVER) {
                        assetReset.assetLanguage = emptyAsset.assetLanguage;
                    }
                    if (!assetOption || assetOption.hasTranslation === NEVER) {
                        assetReset.translationTypes = emptyAsset.translationTypes;
                    }
                    if (!assetOption || assetOption.hasFramerate === NEVER) {
                        assetReset.frameRate = emptyAsset.frameRate;
                    }
                    if (!assetOption || assetOption.hasDefinition === NEVER) {
                        assetReset.tvDefinition = emptyAsset.tvDefinition;
                    }
                    if (!assetOption || assetOption.hasRatio === NEVER) {
                        assetReset.ratio = emptyAsset.ratio;
                    }
                    if (!assetOption || assetOption.hasAudio === NEVER) {
                        assetReset.audioTrack = emptyAsset.audioTrack;
                    }

                    return assetReset;
                } else {
                    return asset
                }
            });
            return newAssets;
        });

        return () => {};

    }, [activeElement.assetType, activeElement.element, entityType])


    if (fetchingAssetOptions || !entityType) {
        return <Loading className={classes.loading} />;
    }
    if (isEmpty(assetTypes) && !fetchingAssetOptions) {
        return <NotFound />;
    }

    return (
        <>
            <Grid container spacing={2} sx={!allowCreation ? {} : { minHeight: '30vh' }}>
                {
                    !allowCreation ? null : (
                        <Grid item xs={4} container direction="column">
                            <div className={classes.gridItem}>
                                <Button onClick={addElement} variant="contained" color="primary">
                                    <FormattedMessage id="ADD_ELEMENT" />
                                </Button>
                            </div>
                            <div className={clsx(classes.listWrapper, classes.gridItem)}>
                                <List>
                                    {
                                        assetList.map(({
                                            assetType, tvDefinition, assetLanguage, translationTypes,
                                            frameRate, ratio, runTime, element,
                                            version, season, episode, seasonTitle,
                                            partNumber, episodeTitle, audioTrack,
                                            assetIndex, isPartial,
                                        }) => (
                                            <AssetListItem
                                                key={assetIndex}
                                                assetIndex={assetIndex}
                                                invalid={invalidAssets.includes(assetIndex)}

                                                duplicateElement={duplicateElement}
                                                deleteElement={deleteElement}
                                                setActiveIndex={setActiveIndex}
                                                activeIndex={activeIndex}
                                                deleteDisabled={assetList.length === 1}

                                                assetType={assetType}
                                                tvDefinition={tvDefinition}
                                                assetLanguage={assetLanguage}
                                                translationTypes={translationTypes}
                                                frameRate={frameRate}
                                                ratio={ratio}
                                                runTime={runTime}
                                                element={element}
                                                version={version}
                                                season={season}
                                                episode={episode}
                                                seasonTitle={seasonTitle}
                                                partNumber={partNumber}
                                                episodeTitle={episodeTitle}
                                                audioTrack={audioTrack}
                                                assetTypes={assetTypes}
                                                ratioImage={ratioImage}
                                                isPartial={isPartial}
                                            />
                                        ))
                                    }
                                </List>
                            </div>
                        </Grid>
                    )
                }
                <Grid item xs={allowCreation ? 8 : 12}>
                    {
                        !activeElement ? null : (
                            <ElementBlock
                                allowCreation={allowCreation}

                                assetIndex={activeIndex}
                                className={classes.gridItem}
                                assetType={activeElement.assetType}
                                tvDefinition={activeElement.tvDefinition}
                                assetLanguage={activeElement.assetLanguage}
                                translationTypes={activeElement.translationTypes}
                                frameRate={activeElement.frameRate}
                                ratio={activeElement.ratio}
                                runTime={activeElement.runTime}
                                element={activeElement.element}
                                season={activeElement.season}
                                episode={activeElement.episode}
                                partNumber={activeElement.partNumber}
                                seasonTitle={activeElement.seasonTitle}
                                episodeTitle={activeElement.episodeTitle}
                                audioTrack={activeElement.audioTrack}
                                version={activeElement.version}
                                isPartial={activeElement.isPartial}

                                audioTrackTypeOptions={audioTrackTypeOptions}
                                audioContenTypeOptions={audioContenTypeOptions}
                                elementOptions={elementOptions}
                                assetTypes={assetTypes}
                                frameRateOption={frameRateOption}
                                resolutionOptions={resolutionOptions}
                                ratioImage={ratioImage}
                                translationTypeOptions={translationTypeOptions}
                                entityType={entityType}
                                // duplicateElement={duplicateElement}
                                deleteAudioTrack={deleteAudioTrack}
                                handleAssetAudio={handleAssetAudio}
                                // deleteElement={deleteElement}
                                // resetElement={resetElement}
                                onChange={handleAssetChange}
                                // disableActionBotton={disableActionBotton}
                                // disableDelete={assetList.length === 1}
                                selectedService={selectedService}
                            />
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}


function AudioBlock (props) {
    const {
        audioTrackTypeOptions, audioContenTypeOptions, handleAudio,
        assetIndex, handleAssetAudio, deleteAudioTrack, audioTrack,
    } = props;


    const emptyAudio = {
        'language' : '',
        'audioTrackType' : '',
        'audioContenType' : '',
    }

    const { locales } = useConstants();
    const classes = useEntityDetailsStyles();
    const [audioL, setAudioL] = React.useState(emptyAudio);
    const { audioContenType, language, audioTrackType } = audioL;

    const audioValid = audioContenType && audioTrackType && (['MUSIC', 'M&E'].includes(audioContenType) || language)

    const handleChange = React.useCallback(({ target : { name, value } }) => {
        setAudioL((currentAudio) => {
            return { ...currentAudio,[name]: value };
        });
    }, []);

    const handleAddAudio = React.useCallback(() => {
        handleAssetAudio(assetIndex, audioL);
        setAudioL((currentAudio) => {
            return emptyAudio
        });
    }, [audioL, assetIndex]);

    const handleDelete = React.useCallback(( value) => {
        deleteAudioTrack(assetIndex, value)
    }, [assetIndex]);


    React.useEffect(() => {
        if (['MUSIC', 'M&E'].includes(audioContenType)) {
            setAudioL((currentAudio) => {
                return { ...currentAudio, language: null };
            })
        }
    }, [audioContenType])

    return (
        <Grid item xs={12} spacing={1} container >
            <Grid item xs={12} className={classes.title}>
                <FormattedMessage id="AUDIO_TRACK_S" />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="audioContenType"
                    fullWidth
                    label={<FormattedMessage id="AUDIO_CONTENT_TYPE" />}
                    value={audioL.audioContenType}
                    onChange={handleChange}
                >
                    {
                        audioContenTypeOptions.map(({ contentId, description } ) => (
                            <MenuItem value={contentId} key={contentId}>
                                {description}
                            </MenuItem>
                        ))
                    }
                </FormSelect>
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name="audioTrackType"
                    fullWidth
                    label={<FormattedMessage id="AUDIO_TRACKS" />}
                    value={audioL.audioTrackType}
                    onChange={handleChange}
                >
                    {
                        audioTrackTypeOptions.map(({ trackTypeId, description} ) => (
                            <MenuItem value={trackTypeId} key={trackTypeId}>
                                {description}
                            </MenuItem>
                        ))
                    }
                </FormSelect>
            </Grid>
            {
                ['MUSIC', 'M&E'].includes(audioContenType) ? null : (
                    <Grid item>
                        <RadioMulti
                            row
                            label={<FormattedMessage id="LANGUAGE" />}
                            fullWidth
                            size="small"
                            name="language"
                            value={audioL.language}
                            onChange={handleChange}
                            commonValues={commonLocales}
                            options={locales}
                            disabled={['MUSIC', 'M&E'].includes(audioContenType)}
                        />
                    </Grid>
                )
            }
            <Grid item sx={{ display: 'inline-flex', justifyContent: 'flex-end', alignSelf: 'flex-end', flexGrow: '1' }}>
                <Button
                    onClick={handleAddAudio}
                    color="primary"
                    disabled={!audioValid}
                >
                    <FormattedMessage id="CREATE_TRACK"/>
                </Button>
            </Grid>
            {
                isEmpty(audioTrack) ? null : (
                    <Grid item xs={12} className={classes.audioTracks}>
                        <List>
                            {
                                audioTrack.map(({ language, audioContenType, audioTrackType, audioIndex}) => (
                                    <ListItem
                                        key={audioIndex}
                                        className={classes.highlightEven}
                                        secondaryAction={
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleDelete(audioIndex)}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={
                                                <>

                                                    <Stack direction="row" spacing={1}>
                                                        <Typography>
                                                            <FormattedMessage id={audioContenType} />
                                                        </Typography>
                                                        {
                                                            !language ? null : (
                                                                <Chip
                                                                    size="small"
                                                                    label={locales.find(({value}) => value === language).label || language}
                                                                />
                                                            )
                                                        }
                                                        <Chip
                                                            size="small"
                                                            label={audioTrackTypeOptions.find(({trackTypeId}) => trackTypeId === audioTrackType).description || audioTrackType}
                                                        />
                                                    </Stack>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Grid>
                )
            }
        </Grid>
    );
}

function ElementBlock (props) {
    const {
        onChange, assetIndex, assetType, tvDefinition, assetLanguage,
        translationTypes, frameRate, ratio, runTime, element, version, season, episode,
        elementOptions, assetTypes, frameRateOption,partNumber,
        resolutionOptions, ratioImage, translationTypeOptions,
        deleteElement, duplicateElement, seasonTitle, episodeTitle,
        entityType, handleAudio,audioTrack,
        audioTrackTypeOptions, audioContenTypeOptions,handleAssetAudio,
        deleteAudioTrack, disableActionBotton, disableDelete, resetElement,
        className, selectedService, allowCreation, isPartial,
    } = props;

    const { locales } = useConstants();
    const classes = useEntityDetailsStyles();
    const assetOption = assetTypes.find((a) => a.assetTypeId === assetType);

    const handleChange = React.useCallback(({ target : { name, value, inputMode, type, checked } }) => {

        if (inputMode === 'numeric') {
            value = value.replace(/\D/g, "")
        }

        let val = value === IDK ? null : value
        val = type === 'checkbox' ? checked ? 1 : 0 : val

        onChange(assetIndex, name, val);
    }, [assetIndex]);

    return (
        <Grid container spacing={2} alignItems="flex-end" className={className}>
            {
                !allowCreation ? null : (
                    <>
                        <Grid item xs={12} className={classes.titleTop}>
                            <FormattedMessage id="GENERAL_INFO" />
                        </Grid>
                        <Grid item xs={6} >
                            <FormSelect
                                name="element"
                                id={assetIndex}
                                fullWidth
                                label={<FormattedMessage id="ELEMENT" />}
                                value={element}
                                onChange={handleChange}
                                required
                            >
                                {
                                    elementOptions.map(( value ) => (
                                        <MenuItem value={value} key={value}>
                                            <FormattedMessage id={value} />

                                        </MenuItem>
                                    ))
                                }
                            </FormSelect>
                        </Grid>
                        {
                            element !== 'PART' ? null : (
                                <Grid item xs={2}>
                                    <TextField
                                        name="partNumber"
                                        xs={1}
                                        value={partNumber}
                                        inputProps={{inputMode: "numeric"}}
                                        onChange={handleChange}
                                        label={<FormattedMessage id="PART_NUMBER" />}
                                    />
                                </Grid>
                            )
                        }
                        {
                            element !== 'OTHER_VERSION' ? null : (
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="version"
                                        id={assetIndex}
                                        fullWidth
                                        label={<FormattedMessage id="VERSION" />}
                                        value={version}
                                        onChange={handleChange}
                                        required
                                    >
                                        {
                                            VERSIONS.map(( value ) => (
                                                <MenuItem value={value} key={value}>
                                                    <FormattedMessage id={value} />

                                                </MenuItem>
                                            ))
                                        }
                                    </FormSelect>
                                </Grid>
                            )
                        }
                        {
                            element !== 'TRAILER' ? null : (
                                <Grid item xs={6}>
                                    <FormSelect
                                        name="version"
                                        id={assetIndex}
                                        fullWidth
                                        label={<FormattedMessage id="VERSION" />}
                                        value={version}
                                        onChange={handleChange}
                                        required
                                    >
                                        {
                                            versionsTrailer.map(( value ) => (
                                                <MenuItem value={value} key={value}>
                                                    <FormattedMessage id={value} />

                                                </MenuItem>
                                            ))
                                        }
                                    </FormSelect>
                                </Grid>
                            )
                        }
                        {
                            element !== 'PREVIEW' ? null : (
                                <Grid item xs={6}>
                                    <TextField
                                        name="version"
                                        id={toString(assetIndex)}
                                        fullWidth
                                        label={<FormattedMessage id="VERSION" />}
                                        value={version}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                            )
                        }
                        {
                            entityType !== 'SERIES' ? null : (
                                <>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={2}>
                                            <TextField
                                                name="season"
                                                xs={1}
                                                disabled={!['TRAILER', 'EPISODE'].includes(element)}
                                                value={season}
                                                onChange={handleChange}
                                                label={<FormattedMessage id="SEASON_NUMBER" />}
                                                required={element === 'EPISODE' }
                                                inputProps={{inputMode: "numeric"}}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextField
                                                name="seasonTitle"
                                                xs={1}
                                                disabled={!['TRAILER', 'EPISODE'].includes(element)}
                                                value={seasonTitle}
                                                onChange={handleChange}
                                                label={<FormattedMessage id="SEASON_TITLE" />}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                name="episode"
                                                xs={1}
                                                disabled={!['TRAILER', 'EPISODE'].includes(element)}
                                                value={episode}
                                                onChange={handleChange}
                                                label={<FormattedMessage id="EPISODE_NUMBER" />}
                                                required={element === 'EPISODE' }
                                                inputProps={{inputMode: "numeric"}}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextField
                                                name="episodeTitle"
                                                xs={1}
                                                disabled={!['TRAILER', 'EPISODE'].includes(element)}
                                                value={episodeTitle}
                                                onChange={handleChange}
                                                label={<FormattedMessage id="EPISODE_TITLE" />}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                        <Grid item xs={4}>
                            <FormSelect
                                name="assetType"
                                fullWidth
                                label={<FormattedMessage id="ASSET_TYPE" />}
                                value={assetType}
                                onChange={handleChange}
                                required
                            >
                                {
                                    assetTypes.map(({ assetTypeId, description }) => (
                                        <MenuItem value={assetTypeId} key={assetTypeId}>
                                            {description}
                                        </MenuItem>
                                    ))
                                }
                            </FormSelect>
                        </Grid>
                    </>
               )
            }
            {
                !assetOption || assetOption.hasLocale === NEVER ? null : (
                    <Grid item xs={8}>
                        <RadioMulti
                            row
                            label={<FormattedMessage id={["VIDEO","PACKAGE"].includes(assetType) ? "VISUAL_LANGUAGE" : "LANGUAGE"} />}
                            fullWidth
                            size="small"
                            name="assetLanguage"
                            value={assetLanguage}
                            onChange={handleChange}
                            commonValues={commonLocales}
                            options={locales}
                            required={assetOption && assetOption.hasLocale === ALWAYS}
                        />
                    </Grid>
                )
            }
            {
                !assetOption || assetOption.hasPartial === NEVER ? null : (
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    name="isPartial"
                                    checked={!!isPartial}
                                    onChange={handleChange}
                                    value="isPartial"
                                />
                            )}
                            label={<FormattedMessage id="IS_PARTIAL" />}
                        />
                    </Grid>
                )
            }
            {
                !assetOption || assetOption.hasTranslation === NEVER ? null : (
                    <Grid item xs={6}>
                        <FormSelect
                            name="translationTypes"
                            fullWidth
                            label={<FormattedMessage id="TRANSLATION_TYPE" />}
                            value={translationTypes}
                            onChange={handleChange}
                            required={assetOption && assetOption.hasTranslation === ALWAYS}
                        >
                            {
                                translationTypeOptions.map(({ translationTypeId, description } ) => (
                                    <MenuItem value={translationTypeId} key={translationTypeId}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                !assetOption || assetOption.hasFramerate === NEVER ? null : (
                    <Grid item xs={4}>
                        <FormSelect
                            name="frameRate"
                            fullWidth
                            label={<FormattedMessage id="FRAME" />}
                            value={frameRate === null ? IDK : frameRate}
                            onChange={handleChange}
                            required
                        >
                            {
                                frameRateOption.map((value) => (
                                    <MenuItem value={value} key={value}>
                                        {value === IDK ? <FormattedMessage id={IDK} />: value}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                !assetOption || assetOption.hasDefinition === NEVER ? null : (
                    <Grid item xs={3}>
                        <FormSelect
                            name="tvDefinition"
                            fullWidth
                            label={<FormattedMessage id="RESOLUTION" />}
                            value={tvDefinition === null ? IDK : tvDefinition}
                            onChange={handleChange}
                            required={assetOption && assetOption.hasDefinition === ALWAYS}
                        >
                            {
                                resolutionOptions.map((value) => (
                                    <MenuItem value={value} key={value}>
                                       {value === IDK? <FormattedMessage id={value} />: value}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                !assetOption || assetOption.hasRatio === NEVER ? null : (
                    <Grid item xs={3}>
                        <FormSelect
                            name="ratio"
                            fullWidth
                            label={<FormattedMessage id="RATIO" />}
                            value={ratio === null ? IDK : ratio}
                            onChange={handleChange}
                            required={assetOption && assetOption.hasRatio === ALWAYS}
                        >
                            {
                                [...ratioImage, { ratio: IDK }].map(({ ratio: ratioOption }) => (
                                    <MenuItem value={ratioOption} key={ratioOption}>
                                        {ratioOption === IDK? <FormattedMessage id={ratioOption} />: ratioOption}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )
            }
            {
                !assetOption || assetOption.hasAudio  === NEVER ? null : (
                    <AudioBlock
                        assetIndex={assetIndex}
                        audioTrackTypeOptions={audioTrackTypeOptions}
                        audioContenTypeOptions={audioContenTypeOptions}
                        handleAudio={handleAudio}
                        handleAssetAudio={handleAssetAudio}
                        audioTrack={audioTrack}
                        deleteAudioTrack={deleteAudioTrack}
                    />
                )
            }
        </Grid>
    );
}



export default AssetCreation;
