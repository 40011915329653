import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
    useRouteMatch, generatePath, useParams, useHistory,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RouteLink from 'components/RouteLink';
import { useUser } from 'contexts/user';
import NotFound from 'components/NotFound';

const propTypes = {
};

function PhysicalVaultDetails() {
    const history = useHistory();
    const match = useRouteMatch();
    const { client, objectId } = useParams();
    const { api } = useUser();
    const [fetchingObject, setFetchingObject] = React.useState(false);
    const [objectDetails, setObjectDetails] = React.useState({});
    const [failed, setFailed] = React.useState(false);

    function closeModal() {
        history.push({
            pathname: generatePath(match.path, { client }),
            search: history.location.search,
        });
    }

    async function fetchObject(abortController = new AbortController()) {
        if (client && objectId) {
            try {
                setFailed(false);
                setFetchingObject(true);

                const response = await api.get(`/physical-vault/${client}/${objectId}`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setObjectDetails(data);
                } else {
                    setFailed(true);
                    setObjectDetails({});
                }
                setFetchingObject(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (objectId && parseInt(objectId, 10) !== objectDetails.objectId) {
            fetchObject(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [client, objectId]);

    const {
        barcode, notes, serviceCenter, title,
        title2, clientCode, btNumber, filmingDate,
        inventoryDate, objectType, format, status,
        description,
    } = objectDetails;

    let content = null;

    if (fetchingObject) {
        content = (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    } else if (failed) {
        content = (
            <DialogContent>
                <NotFound message="NOT_FOUND" />
            </DialogContent>
        );
    } else if (parseInt(objectId, 10) === objectDetails.objectId) {
        content = (
            <>
                <DialogTitle>
                    {title}
                    {
                        !title2 ? null : (
                            <Typography color="textSecondary" gutterBottom>
                                {title2}
                            </Typography>
                        )
                    }
                </DialogTitle>
                <DialogContent>
                    <Grid container wrap="wrap" spacing={2}>
                        <Grid item xs={12} align="right">
                            <Typography
                                variant="h6"
                            >
                                { status }
                            </Typography>
                            {
                                !inventoryDate ? false : (
                                    <Typography color="textSecondary" gutterBottom>
                                        <FormattedDate
                                            value={inventoryDate}
                                            timeZone="UTC"
                                            year="numeric"
                                            day="numeric"
                                            month="short"
                                        />
                                    </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            {
                                !barcode ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="BARCODE" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            { barcode }
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !clientCode ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="CLIENT_CODE" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            { clientCode }
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !btNumber ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="BT_NUMBER" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            { btNumber }
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !serviceCenter ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="SERVICE_CENTER" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            { serviceCenter }
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            {
                                !format ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="FORMAT" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            { format }
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !description ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="OBJECT_TYPE" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            { description }
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                !filmingDate ? null : (
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="FILMING_DATE" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            <FormattedDate
                                                value={filmingDate}
                                                timeZone="UTC"
                                                year="numeric"
                                                day="numeric"
                                                month="short"
                                            />
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                        {
                            !notes ? null : (
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" gutterBottom>
                                        <FormattedMessage id="NOTES" />
                                    </Typography>
                                    <Typography color="textPrimary">
                                        { notes }
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        underline="none"
                        component={RouteLink}
                        to={{
                            pathname: generatePath(match.path, { client }),
                            search: history.location.search,
                        }}
                        color="primary"
                    >
                        <FormattedMessage id="CLOSE" />
                    </Button>

                </DialogActions>
            </>
        );
    } else {
        content = <DialogContent/>;
    }

    return (
        <Dialog
            open={!!objectId}
            onClose={closeModal}
        >
            {content}
        </Dialog>
    );
}

PhysicalVaultDetails.propTypes = propTypes;

export default PhysicalVaultDetails;
