import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

const NotificationContext = React.createContext();
NotificationContext.displayName = "NotificationContext";

function useNotification() {
    const context = React.useContext(NotificationContext);

    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snackId, setSnackId] = React.useState(null);
    const { notificationsEnabled } = context;

    function closeSnack() {
        if (snackId) {
            closeSnackbar(snackId);
        }
    }

    const pushNotification = React.useCallback((text, Icon, title, actions = [], data = {}, requireInteraction = false) => {
        if (notificationsEnabled) {
            const notificationActions = actions.filter(({ action }) => action !== 'default').map(({ action, title: t }) => ({ action, title: t }));
            const defaultAction = actions.find(({ action }) => action === 'default');
            const notification = new Notification(title, {
                body: text,
                badge: '/assets/favicon-32x32.png',
                // icon: Icon,
                actions: notificationActions,
                data,
                requireInteraction,
            });
            if (actions.length > 0) {
                notification.addEventListener('click', (event) => {
                    notification.close();
                    let found = false;
                    actions.forEach(({ action, callback }) => {
                        if (action === event.action && callback) {
                            found = true;
                            callback(event, data);
                        }
                    });
                    if (!found && defaultAction && defaultAction.callback) {
                        defaultAction.callback(event, data);
                    }
                });
            }
            return { isNotification: true, reference: notification, close: notification.close };
        }

        const snackActions = actions.map(({ action, title: t, callback, ...buttonProps }) => (
            <Button
                color="inherit"
                onClick={(event) => {
                    closeSnack();
                    if (callback) {
                        callback(event, data);
                    }
                }}
                value={action}
                {...buttonProps}
            >
                {t}
            </Button>
        ));
        setSnackId(enqueueSnackbar(
            (
                <div>
                    <Typography marginBottom={false} variant="body2">{title}</Typography>
                    <Typography marginBottom={false}>{text}</Typography>
                </div>
            ),
            {
                variant: 'default',
                persist: requireInteraction,
                action: snackActions,
            }
        ));
        return { reference: snackId, isSnack: true, close: closeSnack };
    }, [notificationsEnabled]);

    return {
        pushNotification, notificationsEnabled,
    };
}

function NotificationProvider(props) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [notificationsEnabled, setNotificationsEnabled] = React.useState(Notification.permission === 'granted');

    const notificationUserChoice = localStorage.getItem('notificationUserChoice');

    async function requestPermission() {
        setNotificationsEnabled((await Notification.requestPermission()) === 'granted');
    }

    React.useEffect(() => {
        if (!notificationsEnabled && notificationUserChoice !== 'false') {
            const snackId = enqueueSnackbar(<FormattedMessage id="EXPLAIN_NOTIFICATIONS" />, {
                variant: 'info',
                persist: true,
                action: ([
                    <Button
                        color="inherit"
                        onClick={() => {
                            localStorage.setItem('notificationUserChoice', 'true');
                            closeSnackbar(snackId);
                            requestPermission();
                        }}
                    >
                        <FormattedMessage id="ENABLE_NOTIFICATIONS" />
                    </Button>,
                    <Button
                        color="inherit"
                        onClick={() => {
                            localStorage.setItem('notificationUserChoice', 'false');
                            closeSnackbar(snackId);
                        }}
                    >
                        <FormattedMessage id="CANCEL" />
                    </Button>,
                ]),
            });
            return () => {
                closeSnackbar(snackId);
            };
        }

        return undefined;
    }, []);

    React.useEffect(() => {
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
                notificationPerm.addEventListener('change', () => {
                    setNotificationsEnabled(Notification.permission === 'granted');
                });
            });
        } else if (Notification.permission) {
            setNotificationsEnabled(Notification.permission === 'granted');
        } else {
            setNotificationsEnabled(false);
        }
    }, []);

    return <NotificationContext.Provider value={{ notificationsEnabled }} {...props} />;
}

export { NotificationProvider, NotificationContext, useNotification };
