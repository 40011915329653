import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import parseISO from 'date-fns/parseISO';
import { useSnackbar } from 'notistack';

import RouteLink from 'components/RouteLink';
import { useUser } from 'contexts/user';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        maxHeight: '100%',
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
        alignSelf: 'flex-start',
    },
    pendingContainer: {
        marginTop: theme.spacing(4),
    },
    title: {
        wordWrap: 'break-word',
    },
    reason: {
        '& + &::before': {
            content: "', '",
        },
    },
    strikethrough: {
        textDecoration: 'line-through',
    },
    loading: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    chip: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
}));

const propTypes = {
};

const defaultProps = {
    orderList: []
};

function VodPendingOrder(props) {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    const { api, user : {name} } = useUser();
    const { client: clientId, order: orderId } = match.params;
    const [fetchingOrder, setFetchingOrder] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [failed, setFailed] = React.useState(false);
    const [order, setOrder] = React.useState({});
    const [cancelOrder, setcancelOrder] = React.useState(false);
    const [orderList, setOrderList] = React.useState([]);
    const [cancelOrderReason, setCancelOrderReason] = React.useState();
    const path = generatePath(match.path, { order: null,  client: clientId  })

    const { orderEndpoint, refresh } = props;
    let notCanceledOrder = []
    let listOrder = []
    if (order.items) {
        notCanceledOrder = order.items.filter((o) => o.cancelledDate === null) ;
    }
    let filteredOrder =  order.items
    if (cancelOrder) {
        filteredOrder = notCanceledOrder
    }
    if (filteredOrder) {
        listOrder = filteredOrder.map(x => x.idPendingItem.toString());
    }

    function handleItemSelection({ target: { value } }) {
        setOrderList((currentOrderList) => {
            if (currentOrderList.includes(value)) {
                return currentOrderList.filter((x) => x !== value);
            }
            return [...currentOrderList,value];
        });
    }

    function closeModal() {
        history.push({
            pathname: generatePath(match.path, { order: null,  client: clientId  }),
            search: history.location.search,
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const isDistri = generatePath(match.path, { order: null,  client: clientId  }).includes("distribution")
        let path = `/vod/orders/${clientId}/cancel`
        if (isDistri) {
            path = `/distribution/orders/${clientId}/cancel`
        }

        const snackId = enqueueSnackbar(<FormattedMessage id="CANCELLING_ORDER" />, { variant: 'loading', persist: true });
        const data = {
            orderList, cancelOrderReason, name};

        const response = await api.put(path, data);

        if (response.ok) {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ORDER_CANCELLED" />, { variant: 'success' });
            refresh();
            setcancelOrder(false);
            setOrderList([]);
            setCancelOrderReason(null);
            closeModal();
        } else {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ERROR_CANCELLING_ORDER" />, { variant: 'error' });
        }
    }

    async function fetchOrder(abortController = new AbortController()) {
        if (clientId && orderId) {
            try {
                setFetchingOrder(true);
                setFailed(false);

                const response = await api.get(orderEndpoint(clientId, orderId), {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setOrder(data);
                } else {
                    setOrder({});
                    setFailed(true);
                }

                setFetchingOrder(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    function handleCancelOrder({ target: { checked } }) {
        setcancelOrder(!cancelOrder);
        setOrderList(cancelOrder ? [] : listOrder);
        setCancelOrderReason(null);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (orderId) {
            fetchOrder(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [clientId, orderId]);

    let content = null;

    if (fetchingOrder) {
        content = (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    } else if (!fetchingOrder && isEmpty(order)) {
        content = (
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="NOT_FOUND" />
                </DialogContentText>
            </DialogContent>
        );
    } else if (parseInt(orderId, 10) === order.orderId) {
        content = (
            <>
                <DialogTitle className={classes.title}>
                    {order.orderName}
                </DialogTitle>
                <DialogContent>

                    <Grid container>
                        <Grid item xs={12} align="right" >
                            <Typography
                                variant="h6"
                                className={order.deliverables === order.cancelled ? classes.strikethrough : ''}
                            >
                                <FormattedMessage id="EXPECTED_BY" values={{ date: parseISO(order.expectedBy) }} />
                            </Typography>
                            {
                                !order.cancelledDate || order.deliverables !== order.cancelled ? false : (
                                    <Typography color="error" gutterBottom>
                                        <FormattedMessage id="CANCELLED_ON" values={{ date: parseISO(order.cancelledDate) }} />
                                    </Typography>
                                )
                            }
                        </Grid>

                        {
                            order.items.length === 0 ? null : (
                                <Grid item xs={12} container spacing={2} className={classes.pendingContainer}>
                                    {
                                        filteredOrder.map(({
                                            audio, cancelledDate, cancelledReason, languages, lang, idPendingItem,
                                            resolution, suggestedDeliveryDate, deliveryDate, title, orderId,
                                        }) => (
                                            <Grid container item xs={12} alignItems="baseline">
                                                <Grid item xs={3} direction="column" container spacing={1}>
                                                    <Typography
                                                        display="block"
                                                        variant="overline"
                                                        color={cancelledDate ? 'error' : 'textPrimary'}
                                                    >
                                                        <FormattedMessage id={cancelledDate ? 'CANCELLED' : 'EXPECTED'} />
                                                    </Typography>

                                                    {
                                                        !cancelledDate && !suggestedDeliveryDate ? null : (
                                                            <Typography color="textSecondary" variant="caption">
                                                                <FormattedDate
                                                                    value={cancelledDate || deliveryDate || suggestedDeliveryDate}
                                                                    month="short"
                                                                    year="numeric"
                                                                    day="numeric"
                                                                    timeZone="UTC"
                                                                />
                                                            </Typography>
                                                        )
                                                    }
                                                </Grid>
                                                <Grid item xs={cancelOrder ? 8 : 9}>

                                                    <Typography variant="body1" gutterBottom>
                                                        { title }
                                                    </Typography>
                                                    <Typography paragraph variant="caption">
                                                        {
                                                            !lang ? null : (
                                                                <Chip
                                                                    className={classes.chip}
                                                                    label={lang}
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            )
                                                        }
                                                        {
                                                            !resolution ? null : (
                                                                <Chip
                                                                    className={classes.chip}
                                                                    label={resolution}
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            )
                                                        }
                                                        {
                                                            !audio ? null : (
                                                                <Chip
                                                                    className={classes.chip}
                                                                    label={audio}
                                                                    variant="outlined"
                                                                    size="small"
                                                                />
                                                            )
                                                        }
                                                    </Typography>
                                                    {
                                                        !cancelledReason ? null : (
                                                            <Typography color="error" variant="caption">
                                                                {cancelledReason}
                                                            </Typography>
                                                        )
                                                    }
                                                </Grid>
                                                {
                                                    !cancelOrder ? null : (
                                                        <Grid item xs={1}>
                                                            <Checkbox
                                                                name={`item-${idPendingItem}`}
                                                                checked={orderList.includes(idPendingItem.toString())}
                                                                onChange={handleItemSelection}
                                                                value={idPendingItem}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>

                                        ))
                                    }
                                </Grid>
                            )
                        }

                    </Grid>
                    {
                        !cancelOrder ? null : (
                            <TextField
                                autoFocus
                                maxRows={3}
                                multiline
                                margin="normal"
                                value={cancelOrderReason}
                                required
                                fullWidth
                                minRows={3}
                                label={<FormattedMessage id="CANCEL_ORDER_REASON" />}
                                error={isEmpty(cancelOrderReason)}
                                helperText={!isEmpty(cancelOrderReason) ? null : <FormattedMessage id="MUST_FILL_FIELD" />}
                                onChange={({ target: { value } }) => { setCancelOrderReason(value); }}
                            />
                        )
                    }
                </DialogContent>
            </>
        );
    }

    return (
        <Dialog
            open={!!orderId}
            onClose={closeModal}
        >
            {content}
            <DialogActions>
                {
                    order.deliverables === order.cancelled ? null : (
                        <div style={{ marginRight: 'auto' }}>
                            <Button onClick={handleCancelOrder}>
                                <FormattedMessage id={cancelOrder ? 'CLOSE' : 'CANCEL_ORDER'} />
                            </Button>
                        </div>
                    )
                }
                { cancelOrder && order.deliverables !== order.cancelled ? (
                    <Button
                        component={RouteLink}
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isEmpty(cancelOrderReason) || isEmpty(orderList)}
                    >
                        <FormattedMessage id="SAVE" />
                    </Button>
                ) : (
                    <Button
                        underline="none"
                        color="primary"
                        component={RouteLink}
                        to={generatePath(match.path, { client: clientId })}
                    >
                        <FormattedMessage id="CLOSE" />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

VodPendingOrder.propTypes = propTypes;
VodPendingOrder.defaultProps = defaultProps;

export default VodPendingOrder;
