import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Wizard from 'components/Wizard';
import ServiceSelection from './ServiceSelection';
import TitleSelection from './TitleSelection';
import AssetSelection from './AssetSelection';
import AssetCreation from './AssetCreation';
import AssetExpectedDate, { validAssetsForReception } from './AssetExpectedDate';
import TitleCreation from './TitleCreation';
import OutputTechInfo from './OutputTechInfo';
// import VaultOptions from './VaultOptions';
import Destination from './Destination';
import Confirmation from './Confirmation';
import FileDestination from './FileDestination';
import DestinationsGroups from './DestinationsGroups';
import { formatPostData } from 'utils/ofApi';

import {
    validateEmail, initialState, defaultFormOptions, deliveryTypes,
    fileDelivery, tapeDelivery, vaultService, versionService,
    closedCaptionService, adaptationService, videoDescriptionService,
    subtitleService, catalogueElementService, ensureDefaultUndefined,
    creationServices, CC_ASSET_TYPE, SUBTITLE_ASSET_TYPE,
    AUDIO_ASSET_TYPE, STEREO, DESCRIBED_VIDEO, languageSelectionServices,
} from './constants';
import { useUser } from 'contexts/user';
import { useConstants } from 'contexts/constants';

const emptyAsset =  {
    'assetType' : '',
    'tvDefinition' : '',
    'assetLanguage' : '',
    'translationTypes' : '',
    'frameRate' : '',
    'ratio' : '',
    'runtime' : '',
    'element' : '',
    'version' : '',
    'season' : '',
    'seasonTitle' : '',
    'season' : '',
    'episodeTitle' : '',
    'partNumber' : '',
    'audioTrack' : [],
    'isPartial' : '',
}

const emptyAudio = {
    'language' : '',
    'audioTrackType' : '',
    'audioContenType' : '',
}

const useStyles = makeStyles((theme) => createStyles({
    overflowWrapper: {
        overflowY: 'auto',
        display: 'grid',
        gridTemplateRows: '100%',
        gridTemplateColumns: '100%',
    },
    transitionWidth: {
        transition: 'inherit',
        transitionProperty: 'width',
    },
    ...Object.keys(theme.breakpoints.values).reduce((m,k) => {
        m[`width${k.toUpperCase()}`] = {width: `${theme.breakpoints.values[k]}px`}
        return m
    }, {}),
}));

const propTypes = {
    closeModal: PropTypes.func,
    setSelectedTitles: PropTypes.func,
    setSelectedAssets: PropTypes.func,
};
const defaultProps = {
    closeModal: null,
    setSelectedTitles: () => {},
    setSelectedAssets: () => {},
};

function CreateOrder(props) {
    const {
        closeModal, selectedTitles: propSelectedTitles, selectedAssets: propSelectedAssets,
        setSelectedTitles: setPropSelectedTitles, setSelectedAssets: setPropSelectedAssets,
        titlesDescription: propTitlesDescription, assetsDescription: propAssetsDescription,
    } = props;

    const { api } = useUser();
    const { client } = useParams();
    const classes = useStyles();

    const { isLoading: fetchingAssetOptions, locales, vodOptions: { assetTypeOptions: assetTypeConstants,
        entityTypeOptions: entityTypeConstants, hardDiskId, pickUpId, clientServerId, tapeHdDeliveryTypes, fileDeliveryOptions,
        ratings, advisories,
    } } = useConstants();

    const [receptionDates, setReceptionDates] = React.useState([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selectedService, setSelectedService] = React.useState(initialState.selectedService);
    const [controlledSelectedTitles, setControlledSelectedTitles] = React.useState([...initialState.selectedTitles]);
    const [newTitle, setNewTitle] = React.useState({ ...initialState.newTitle });
    const [tempTitle, setTempTitle] = React.useState({ ...initialState.newTitle });
    const [assetList, setAssetList] = React.useState([...initialState.assetList]);
    const [tempAssetList, setTempAssetList] = React.useState([...initialState.assetList]);
    const [delivery, setDelivery] = React.useState(initialState.delivery.map((x) => ({ ...x })));
    const [controlledSelectedAssets, setControlledSelectedAssets] = React.useState([...initialState.selectedAssets]);
    const [destination, setDestination] = React.useState(initialState.destination.map((x) => ({ ...x })));
    const [outputData, setOutputData] = React.useState(initialState.outputData.map((x) => ({ ...x })));
    const [controlledAssetsDescription, setControlledAssetsDescription] = React.useState([...initialState.assetsDescription]);
    const [controlledTitlesDescription, setControlledTitlesDescription] = React.useState([...initialState.titlesDescription]);
    const [currentServiceIndex, setCurrentServiceIndex] = React.useState(initialState.currentServiceIndex);
    const [targetLanguage, setTargetLanguage] = React.useState(initialState.targetLanguage);
    const [fetchingAssetDescriptions, setFetchingAssetDescriptions] = React.useState(false);
    const [skipServiceSelection, setSkipServiceSelection] = React.useState(false);
    const [broadcasterOptions, setBroadcaster] = React.useState([]);
    const [fetchingBroadcaster, setFetchingBroadcaster] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const [audioL, setAudioL] = React.useState(emptyAudio);
    const [entity, setEntity] = React.useState(newTitle);
    const [fetchingEntity, setFetchingEntity] = React.useState(true);
    const [failed, setFailed] = React.useState(false);

    const preSelectedAssets = !isEmpty(propSelectedAssets);
    const preSelectedTitles = !isEmpty(propSelectedTitles);
    const forceAssetCreation = [versionService].includes(selectedService)
    const DisableTitleCreation = [closedCaptionService, videoDescriptionService, catalogueElementService, subtitleService, adaptationService].includes(selectedService)
    const DisableAssetCreation = [closedCaptionService, videoDescriptionService, catalogueElementService, adaptationService, subtitleService].includes(selectedService)
    const EnableDestinationAsset = [adaptationService, subtitleService].includes(selectedService)
    const requireAdditionalAssetDetails = selectedService === adaptationService || (selectedService === subtitleService && preSelectedAssets)

    const [selectedAssets, setSelectedAssets] = React.useMemo(() => {
        if (preSelectedAssets) {
            createAdditionalAssets();
            return [propSelectedAssets, setPropSelectedAssets];
        }
        return [controlledSelectedAssets, setControlledSelectedAssets];
    }, [propSelectedAssets, setPropSelectedAssets, controlledSelectedAssets]);

    const [selectedTitles, setSelectedTitles] = React.useMemo(() => {
        if (preSelectedTitles) {
            return [propSelectedTitles, setPropSelectedTitles];
        }
        return [controlledSelectedTitles, setControlledSelectedTitles];
    }, [propSelectedTitles, setPropSelectedTitles, controlledSelectedTitles]);

    const [assetsDescription, setAssetsDescription] = React.useMemo(() => {
        if (propAssetsDescription) {
            return [propAssetsDescription, () =>{}];
        }
        return [controlledAssetsDescription, setControlledAssetsDescription];
    }, [propAssetsDescription, controlledAssetsDescription]);

    const [titlesDescription, setTitlesDescription] = React.useMemo(() => {
        if (propTitlesDescription) {
            return [propTitlesDescription, () =>{}];
        }
        return [controlledTitlesDescription, setControlledTitlesDescription];
    }, [propTitlesDescription, controlledTitlesDescription]);



    const handleClose = React.useCallback(() => {
        setSelectedService(initialState.selectedService);
        setControlledSelectedTitles([...initialState.selectedTitles]);
        setNewTitle({ ...initialState.newTitle });
        setAssetList([...initialState.assetList]);
        setTempTitle({ ...initialState.newTitle });
        setTempAssetList([...initialState.assetList]);
        setDelivery(initialState.delivery.map((x) => ({ ...x })));
        setControlledSelectedAssets([...initialState.selectedAssets]);
        setDestination(initialState.destination.map((x) => ({ ...x })));
        setOutputData(initialState.outputData.map((x) => ({ ...x })));

        setAssetsDescription([...initialState.assetsDescription]);
        setTitlesDescription([...initialState.titlesDescription]);

        if (closeModal) {
            closeModal();
        }
    }, [closeModal]);

    async function fetchEntity(abortController = new AbortController()) {
        if (client && !isEmpty(selectedTitles)) {
            try {
                setFetchingEntity(true);
                setFailed(false);
                const response = await api.get(`${client}/entity/${selectedTitles[0]}/title`, { }, { signal: abortController.signal });
                if (response.ok) {
                    const {
                        id: titleId, ownerId, ...data
                    } = await response.json();

                    const {
                    } = data.metadata;

                    setEntity({
                        aggregator: data.aggregator,
                        poster: data.poster,
                        id: titleId,
                        prodYear: data.details.year,
                        details: data.details,
                        title: data.details.originalTitle || data.details.title || data.title,
                        hasMore: data.hasMore,
                        metadata: data.metadata,
                        entityType: data.entityType.entityTypeId,
                    });

                    setFetchingEntity(false);
                } else {
                    setFetchingEntity(false);
                    setFailed(true);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    async function createTitle(abortController = new AbortController()) {
        try {
            const query = {
                entities: [...newTitle],
            };

            const response = await api.get(`/${client}/order/entity`, query, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();

                if (data.entityId) {
                    setState((currentState) => ({
                        newTitle: { ...initialState.newTitle },
                        selectedTitles: [...currentState.selectedTitles, data.entityId],
                    }));
                }
                return true;
            }
        } catch (error) {
            if (abortController && !abortController.signal.aborted) {
                console.error(error);
            }
        }
        return false;
    }


    async function sendOrder(abortController = new AbortController()) {
        setIsLoading(true)
        let orderData = {}
        if (client) {

            let serviceDeliveryData = [];
            let jointDocuments = {};
            let createdTitle = isEmpty(selectedTitles) && newTitle ? newTitle : {};
            let createdAssets = (creationServices.includes(selectedService) || isEmpty(selectedAssets)) && assetList ? assetList : [];


            if (newTitle.poster instanceof FileList) {
                jointDocuments.poster = newTitle.poster[0];
                let { poster, ...cleanTitle } = newTitle;
                createdTitle = cleanTitle;
            }

            const SourceData = {
                selectedAssets,
                selectedTitles,
                createdTitle,
                createdAssets,
                receptionDates: receptionDates.map(({assetId, isNew, receptionDate}) => ({assetId, isNew, receptionDate})),
            }

            delivery.forEach((service, index) => {
                let data;

                const {
                    deliveryType, deliverTo, tapeDeliveryType,
                    fileDeliveryType, hardDiskDeliveryType, otherDelivery,
                    deliveryAddress,
                } = delivery[index];

                const {
                    sendTo, jointFile, deliveryClient,
                    deliveryDate, deliveryInfoSupplied,
                    section, isnew, externalRecipient,
                    redirect, jointFileFiles,
                    poNumber, tvodsvod, notes, rating, advisoryList,
                    startDate, endDate, virtualVaultEntry,
                    workFile, documentMapping, receptionDate, xmlInventoryId, xmlLineupId
                } = destination[index];
                const advisory = advisoryList && advisoryList.map((item) => (typeof item == 'object' ? item.label : item)).toString();

                const {
                    piste, loudness, codec,
                    codecAutre, bitrate, wrapper,
                    wrapperAutre, frame, textless,
                    encodageNoHearing, scanningMethod, otherSpecification,
                    rubanType, rubanTypeAutre, ardoise,
                } = outputData[index];

                const baseDataTitle = {
                    poNumber,
                    virtualVaultEntry: virtualVaultEntry,
                    tvodsvod,
                    notes,
                    rating,
                    xmlInventoryId,
                    xmlLineupId,
                    advisory: advisory,
                    deliveryDate,
                    client,
                    deliveryClient,
                    startDate,
                    endDate,
                    withAttachment: false,
                    workFile,
                    documentMapping,
                };

                const baseData = {
                    ...baseDataTitle,

                };

                const fileData = {
                    piste,
                    loudness,
                    codec: codec === 'other' ? codecAutre : codec,
                    bitrate,
                    wrapper: wrapper === 'other' ? wrapperAutre : wrapper,
                    frame,
                    textless: !textless ? 'Non' : 'Oui',
                    encodageNoHearing: !encodageNoHearing ? 'Non' : 'Oui',
                    scanningMethod: scanningMethod === 1 ? 'Entrelacé' : 'Progressif',
                    otherSpecification,
                };

                const findDescription = (typeArray, refId) => {
                    const found = typeArray.find(({ id }) => id === refId);
                    const { description } = found || { description: 'Not Found' };
                    return description;
                };

                if (externalRecipient) {
                    if (!isEmpty(jointFile) && deliveryInfoSupplied) {
                        data = {
                            ...baseData,
                            withAttachment: true,
                        };
                    } else if (!isEmpty(jointFile) && !deliveryInfoSupplied) {
                        data = {
                            ...baseData,
                            withAttachment: true,
                            deliverTo,
                            delivery: fileDeliveryType === hardDiskId ? hardDiskDeliveryType : fileDeliveryType,
                            deliveryStr: fileDeliveryType === hardDiskId
                                ? findDescription(tapeHdDeliveryTypes, hardDiskDeliveryType)
                                : findDescription(fileDeliveryOptions, fileDeliveryType),
                            deliveryAddress,
                        };
                    } else {
                        data = {
                            ...baseData,
                            target: sendTo,
                            section,
                            ...fileData,
                            deliveryType: 'Fichier',
                            deliverTo,
                            delivery: fileDeliveryType === hardDiskId ? hardDiskDeliveryType : fileDeliveryType,
                            deliveryStr: fileDeliveryType === hardDiskId
                                ? findDescription(tapeHdDeliveryTypes, hardDiskDeliveryType)
                                : findDescription(fileDeliveryOptions, fileDeliveryType),
                            deliveryAddress,
                        };
                    }
                } else {
                    data = {
                        ...baseData,
                        target: virtualVaultEntry ? [] : sendTo,
                        section,
                    };
                    // if (needsApprouval) {
                    //     data.createPackages = suggestedAssets;
                    // }
                }
                if (workFile) {
                    Object.keys(documentMapping).filter((key) => (documentMapping[key] !== 'DELIVERY_SPECS')).forEach((key, index) => {
                        jointDocuments[key] = workFile[index];
                    })
                }
                if (jointFile) {
                    Object.keys(documentMapping).filter((key) => (documentMapping[key] === 'DELIVERY_SPECS')).forEach((key, index) => {
                        jointDocuments[key] = jointFile[index];
                    })
                }
                serviceDeliveryData.push(data)

            })

            orderData = ensureDefaultUndefined({
                selectedService,
                targetLanguage,
                ...SourceData,
                serviceDeliveryData
            })


            const snackId = enqueueSnackbar(<FormattedMessage id="CREATING_ORDER" />, { variant: 'loading', persist: true });
            const response = await api.post(`/${client}/orders/`, formatPostData(orderData, jointDocuments), { signal: abortController.signal });

            if (response.ok) {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ORDER_CREATED" />, { variant: 'success' });
                closeModal();
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ERROR_CREATING_ORDER" />, { variant: 'error' });
            }
            setIsLoading(false);
        }
    }

    const handleCreateTitle = React.useCallback(async (event, callback) => {
        const successful = await createTitle();

        if (successful) {
            callback(event);
        }
    }, [newTitle]);


    const mainDestination = destination[0];
    const mainDelivery = delivery[0];
    const mainOutputData = outputData[0];

    const {titleValid, titleHelperMesage, titleCreationValid} = React.useMemo(() => {
        let titleHelperMesage = undefined;
        const { value: titleTxt = '' } = !isEmpty(tempTitle.title) && tempTitle.title[0] || {};
        const titleEmpty = isEmpty(titleTxt.trim());
        const noLeadingOrTrailingSpaces = titleTxt === titleTxt.trim()

        if (titleEmpty) {
            titleHelperMesage =  <FormattedMessage id="INVALID_TITLE" />
        } else if (!noLeadingOrTrailingSpaces){
            titleHelperMesage =  <FormattedMessage id="LEADING_TRAILING_SPACES" />
        }
        const titleValid = !titleEmpty && noLeadingOrTrailingSpaces;

        const titleCreationValid = !!(
            !isEmpty(titleTxt) &&
            noLeadingOrTrailingSpaces &&
            tempTitle.prodYear &&
            tempTitle.entityType &&
            tempTitle.originalLanguage  );

        return {titleValid, titleHelperMesage, titleCreationValid};
    }, [tempTitle]);

    const showRatingAndAdvisory = React.useMemo(() => {
        const {
            sendTo
        } = mainDestination;

        const {
            idTypeDiffuseur,
            needXml,
            isContentAdvisoryRequired,
        } = sendTo;
        const displayXmlFields = idTypeDiffuseur === 'VOD' && needXml === 1;

        const advisoryAssetTypes = ['VIDEO', 'PACKAGE'];
        const advisoryEntityTypes = ['SHORT', 'MOVIE', 'IMF', 'DCP', 'SERIES', 'DOCU', 'EPISODE', 'PART', 'SEASON', 'VERSION'];
        const advisoryElementTypes = ['ORIGINAL_VERSION', 'OTHER_VERSION', 'PART'];

        const someAssetsAcceptRating = assetList.some(({ element, assetType }) => advisoryAssetTypes.includes(assetType) && (advisoryElementTypes.includes(element) || advisoryEntityTypes.includes(element)));
        const someAssetDescriptionsAcceptRating = assetsDescription.some(({ entityTypeId, assetTypeId }) => advisoryAssetTypes.includes(assetTypeId) && advisoryEntityTypes.includes(entityTypeId));
        return (someAssetsAcceptRating || someAssetDescriptionsAcceptRating) && isContentAdvisoryRequired && displayXmlFields;
    }, [assetList, assetsDescription, mainDestination]);// isContentAdvisoryRequired, displayXmlFields]);

    const destinationValid = React.useMemo(() => {
        const {
            externalRecipient, sendTo, deliveryDate, specFile,
            jointFile, deliveryClient, deliveryInfoSupplied,
            tvodsvod, rating, xmlInventoryId, xmlLineupId, 
        } = mainDestination;

        const {
            deliveryType, fileDeliveryType, hardDiskDeliveryType,
            deliveryAddress, deliverTo,
        } = mainDelivery;

        const {
            hasInventoryId,
            hasLineupId,
        } = sendTo;
        const ratingIfRequired = (sendTo.isContentAdvisoryRequired) && showRatingAndAdvisory ? (!isEmpty(rating)) : true;
        const inventoryRequired = hasInventoryId === 'A'
        const lineUpRequired = hasLineupId === 'A'
        const lineup = !lineUpRequired ? true : !!xmlLineupId
        const inventoryID = !inventoryRequired ? true : !!xmlInventoryId
        const Vvtype = [vaultService, adaptationService, subtitleService, videoDescriptionService, closedCaptionService].includes(selectedService) && !isNull(deliveryDate);
        const supportedClient = !externalRecipient && !isEmpty(sendTo) && !isNull(deliveryDate) && (!sendTo.hasProductRule || !!tvodsvod) && ratingIfRequired && inventoryID && lineup;
        const externalNoSpec = externalRecipient && !specFile && !isNull(deliveryDate) && deliveryClient;
        // const deliveryInfoValid = !deliveryInfoSupplied && deliverTo && fileDeliveryType && ( (fileDeliveryType === 20 && deliveryAddress) || validateEmail(deliveryAddress));
        const externalWithSpec = externalRecipient && !!specFile && !isEmpty(jointFile) && !isNull(deliveryDate) && deliveryClient ;
        return supportedClient || externalNoSpec || externalWithSpec || Vvtype  ;
    }, [mainDestination, mainDelivery]);

    const otherDestinationValid = React.useMemo(() => {
        const {
            sendTo, deliveryDate,
            deliveryInfoSupplied,
        } = mainDestination;

        const {
            deliveryType, fileDeliveryType, hardDiskDeliveryType,
            deliveryAddress, deliverTo,
        } = mainDelivery;

        const deliveryInfoValid = !!deliverTo && (
            (fileDeliveryType !== hardDiskId && fileDeliveryType !== clientServerId && validateEmail(deliveryAddress)) ||
            ((fileDeliveryType === clientServerId || fileDeliveryType === hardDiskId && hardDiskDeliveryType) && !!deliveryAddress || hardDiskDeliveryType === pickUpId)
        )

        return deliveryInfoValid;
    }, [mainDestination, mainDelivery]);

    const disableOutputTech = React.useMemo(() => {
        const {
            externalRecipient, sendTo, deliveryDate, specFile,
            deliveryClient, deliveryInfoSupplied,
        } = mainDestination;

        const { deliveryType } = mainDelivery;

        return (
            (!externalRecipient && !isEmpty(sendTo) && !isNull(deliveryDate)) && (selectedService !== catalogueElementService)
            || (externalRecipient && !!specFile && deliveryTypes.includes(deliveryType) && !isNull(deliveryDate) && deliveryClient) && (selectedService !== catalogueElementService)
            || deliveryInfoSupplied && (selectedService !== catalogueElementService)
        );

    }, [selectedService, mainDestination, mainDelivery]);


    const outputTechInfoValid = React.useMemo(() => {
        const { deliveryType } = mainDelivery;
        const {
            codec, wrapper, frame,
            piste, loudness, rubanType, ardoise,
        } = mainOutputData;

        const tapeDetailsValid = deliveryType === tapeDelivery && rubanType && ardoise;
        const fileDetailsValid = deliveryType === fileDelivery && codec && wrapper && loudness;

        return !!(frame && !isEmpty(piste) && codec && wrapper && loudness);
    }, [mainOutputData, mainDelivery]);

    const invalidAssets = React.useMemo(() => {
        let invalidAsset = [];

        tempAssetList.forEach((asset, assetIndex) => {
            let elementTypeValid = false;
            let assetDefinitionValid = false;

            const assetTypeDescription = assetTypeConstants.find(({assetTypeId}) => assetTypeId === asset.assetType);

            if (['ORIGINAL_VERSION', 'TRAILER'].includes(asset.element)) {
                elementTypeValid = true;
            } else if (asset.element === 'EPISODE') {
                elementTypeValid = !!(asset.season && asset.episode);
            } else if (asset.element === 'OTHER_VERSION') {
                elementTypeValid = !!(asset.version);
            } else if (asset.element === 'PART') {
                elementTypeValid = !!(asset.partNumber);
            } else if (asset.element === 'PREVIEW') {
                elementTypeValid = !!(asset.version);
            }

            if (assetTypeDescription) {
                assetDefinitionValid = Object.entries(assetTypeDescription).every(([key, value]) => {
                    if (key === 'hasLocale' && value === 'A') {
                        return !!asset.assetLanguage;
                    } else if (key === 'hasFrameRate' && value in ['A','O']) {
                        return ![undefined, ''].includes(asset.frameRate);
                    } else if (key === 'hasRatio' && value === 'A') {
                        return ![undefined, ''].includes(asset.ratio);
                    } else if (key === 'hasTranslation' && value === 'A') {
                        return !!asset.translationTypes;
                    } else if (key === 'hasDefinition' && value === 'A') {
                        return ![undefined, ''].includes(asset.tvDefinition);
                    } else if (key === 'hasAudio' && value === 'A') {
                        return asset.audioTrack && asset.audioTrack.length > 0;
                    }
                    return true;
                });
            }

            if (requireAdditionalAssetDetails ? !assetDefinitionValid : !asset.assetType || !asset.element || !assetDefinitionValid || !elementTypeValid) {
                invalidAsset.push(assetIndex)
            }

        })
        return invalidAsset;
    }, [tempAssetList, requireAdditionalAssetDetails]);

    function createAdditionalAssets() {
        if (creationServices.includes(selectedService)) {
            const sourceAsset = assetsDescription[0];
            const sourceLocale = (locales.find(({ label }) => sourceAsset.language === label) || {}).value
            let createdAsset = {
                ...emptyAsset,
                assetIndex: (new Date()).valueOf(),
            };

            if (selectedService === adaptationService) {
                createdAsset.assetType = sourceAsset.assetTypeId;
                createdAsset.frameRate = sourceAsset.frameRate;
                createdAsset.tvDefinition = sourceAsset.tvDefinition;
                createdAsset.runtime = sourceAsset.runtime;
                createdAsset.ratio = sourceAsset.imageRatio;
                createdAsset.assetLanguage = targetLanguage;
            } else if (selectedService === subtitleService) {
                createdAsset.assetType = SUBTITLE_ASSET_TYPE;
                createdAsset.frameRate = sourceAsset.frameRate;
                createdAsset.assetLanguage = targetLanguage;
            } else if (selectedService === videoDescriptionService) {
                createdAsset.assetType = AUDIO_ASSET_TYPE;
                createdAsset.runtime = sourceAsset.runtime;
                createdAsset.frameRate = sourceAsset.frameRate;
                createdAsset.audioTrack.push({ audioContenType: DESCRIBED_VIDEO, language: sourceLocale, audioTrackType: STEREO, trackLeft: 1, trackRight: 2 })
            } else if (selectedService === closedCaptionService) {
                createdAsset.assetType = CC_ASSET_TYPE;
                createdAsset.assetLanguage = sourceLocale;
                createdAsset.frameRate = sourceAsset.frameRate;
            }
            setAssetList([createdAsset])
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchEntity(abortController);
        if (!isEmpty(selectedTitles))  {
            fetchEntity(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [selectedTitles]);

    async function fetchAssetDescription(abortController = new AbortController()) {
        if (client) {
            setFetchingAssetDescriptions(true);
            setSkipServiceSelection(false);
            try {
                const response = await api.get(`${client}/forest/`, { entityIds: selectedTitles, assetIds: selectedAssets }, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setAssetsDescription(data);

                    if (!selectedService && (data.length > 1 || !(['VIDEO', 'PACKAGE']).includes(data[0].assetTypeId))) {
                        setSkipServiceSelection(true);
                        setSelectedService(catalogueElementService);
                    }
                } else {
                    setAssetsDescription([]);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setFetchingAssetDescriptions(false);
        }
    }



    React.useEffect(() => {

        if (propSelectedAssets) {
            const abortController = new AbortController();
            fetchAssetDescription(abortController);
            return () => {
                abortController.abort();
            };
        }
    }, [propSelectedAssets])

    async function fetchBroadcaster(abortController = new AbortController()) {
        if (client) {
            try {
                setFetchingBroadcaster(true);
                const response = await api.get(`${client}/broadcaster`, { }, { signal: abortController.signal, artificialDelay: 100 });
                if (response.ok) {
                    const data = await response.json();
                    setBroadcaster(data);
                } else {
                    setBroadcaster([]);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setFetchingBroadcaster(false);
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchBroadcaster(abortController);
        return () => {
            abortController.abort();
        };
    }, [client]);

    const resetSelectedService = React.useCallback(() => {
        setSelectedService(initialState.selectedService);

        setControlledSelectedTitles([...initialState.selectedTitles]);
        setNewTitle({ ...initialState.newTitle });
        setAssetList([...initialState.assetList]);
        setTempTitle({ ...initialState.newTitle });
        setTempAssetList([...initialState.assetList]);
        setDelivery(initialState.delivery.map((x) => ({ ...x })));
        setControlledSelectedAssets([...initialState.selectedAssets]);
        setDestination(initialState.destination.map((x) => ({ ...x })));
        setOutputData(initialState.outputData.map((x) => ({ ...x })));

        setSkipServiceSelection(false);
    });

    const resetAssetSelection = React.useCallback(() => {

        if (!requireAdditionalAssetDetails) {
            setAssetList([...initialState.assetList]);
            setSelectedAssets([...initialState.selectedAssets]);
            setAssetsDescription([...initialState.assetsDescription]);
            setReceptionDates([]);
        }
    }, [setAssetList, setSelectedAssets, requireAdditionalAssetDetails])

    const prepDestination = React.useCallback(() => {
        setDestination((current) => current.map((x, i) => {
            if (i == 0) {
                return { ...x, virtualVaultEntry: selectedService === vaultService };
            }
            return x;
        }))
    }, [selectedService]);

    const resetSelectedTitles = React.useCallback(() => {
        setControlledSelectedTitles([...initialState.selectedTitles]);
        setNewTitle({ ...initialState.newTitle });
        setAssetList([...initialState.assetList]);
        setTempTitle({ ...initialState.newTitle });
        setTempAssetList([...initialState.assetList]);
        setControlledSelectedAssets([...initialState.selectedAssets]);
        setAssetsDescription([...initialState.assetsDescription]);
        setTitlesDescription([...initialState.titlesDescription]);
        setDelivery(initialState.delivery.map((x) => ({ ...x })));
        setDestination(initialState.destination.map((x) => ({ ...x })));
        setOutputData(initialState.outputData.map((x) => ({ ...x })));
    }, [setSelectedTitles, setTitlesDescription]);

    React.useEffect(() => {
        setReceptionDates(validAssetsForReception(assetsDescription, creationServices.includes(selectedService) ? [] : assetList));
    }, [assetList, assetsDescription, selectedService])

    return (
        <Wizard
            contentComponent={DialogContent}
            actionsComponent={DialogActions}
            headerComponent={DialogTitle}
            headers={false}
            onSubmit={sendOrder}
            hasCancel
            cancelActionLabel={<FormattedMessage id="CANCEL_ORDER" />}
            nextActionLabel={<FormattedMessage id="NEXT" />}
            closeModal={handleClose}
            contentClassName={[classes.transitionWidth, classes.widthSM].join(' ')}
            showRequiredMessage={false}
        >
            <ServiceSelection
                isLoading={fetchingAssetDescriptions}
                contentClassName={classes.widthMD}
                isDisabled={skipServiceSelection}
                nextValid={!!selectedService}
                stepHeader={<FormattedMessage id="SELECT_SERVICE" />}
                onChange={setSelectedService}
                selectedService={selectedService}
                beforeMount={resetSelectedService}
                preSelectedAssets={preSelectedAssets}
                preSelectedTitles={preSelectedTitles}
                multipleAssetsSelected={propSelectedAssets && propSelectedAssets.length > 1}
                autoNext
                nextAction={(event, buttonNavigation) => {
                    if(preSelectedAssets) {
                        createAdditionalAssets();
                    }
                    buttonNavigation(event);
                }}
            />
            <TitleSelection
                contentClassName={[classes.widthLG, classes.overflowWrapper].join(' ')}
                isDisabled={preSelectedTitles || preSelectedAssets}
                nextValid={!isEmpty(selectedTitles)}
                altId="createTitle"
                altAction={!DisableTitleCreation}
                altActionLabel={<FormattedMessage id="CREATE" />}
                stepHeader={<FormattedMessage id={[vaultService, versionService].includes(selectedService) ? "SELECT_TITLE_CREATE" : creationServices.includes(selectedService) ? "SELECT_TITLE_SOURCE" : "SELECT_TITLE"} />}
                onChange={setSelectedTitles}
                selectedTitles={selectedTitles}
                setTitlesDescription={setTitlesDescription}
                resetAssetSelection={resetAssetSelection}
                beforeMount={resetSelectedTitles}
                autoNext
            />
            <TitleCreation
                contentClassName={classes.widthLG}
                isDisabled={preSelectedTitles || preSelectedAssets}
                shouldReturn={false}
                altStep="createTitle"
                beforeMount={resetSelectedTitles}
                nextValid={titleCreationValid}
                newTitle={tempTitle}
                selectedTitles={selectedTitles}
                setSelectedTitles={setSelectedTitles}
                setTitlesDescription={setTitlesDescription}
                onChange={setTempTitle}
                hasCancel={false}
                previousActionLabel={<FormattedMessage id="CANCEL" />}
                nextActionLabel={<FormattedMessage id="CREATE" />}
                nextAction={(event, buttonNavigation) => {
                    if (titleCreationValid) {
                        setNewTitle(tempTitle);
                        buttonNavigation(event);
                    }
                }}
                stepHeader={<FormattedMessage id="TITLE_CREATION" />}
                error={!titleValid}
                helperText={titleHelperMesage}
            />
            <AssetSelection
                contentClassName={[classes.widthMD, classes.overflowWrapper].join(' ')}
                isDisabled={isEmpty(selectedTitles) || preSelectedAssets || forceAssetCreation}
                nextValid={languageSelectionServices.includes(selectedService) ? !isEmpty(selectedAssets) && !isEmpty(targetLanguage) : !isEmpty(selectedAssets)}
                altId="createAsset"
                altAction={!DisableAssetCreation}
                altActionLabel={<FormattedMessage id="CREATE" />}
                stepHeader={<FormattedMessage id={selectedService === vaultService ? "SELECT_ASSETS_CREATE" : creationServices.includes(selectedService) ? "SELECT_ASSETS_SOURCE" : "SELECT_ASSETS"} />}
                onChange={setSelectedAssets}
                setAssetsDescription={setAssetsDescription}
                selectedService={selectedService}
                selectedAssets={selectedAssets}
                selectedTitles={selectedTitles}
                setTargetLanguage={setTargetLanguage}
                targetLanguage={targetLanguage}
                nextAction={(event, buttonNavigation) => {
                    if (!isEmpty(selectedAssets)) {
                        createAdditionalAssets();
                        buttonNavigation(event);
                    }
                }}
            />
            <AssetCreation
                contentClassName={classes.widthLG}
                entity={entity}
                shouldReturn={false}
                beforeMount={resetAssetSelection}
                selectedTitles={selectedTitles}
                altStep={!isEmpty(selectedTitles) && !forceAssetCreation && !requireAdditionalAssetDetails ? "createAsset" : undefined}
                newTitle={newTitle}
                selectedService={selectedService}

                invalidAssets={invalidAssets}
                selectedAssets={selectedAssets}
                hasCancel={false}
                previousActionLabel={<FormattedMessage id={forceAssetCreation || requireAdditionalAssetDetails ? "PREVIOUS" : "CANCEL"} />}
                nextActionLabel={<FormattedMessage id={requireAdditionalAssetDetails ? "NEXT" : "CREATE"} />}
                stepHeader={<FormattedMessage id={requireAdditionalAssetDetails ? "ASSET_CREATION_DETAILS" : "ASSET_CREATION"} />}

                allowCreation={!requireAdditionalAssetDetails}

                nextValid={invalidAssets.length <= 0}
                assetList={requireAdditionalAssetDetails ? assetList : tempAssetList}
                onChange={requireAdditionalAssetDetails ? setAssetList : setTempAssetList}
                nextAction={(event, buttonNavigation) => {
                    if (invalidAssets.length <= 0) {
                        if (!requireAdditionalAssetDetails) {
                            setAssetList(tempAssetList);
                        }
                        buttonNavigation(event);
                    }
                }}
            />
            <AssetExpectedDate
                contentClassName={classes.widthMD}
                nextActionLabel={receptionDates.some(({receptionDate}) => !!receptionDate) ? undefined : <FormattedMessage id="SKIP" />}
                onChange={setReceptionDates}
                receptionDates={receptionDates}
                isDisabled={receptionDates.length <= 1}
                nextValid
            />
            <Destination
                contentClassName={classes.widthMD}
                nextValid={destinationValid}
                onChange={setDestination}
                stepHeader={<FormattedMessage id="SELECTION_DESTINATION" />}
                selectedService={selectedService}
                currentServiceIndex={0}
                destination={mainDestination}
                broadcasterOptions={broadcasterOptions}
                mainDestination
                showRatingAndAdvisory={showRatingAndAdvisory}
                ratings={ratings}
                advisories={advisories}
                targetLanguage={targetLanguage}

                onReceptionChange={setReceptionDates}
                receptionDates={receptionDates}

                selectedAssets={selectedAssets}
                assetList={assetList}
                assetsDescription={assetsDescription}
                beforeMount={prepDestination}
            />
            <OutputTechInfo
                stepHeader={<FormattedMessage id="CONTENT" />}
                contentClassName={classes.widthMD}
                isDisabled={(!(mainDestination.externalRecipient && !mainDestination.specFile)) || disableOutputTech}
                nextValid={outputTechInfoValid}
                onChange={setOutputData}
                outputData={outputData}
                currentServiceIndex={0}
            />
            <FileDestination
                stepHeader={<FormattedMessage id="FILE_DESTINATION" />}
                contentClassName={classes.widthMD}
                isDisabled={!(mainDestination.externalRecipient && !mainDestination.deliveryInfoSupplied)}
                selectedService={selectedService}
                nextValid={otherDestinationValid}
                onChange={setDelivery}
                currentServiceIndex={0}
                delivery={mainDelivery}
            />
            <Confirmation
                isaSubmit
                nextValid={!isLoading}
                stepHeader={<FormattedMessage id="ORDER_CONFIRMATION" />}
                altId="services"
                nextActionLabel={<FormattedMessage id="SUBMIT" />}
                altActionLabel={<FormattedMessage id="ADD_SERVICE" />}
                altAction={()=> {
                    setDelivery((currentDelivery) => {
                        const newDelivery = currentDelivery.concat(initialState.delivery.map((x) => ({ ...x })))
                        setCurrentServiceIndex(newDelivery.length -1 )
                        return newDelivery
                    })
                    setDestination((currentDestination) => ([...currentDestination, ...initialState.destination.map((x) => ({...x}))]))
                    setOutputData((currentOutputData) => (currentOutputData.concat(initialState.outputData.map((x) => ({ ...x })))))
                    }
                }
                entity={entity}
                selectedAssets={selectedAssets}
                selectedTitles={selectedTitles}
                selectedService={selectedService}
                delivery={delivery}
                newTitle={newTitle}
                destination={destination}
                assetList={assetList}
                assetsDescription={assetsDescription}
                titlesDescription={titlesDescription}
                setDelivery={setDelivery}
                setDestination={setDestination}
                setOutputData={setOutputData}
                targetLanguage={targetLanguage}
            />
            <DestinationsGroups
                isPageBundle
                disabled={isEmpty(delivery[currentServiceIndex])}
                setDestination={setDestination}
                selectedService={selectedService}
                currentServiceIndex={currentServiceIndex}
                setCurrentServiceIndex={setCurrentServiceIndex}
                delivery={delivery[currentServiceIndex]}
                broadcasterOptions={broadcasterOptions}
                destination={destination[currentServiceIndex]}
                setOutputData={setOutputData}
                outputData={outputData}
                setDelivery={setDelivery}
                deliverys={delivery}
                destinations={destination}
            />
        </Wizard>
    );
}

CreateOrder.propTypes = propTypes;
CreateOrder.defaultProps = defaultProps;

export default CreateOrder;
