import React from 'react';
import PropTypes from 'prop-types';
import clsx from  'clsx';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) => createStyles({
    selected: {
        '& > td, & > th': {
            fontWeight: 600,
        },
    },
    row: {
        [`&, &.${tableRowClasses.hover}:hover, &.${tableRowClasses.selected}`]: {
            backgroundColor: 'transparent',
        }
    }
}));

const propTypes = {
    onClick: PropTypes.func,
    rowData: PropTypes.shape({}),
    selected: PropTypes.bool,
    className: PropTypes.string,
    hover: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

const defaultProps = {
    onClick: undefined,
    rowData: undefined,
    selected: undefined,
    className: undefined,
    hover: undefined,
    children: undefined,
    disabled: false,
};

function EnhancedTableRow(props) {
    const {
        children, hover, className, selected,
        disabled, disabledMessage,
    } = props;

    const classes = useStyles();

    function handleOnRowClick({ target: { type } }) {
        const { onClick, rowData } = props;
        if (type !== 'checkbox' && onClick) {
            onClick(rowData);
        }
    }

    function content() {
        return (
            <TableRow
                disabled={disabled}
                hover={hover}
                className={clsx(className, classes.row, selected && classes.selected)}
                onClick={handleOnRowClick}
                selected={selected}
            >
                {children}
            </TableRow>
        );
    }


    return (disabled && disabledMessage) ? (
        <Tooltip
            PopperProps={{
                popperOptions: {
                    placement: 'bottom',
                    modifiers: {
                        inner: {
                            enabled: true,
                        },
                        flip: {
                            enabled: false,
                        },
                        offset: {
                            enabled: true,
                            offset: '0px, 50%r - 50%p',
                        },
                    },
                },
            }}
            title={<FormattedMessage id={disabledMessage} />}
        >
            {content()}
        </Tooltip>
    ) : content();
}

EnhancedTableRow.propTypes = propTypes;
EnhancedTableRow.defaultProps = defaultProps;

export default EnhancedTableRow;
