import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import DatePicker from 'components/DatePicker';
import { formatPostData } from 'utils/ofApi';
import { useUser } from 'contexts/user';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';

import FormSelect from 'components/FormSelect';

function RequestOutput(props) {
    const {
        objects, objectIds, open, onClose,
        branches, coordinators,
    } = props;


    const { client: clientId } = useParams();

    const { api, user: { language } } = useUser();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [actionId, setActionId] = React.useState(1);
    const [poNum, setPoNum] = React.useState('');
    const [shipTo, setShipTo] = React.useState('');
    const [attn, setAttn] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [expectedDate, setExpectedDate] = React.useState(null);
    const [coordinatorIds, setCoordinatorIds] = React.useState([]);


    const [failed, setFailed] = React.useState(false);

    function handleClose() {
        if (typeof onClose === 'function') {
            onClose(false);
            setActionId(1);
            setPoNum('');
            setShipTo('');
            setAttn('');
            setAddress('');
            setComment('');
            setExpectedDate(null);
            setCoordinatorIds([]);
        }
    }

    async function handleSendOnClick(abortController = new AbortController()) {

        const selectedCoordinators = coordinators.filter(({ employeeId }) => coordinatorIds.includes(employeeId));
        const emailAddresses = selectedCoordinators.map(({ email }) => (email));
        const selectedObjects = objects.filter(({ objectId: id }) => objectIds.includes(id));
        const barcodes = selectedObjects.map(({ barcode }) => (barcode));
        const data = {
            clientId, actionId, poNum,
            shipTo, attn, expectedDate, address,
            comment, emailAddresses, objectIds, barcodes,
        };

        const snackId = enqueueSnackbar(<FormattedMessage id="CREATING_ORDER" />, { variant: 'loading', persist: true });
        const response = await api.post(`/physical-vault/${clientId}/request`, formatPostData(data, []), { signal: abortController.signal });


        if (response.ok) {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ORDER_CREATED" />, { variant: 'success' });
            if (typeof onClose === 'function') {
                setActionId(1);
                setPoNum('');
                setShipTo('');
                setAttn('');
                setAddress('');
                setComment('');
                setExpectedDate(null);
                setCoordinatorIds([]);

                onClose(true);
            }
        } else {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ERROR_CREATING_ORDER" />, { variant: 'error' });
        }
    }

    const actions = [
        { value: 1, label: 'VAULT_OUTPUT' },
        { value: 2, label: 'PERMANENT_REMOVAL' },
    ];

    const optionBranches = branches ? branches.map(({ branchId, shortName }) => ({ value: branchId, label: shortName })) : [];
    const optionCoordinators = coordinators ? coordinators.map(({ employeeId, name }) => ({ value: employeeId, label: name })) : [];
    const sendAllowed = expectedDate > Date.now() && shipTo != undefined && (coordinatorIds.length > 0 || attn.length > 2) && address;
    return (
        <Dialog
            props={{ ...props }}
            open={open}
        >
            <DialogTitle>
                <FormattedMessage id="VAULT_OUTPUT" />
            </DialogTitle>
            <DialogContent>

                <Grid container spacing={2} style={{ marginTop: '0', marginBottom: '0' }}>
                    <Grid item xs={12} md={6}>
                        <FormSelect
                            fullWidth
                            required
                            label={<FormattedMessage id="REQUEST" />}
                            name="actionId"
                            value={actionId}
                            onChange={e => { setActionId(e.target.value); }}
                        >
                            {
                                actions.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        <FormattedMessage id={label} />
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth
                            id="poNum"
                            label={<FormattedMessage id="PO_NUMBER" />}
                            value={poNum}
                            onChange={e => { setPoNum(e.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSelect
                            fullWidth
                            required
                            label={<FormattedMessage id="DELIVER_TO" />}
                            name="shipTo"
                            value={shipTo}
                            onChange={e => {
                                setAddress(branches.find(
                                    ({ branchId }) => branchId === e.target.value).address || '');
                                setShipTo(e.target.value);
                            }}
                        >
                            {
                                optionBranches.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                    {
                        shipTo === '' ? null : (
                            <Grid item xs={12}>
                                {
                                    shipTo === 0 ? (
                                        <TextField fullWidth
                                            id="attn"
                                            label={<FormattedMessage id="CARE_OF" />}
                                            value={attn}
                                            required
                                            onChange={e => { setAttn(e.target.value); }}
                                        />
                                    ) : (
                                        <FormSelect
                                            fullWidth
                                            required
                                            multiple
                                            label={<FormattedMessage id="SEND_TO" />}
                                            name="coordinatorIds"
                                            value={coordinatorIds}
                                            onChange={e => { setCoordinatorIds([...e.target.value]); }}
                                        >
                                            {optionCoordinators.map(({ value, label }) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </FormSelect>
                                     )
                                }
                            </Grid>
                        )
                    }
                {/* <Grid container spacing={2} alignItems="baseline" style={{ paddingTop: '20px' }}> */}
                    <Grid item xs={12}>
                        <TextField fullWidth multiline rows={4} variant='outlined'
                            id="address"
                            label={<FormattedMessage id="POSTAL_ADDRESS" />}
                            value={address}
                            required
                            onChange={e => { setAddress(e.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth multiline rows={2} variant='outlined'
                            id="comment"
                            label={<FormattedMessage id="COMMENT" />}
                            value={comment}
                            onChange={e => { setComment(e.target.value); }}
                        />
                    </Grid>
                {/* </Grid> */}

                {/* <Grid container spacing={2} alignItems="baseline" style={{ margin: '0' }}> */}
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            clearable
                            fullWidth
                            disableToolbar
                            disablePast
                            name="endDate"
                            required
                            label={<FormattedMessage id="DELIVERY_DATE" />}
                            value={expectedDate}
                            onChange={({ target: { value } }) => { setExpectedDate(value); }}
                            format="yyyy-MM-dd"
                            minDate={new Date().setDate(new Date().getDate() + 1)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent="flex-end">
                    <Typography variant="caption" style={{ marginRight: 'auto' }}>
                        <FormattedMessage id="REQUIRED_FIELDS" />
                    </Typography>
                    <Grid item>
                        <Button onClick={handleClose} >
                            <FormattedMessage id="CLOSE" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            status="accent"
                            disabled={!sendAllowed}
                            onClick={sendAllowed ? handleSendOnClick : null}
                        >
                            <FormattedMessage id="SEND" />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default RequestOutput;
