import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import format from 'date-fns/format';
import { useParams, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import RadioMulti from 'components/RadioMulti';

import { useUser } from 'contexts/user';
import { useConstants } from 'contexts/constants';
import DownloadButton from 'components/DownloadButton';
import RouteLink from 'components/RouteLink';


const propTypes = {
    initialFilters: PropTypes.shape({
        inVault: PropTypes.bool,
        title: PropTypes.string,
        entityType: PropTypes.array,
    }).isRequired,
    applyFilters: PropTypes.func.isRequired,
    selectionEnabled: PropTypes.bool.isRequired,
    managePermission: PropTypes.bool.isRequired,
    fetchingTitles: PropTypes.bool.isRequired,
    handleSwitch: PropTypes.func,
    defaultFilter: PropTypes.shape({}).isRequired,
};

const defaultProps = {
};

const useStyles = makeStyles((theme) => createStyles({
    search: { // StyledTSearchCard
        marginBottom: theme.spacing(2),
    },
    extraAction: {
        textDecoration: 'none',
        '&:hover, &:active': {
            background: 'transparent',
            textDecoration: 'none',
        },
    },
    filterItem: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    accordionFilter: {
        boxShadow: 'none',
        width: '100%',
        '& .MuiExpansionPanelSummary-root, & .MuiExpansionPanelDetails-root': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    badge: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
        lineHeight: 1,
        fontSize: 'inherit',
        padding: 'inherit',
        '.MuiBadge-badge:hover > &': {
            opacity: 1,
        },
        '&, &:active, &:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
        },
    },
}));

function Filter(props) {
    const {
        selectionEnabled, initialFilters, applyFilters,
        fetchingTitles, managePermission, handleSwitch,
        defaultFilter,
    } = props;

    const { entityTypes } = useConstants();

    const [filtersActive, setFiltersActive] = React.useState(!isEqual(defaultFilter, initialFilters));
    const [filters, setFilters] = React.useState(initialFilters);

    const { inVault, title, entityType, searchAllTitleLangauges, assetLanguage } = filters;

    const classes = useStyles();
    const { user } = useUser();
    const { client } = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { locales } = useConstants();
    const commonLocales = ['en', 'fr-ca', 'fr'];

    const handleSubmit = React.useCallback((event) => {
        setFilters((currentFilters) => {
            applyFilters(currentFilters);
            return currentFilters;
        });
        setFiltersActive(true);
    }, []);

    const clearFilters = React.useCallback((event) => {
        setFilters(defaultFilter);

        if (filtersActive) {
            applyFilters(defaultFilter);
        }
    }, [filtersActive, defaultFilter]);

    const formattedSearch = React.useMemo(() => {
        const fsearch = initialFilters.title ? {
            filter: {
                title: {
                    value: initialFilters.title,
                    condition: 'ilike',
                },
                parent_id: {
                    value: null,
                },
            },
        } : {
            filter: {
                parent_id: {
                    value: null,
                },
            },
        };

        if (!isEmpty(initialFilters.entityType)) {
            fsearch.filter.entity_type_id = { value: initialFilters.entityType, condition: 'in' };
        }

        if (searchAllTitleLangauges && fsearch.filter.title) {
            fsearch.filter.title = {
                ...fsearch.filter.title,
                OR: {
                    value: {
                        value: initialFilters.title,
                        condition: 'ilike',
                    },
                },
            };
        }

        return fsearch;
    }, [initialFilters]);

    const exportFileName = React.useMemo(() => {
        const clientName = (user.clients.find((c) => parseInt(client, 10) === c.client) || {}).name || client;
        const dateFileName = format(Date.now(), 'yyyy-MM-dd_HH-m');
        const cleanClientName = clientName.replace(/\./g, '').replace(/\s+/g, '_');
        return `${cleanClientName}_${dateFileName}.xlsx`;
    }, [client, user.clients]);

    const handleMenuClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleKeyUp = React.useCallback((event) => {
        const { key, target: { value } } = event;
        if (key === 'Enter') {
            handleSubmit(event);
        }
    }, []);


    const handleSearchChange = React.useCallback(({ target: { value } }) => {
        setFilters((oldFilter) => ({ ...oldFilter, title: value }));
    }, []);

    const handleLanguageChange = React.useCallback(({ target: { value } }) => {
        setFilters((oldFilter) => ({ ...oldFilter, assetLanguage: value }));
    }, []);


    const handleVaultChange = React.useCallback(({ target: { checked } }) => {
        setFilters((oldFilter) => ({ ...oldFilter, inVault: checked }));
    }, []);

    const handleAllTitleChange = React.useCallback(({ target: { checked } }) => {
        setFilters((oldFilter) => ({ ...oldFilter, searchAllTitleLangauges: checked }));
    }, []);

    const handleEntityTypeChange = React.useCallback(({ target: { value } }) => {
        setFilters((oldFilter) => {
            const { entityType: oldEntityType = [] } = oldFilter;
            let newEntityTypes = [...oldEntityType, value];

            if (oldEntityType.includes(value)) {
                newEntityTypes = oldEntityType.filter((v) => v !== value);
            }
            return { ...oldFilter, entityType: newEntityTypes };
        });
    }, []);

    const filterCount = React.useMemo(() => {
        return [inVault, !isEmpty(entityType), searchAllTitleLangauges, title, assetLanguage!='all'].reduce((m,v) => (m += (v ? 1 : 0)), 0);
    }, [inVault, isEmpty(entityType), searchAllTitleLangauges, isEmpty(title), assetLanguage==='all']);

    return (
        <Grid container spacing={2} alignItems="center" className={classes.search}>
            <Grid item container xs={12} md={12} lg={6} spacing={2}>
                {
                    !managePermission ? null : (
                        <>
                            {
                                !handleSwitch ? null : (
                                    <Grid item xs="auto">
                                        <FormControlLabel
                                            control={(
                                                <Switch
                                                    checked={selectionEnabled}
                                                    onChange={handleSwitch}
                                                    name="selectionEnabled"
                                                    value="selectionEnabled"
                                                />
                                            )}
                                            label={<FormattedMessage id={selectionEnabled ? 'DESELECT_TITLES' : 'SELECT_TITLES'} />}
                                        />
                                    </Grid>
                                )
                            }
                            <Grid item xs="auto">
                                <Tooltip title={<FormattedMessage id="EXPORT_DEFINITION" />}>
                                    <span>
                                        <DownloadButton
                                            url={`/${client}/entity/`}
                                            urlData={{ inVault, ...formattedSearch, action: 'xlsx' }}
                                            disabled={selectionEnabled}
                                            fileName={exportFileName}
                                            progressSize={18}
                                            variant="outlined"
                                            className={classes.extraAction}
                                        >
                                            <FormattedMessage id="EXPORT" />
                                        </DownloadButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </>
                    )
                }
            </Grid>
            <Grid item container xs={12} md={12} lg={6} justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item xs>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        type="search"
                        name="search"
                        size="small"
                        label={<FormattedMessage id="TITLE" />}
                        value={title}
                        onKeyUp={handleKeyUp}
                        onChange={handleSearchChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs="auto">
                    <Badge
                        color="primary"
                        overlap="circular"
                        invisible={filterCount === 0}
                        badgeContent={(
                            <>
                                <span>
                                    {filterCount}
                                </span>
                                <IconButton
                                    size="small"
                                    className={classes.badge}
                                    onClick={clearFilters}
                                    color="error"
                                >
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        )}

                    >
                        <IconButton onClick={handleMenuClick} size="large">
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={handleMenuClose}
                    >
                        <MenuItem disableRipple className={classes.filterItem}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        disabled={fetchingTitles}
                                        checked={inVault}
                                        onChange={handleVaultChange}
                                        name="showInVaultOnly"
                                        value="showInVaultOnly"
                                        title={<FormattedMessage id="VAULT_TITLES_ONLY" />}
                                    />
                                )}
                                label={<FormattedMessage id="VAULT_TITLES_ONLY" />}
                            />
                        </MenuItem>
                        <MenuItem disableRipple className={classes.filterItem}>
                            <FormControlLabel
                                control={(
                                    <Switch
                                        checked={searchAllTitleLangauges}
                                        onChange={handleAllTitleChange}
                                        name="searchAllTitleLangauges"
                                        value="searchAllTitleLangauges"
                                        disabled={isEmpty(title) || fetchingTitles}
                                        title={<FormattedMessage id="SEARCH_ALL_TITLE_LANGUAGES" />}
                                    />
                                )}
                                label={<FormattedMessage id="SEARCH_ALL_TITLE_LANGUAGES" />}
                            />
                        </MenuItem>
                        <MenuItem disableRipple className={classes.filterItem}>
                            <RadioMulti
                                row
                                label={<FormattedMessage id="ASSET_LANGUAGE" />}
                                fullWidth
                                size="small"
                                name="assetLanguage"
                                value={assetLanguage}
                                onChange={handleLanguageChange}
                                commonValues={commonLocales}
                                options={locales}
                            />
                        </MenuItem>
                        <MenuItem disableRipple className={classes.filterItem}>
                            <Accordion square className={classes.accordionFilter}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <FormattedMessage id="TYPE" />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {
                                            React.useMemo(() => (
                                                entityTypes.filter(({ isRoot }) => ('O', 'A').includes(isRoot)).map(({ value, label }) => (
                                                    <ListItem key={value}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={entityType.includes(value)}
                                                                value={value}
                                                                tabIndex={-1}
                                                                disableRipple
                                                                disabled={fetchingTitles}
                                                                onClick={handleEntityTypeChange}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText primary={label} />
                                                    </ListItem>
                                                ))
                                            ), [entityTypes, fetchingTitles, entityType])
                                        }
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        </MenuItem>
                    </Menu>
                </Grid>

                <Grid item xs="auto">
                    <Button variant="outlined" disabled={fetchingTitles} onClick={handleSubmit}>
                        <FormattedMessage id="SEARCH" />
                    </Button>
                </Grid>
            </Grid>

        </Grid>
    );
}

Filter.propTypes = propTypes;

Filter.defaultProps = defaultProps;

export default Filter;
