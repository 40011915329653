import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';

import { useUser } from 'contexts/user';
import Notes from 'components/Notes/List';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import CURRENT from 'utils/environment';
import useLocationSearch from 'hooks/useLocationSearch';
import FormSelect from 'components/FormSelect';


const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    loading: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
        height: 'max-content',
        margin: `${theme.spacing(8)} auto 0`,
    },
    content: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(4, 3),
        flex: 'auto',
        overflow: 'auto',
        [theme.breakpoints.up('md')]: {
            width: `calc(75ch + ${theme.spacing(4)})`,
        },
        '& > .MuiCard-root:nth-child(n + 2)': {
            marginTop: theme.spacing(4),
        },
        '& > .MuiCard-root': {
            padding: theme.spacing(1, 2),
        }
    },
}));

const defaultSort = { startDate: 'DESC' };

const sortOptions = [
    { label: 'MOST_RECENT', value: defaultSort },
    { label: 'LEAST_RECENT', value: { startDate: 'ASC' } },
];

const defaultFilter = {
    noteType: 'ALL',
};

const noteTypeOptions = [
    { label: 'ALL', value: defaultFilter.noteType },
    { label: 'RELEASE', value: 'RELEASE' },
    { label: 'ANNOUNCEMENT', value: 'ANNOUNCEMENT' },
];

function Releases() {
    const { user: { language }, api } = useUser();

    const [releases, setReleases] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasReleases, setHasReleases] = React.useState(true);
    const [failed, setFailed] = React.useState(false);

    const [sort, setSort] = useLocationSearch(Object.keys(sortOptions[0].value), sortOptions[0].value);
    const [filter, setFilter] = useLocationSearch(Object.keys(defaultFilter), defaultFilter);
    const classes = useStyles();
    const intl = useIntl();

    const sortIndex = sortOptions.findIndex(({ value }) => JSON.stringify(value) === JSON.stringify(sort)) || 0;

    async function fetchReleases(abortController = new AbortController()) { // TODO add to constants ?
        try {
            setIsLoading(true);
            const appVersion = CURRENT.appVersion();
            const response = await api.get('/releases', { sort, filter, appVersion }, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                if (isEmpty(data)) {
                    setHasReleases(false);
                } else {
                    setHasReleases(true);
                    setReleases(data);
                }
            } else {
                setFailed(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchReleases(abortController);
        return () => {
            abortController.abort();
        };
    }, [sort, filter]);

    function handleFilter({ target: { name, value } }) {
        setFilter((current) => ({ ...current, [name]: value }));
    }

    function handleSort({ target: { value } }) {
        setSort(value);
    }

    let content = null;

    if (isLoading) {
        content = (
            <Loading className={classes.loading} />
        );
    } else if (!hasReleases) {
        content = (<NotFound className={classes.notFound} message="NO_RELEASES" />);
    } else {
        content = (<Notes notes={releases} language={language} Container={Card} />);
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>
                    {`Melodie - ${intl.formatMessage({ id: 'RELEASES' })}`}
                </title>
            </Helmet>
            <Toolbar>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormSelect
                            name="noteType"
                            size="small"
                            label={<FormattedMessage id="FILTER" />}
                            value={filter.noteType}
                            onChange={handleFilter}
                        >
                            {
                                noteTypeOptions.map(({ label, value }, i) => (
                                    <MenuItem value={value} key={label}>
                                        <FormattedMessage id={label} />
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                    <Grid item>
                        <FormSelect
                            size="small"
                            label={<FormattedMessage id="SORT" />}
                            value={sortOptions[sortIndex].value}
                            onChange={handleSort}
                        >
                            {
                                sortOptions.map(({ label }, i) => (
                                    <MenuItem value={sortOptions[i].value} key={label}>
                                        <FormattedMessage id={label} />
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                </Grid>
            </Toolbar>
            <div className={classes.content}>
                {content}
            </div>
        </div>
    );
}

export default Releases;
