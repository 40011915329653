import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

import {fileDelivery, deliveryTypes, tapeDelivery } from './constants';
import FormSelect from 'components/FormSelect';
import { useConstants } from 'contexts/constants';

function OutputTechInfo(props) {

    const {
        onChange, outputData, currentServiceIndex,
    } = props;

    const {
        piste, loudness, codec, codecAutre,
        bitrate, wrapper, wrapperAutre, frame,
        textless, encodageNoHearing, scanningMethod,
        otherSpecification
    } = outputData;

    const { vodOptions: {
        codecOptions, bitrateOptions, wrapperOptions, frameRateOptions,
        trackOptions, loundnessOptions,
    } } = useConstants();

    const handleOutputDataChange = React.useCallback(({ target: { name, value, type, checked } }) => {
        if (onChange) {
            onChange((currentTechInfo) => {
                return currentTechInfo.map((TechInfo, index) => {
                    if (index === currentServiceIndex) {
                        return {...TechInfo, [name]: type === 'checkbox' ? checked : value, }
                    } else {
                        return TechInfo
                    }
                });
            });
        }
    }, [onChange, outputData]);

    return (
            <Grid
                container
                spacing={2}
                alignItems="flex-end"
                style={{ marginTop: 0 }}
            >
                <Grid item xs={6}>
                    <FormSelect
                        fullWidth
                        required
                        label={<FormattedMessage id="PISTE" />}
                        name="piste"
                        value={piste}
                        onChange={handleOutputDataChange}
                    >
                        {
                            trackOptions.map(({ value, intlLabel, label }) => (
                                <MenuItem key={value} value={value}>
                                    {
                                        !intlLabel ? label : <FormattedMessage id={intlLabel} />
                                    }
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid item xs={6}>
                    <FormSelect
                        fullWidth
                        required
                        label={<FormattedMessage id="LOUDNESS" />}
                        name="loudness"
                        value={loudness}
                        onChange={handleOutputDataChange}
                    >
                        {
                            loundnessOptions.map(({ value, intlLabel, label }) => (
                                <MenuItem key={value} value={value}>
                                    {
                                        !intlLabel ? label : <FormattedMessage id={intlLabel} />
                                    }
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid item xs={['DNxHD', 'H264', 'other'].includes(codec) ? 4 : 6}>
                    <FormSelect
                        fullWidth
                        required
                        label={<FormattedMessage id="CODEC" />}
                        name="codec"
                        value={codec}
                        onChange={handleOutputDataChange}
                    >
                        {
                            codecOptions.map(({ value, intlLabel, label }) => (
                                <MenuItem key={value} value={value}>
                                    {
                                        !intlLabel ? label : <FormattedMessage id={intlLabel} />
                                    }
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                {codec !== 'DNxHD' ? null : (
                    <Grid item xs={8}>
                        <FormSelect
                            fullWidth
                            required
                            label={<FormattedMessage id="BITRATE" />}
                            name="bitrate"
                            value={bitrate}
                            onChange={handleOutputDataChange}
                        >
                            {
                                bitrateOptions.map(({ value, intlLabel, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {
                                            !intlLabel ? label : <FormattedMessage id={intlLabel} />
                                        }
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </Grid>
                )}
                {codec !== 'H264' ? null : (
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            required
                            name="bitrate"
                            label={<FormattedMessage id="BITRATE" />}
                            value={bitrate}
                            onChange={handleOutputDataChange}
                        />
                    </Grid>
                )}
                {codec !== 'other' ? null : (
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            required
                            name="codecAutre"
                            label={<FormattedMessage id="CODEC" />}
                            value={codecAutre}
                            onChange={handleOutputDataChange}
                        />
                    </Grid>
                )}
                <Grid item xs={wrapper !== 'other' ? 6 : 4}>
                    <FormSelect
                        fullWidth
                        required
                        label={<FormattedMessage id="WRAPPER" />}
                        name="wrapper"
                        value={wrapper}
                        onChange={handleOutputDataChange}
                    >
                        {
                            wrapperOptions.map(({ value, intlLabel, label }) => (
                                <MenuItem key={value} value={value}>
                                    {
                                        !intlLabel ? label : <FormattedMessage id={intlLabel} />
                                    }
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                {wrapper !== 'other' ? null : (
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            required
                            name="wrapperAutre"
                            label={<FormattedMessage id="WRAPPER" />}
                            value={wrapperAutre}
                            onChange={handleOutputDataChange}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <FormSelect
                        fullWidth
                        required

                        label={<FormattedMessage id="FRAME" />}
                        name="frame"
                        value={frame}
                        onChange={handleOutputDataChange}
                    >
                        {
                            frameRateOptions.map(({ value, intlLabel, label }) => (
                                <MenuItem key={value} value={value}>
                                    {
                                        !intlLabel ? label : <FormattedMessage id={intlLabel} />
                                    }
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormGroup row>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        name="textless"
                                        checked={textless}
                                        onChange={handleOutputDataChange}
                                        value="textless"
                                    />
                                )}
                                label={<FormattedMessage id="TEXTLESS" />}
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        name="encodageNoHearing"
                                        checked={encodageNoHearing}
                                        onChange={handleOutputDataChange}
                                        value="encodageNoHearing"
                                    />
                                )}
                                label={<FormattedMessage id="ENCODE_HEARING" />}
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset" fullWidth >
                        <FormLabel component="legend">
                            <FormattedMessage id="SCANNING_METHOD" />
                        </FormLabel>
                        <RadioGroup row name="scanningMethod" value={scanningMethod} onChange={handleOutputDataChange}>
                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label={<FormattedMessage id="PROGRESSIF" />}
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={<FormattedMessage id="INTERLACE" />}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        name="otherSpecification"
                        value={otherSpecification}
                        label={<FormattedMessage id="OTHERS_SPECIFICATIONS" />}
                        onChange={handleOutputDataChange}

                    />
                </Grid>
            </Grid>
        )
}

export default OutputTechInfo;
