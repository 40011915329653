import { EventEmitter } from 'events';
import auth0 from 'auth0-js';

export default class Auth extends EventEmitter {
    constructor(options) {
        super();
        this.handledEvents = {};
        this.auth0 = new auth0.WebAuth(options);
    }

    setEventHandler = (event, handler) => {
        this.handledEvents[event] = handler;
    }

    setSession = (authResult) => {
        // Set the time that the access token will expire at
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);

        if (this.handledEvents.setSession) {
            this.handledEvents.setSession({ authResult });
        }
    }

    getUserInfo = (accessToken, callback) => {
        this.auth0.client.userInfo(accessToken, (error, profile) => {
            if (error) {
                // Handle error
                return;
            }

            const { 'https://mels-studios.com/uid': uid = null } = profile;

            if (uid) {
                localStorage.setItem('uid', uid);
            }

            if (profile && callback) {
                callback(error, profile);
            }
        });
    }

    // handle social logins
    handleAuthentication = () => {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                alert(`Error: ${err.error}`);
            }

            if (this.handledEvents.handleAuthentication) {
                this.handledEvents.handleAuthentication({ authResult });
            }
        });
    }

    logout = () => {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('uid');
        if (this.handledEvents.logout) {
            this.handledEvents.logout();
        }
    }

    isAuthenticated = () => {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    getAccessToken = () => {
        const accessToken = localStorage.getItem('access_token');
        return accessToken;
    }

    getIdToken = () => {
        const idToken = localStorage.getItem('id_token');
        return idToken;
    }

    getUID = () => {
        const uid = localStorage.getItem('uid');
        return uid;
    }

    loginWithGoogle = () => {
        this.auth0.authorize({ connection: 'google-oauth2' });
        if (this.handledEvents.loginWithGoogle) {
            this.handledEvents.loginWithGoogle({ auth: this });
        }
    }

    login = (username, password, callback) => {
        this.auth0.client.login(
            { realm: 'mels', username, password },
            (err, authResult) => {

                if (err) {
                    callback(err, authResult);
                    return;
                }

                this.setSession(authResult);

                if (callback) {
                    callback(err, authResult);
                }
                if (this.handledEvents.login) {
                    this.handledEvents.login({ auth: this });
                }
            },
        );
    }

    resetPassword = (email, callback) => {
        this.auth0.client.dbConnection.changePassword(
            { connection: 'mels', email },
            callback,
        );
    }
}
