import { Theme } from 'styles/constants';


import imgLightBkgnd from 'resources/img/mels.png';
import imgDarkBkgnd from 'resources/img/mels_white.png';


const luminance = (color) => {
    let colorObj = {};
    if (typeof color === 'string' || color instanceof String) {
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
            let c = color.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${c.join('')}`;
            colorObj = Theme.rgbaObj(`rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},1)`); // eslint-disable-line no-bitwise
        } else {
            colorObj = Theme.rgbaObj(color);
        }
    } else {
        colorObj = color;
    }

    const { r, g, b } = colorObj;
    const perceptiveLuminance = (((0.299 * r) + (0.587 * g) + (0.114 * b)) / 255);
    return perceptiveLuminance;
};

function DynamicLogoImg(bkgndColor, lightBkgndLogo = imgLightBkgnd, darkBkgndLogo = imgDarkBkgnd) {

    if (bkgndColor && luminance(bkgndColor) > 0.5) {
        return lightBkgndLogo;
    }

    return darkBkgndLogo;
}

export default DynamicLogoImg;
